import { useAppDispatch } from '@/_helpers/store';
import {
    DialogNameWithData,
    openCandidateDetailsDialog,
    openDialog,
    openDialogDismissOthers,
} from '@/_reducers/dialogs';
import { fetchProcessesInProgress } from '@/_reducers/processes';
import { fetchRecruiterAvailability } from '@/_reducers/recruiterAvailability';
import {
    InProcessProcesses,
    InProcessProcessesFetcherData,
    RecruiterAvailabilityFetcherData,
} from '@/_selectors';
import useFetchers from '@/hooks/useFetchers';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import useMissingEventsFetcher from '@/hooks/useMissingEventsFetcher';
import { DialogName, InProcessFilter } from '@/lib/types';
import { valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import InProcessLayout from './InProcessLayout';
import InProcessMobileLayout from './InProcessMobileLayout';
import { processToInProcessFilter, substatusFilters } from './utils';

function InProcess({
    openDialog,
    openDialogDismissOthers,
}: {
    openDialog: (dialog: DialogName) => void;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
}) {
    const isMobileScreen = useIsMobileScreen();
    useFetchers(fetchProcessesInProgress, fetchRecruiterAvailability);
    useMissingEventsFetcher();

    const dispatch = useAppDispatch();
    const onProcessClick = (processId: string) => {
        dispatch(openCandidateDetailsDialog(processId));
    };
    const openSetAvailabilityDialog = () => openDialog(DialogName.RecruiterAvailability);
    const openSetAvailabilityDialogDismissOthers = () =>
        openDialogDismissOthers(DialogName.RecruiterAvailability);
    const [filter, setFilter] = useState<InProcessFilter>(InProcessFilter.All);
    const modelsById = useSelector(InProcessProcesses);
    const { initiated, isLoading } = useSelector(InProcessProcessesFetcherData);
    const models = valuesOf(modelsById);
    const noCandidtesInProcess = models.length === 0;

    const modelsByFilter = groupBy(models, processToInProcessFilter);
    const relevantFilters = substatusFilters.filter(
        (f) => modelsByFilter[f]?.length ?? 0 > 0,
    );
    const { initiated: initiatedRecruiterAv } = useSelector(
        RecruiterAvailabilityFetcherData,
    );
    const showFilters = relevantFilters.length > 0;

    const filteredModels =
        !showFilters || filter === InProcessFilter.All ? models : modelsByFilter[filter];

    const onFilterChange = (v: string) => {
        trackEvent(MixpanelEvent.FilterCandidatesInProcess, { filter_value: v });
        setFilter(v as InProcessFilter);
    };

    return isMobileScreen ? (
        <InProcessMobileLayout
            processes={models}
            filteredProcesses={filteredModels}
            filter={filter}
            onFilterChange={onFilterChange}
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
            onProcessClick={onProcessClick}
            isEmpty={noCandidtesInProcess}
            isLoading={isLoading}
            initiated={initiated}
        />
    ) : (
        <InProcessLayout
            processes={models}
            filteredProcesses={filteredModels}
            filter={filter}
            onFilterChange={onFilterChange}
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
            onProcessClick={onProcessClick}
            isEmpty={noCandidtesInProcess}
            isLoading={isLoading}
            initiated={initiated}
        />
    );
}

const mapDispathToProps = {
    openDialog,
    openDialogDismissOthers,
};

export default connect(null, mapDispathToProps)(InProcess);
