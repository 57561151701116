import { CandidateEventApiModel } from '@/lib/types';
import { keysOf, parseEventApiModel, serializeFetcherState } from '@/lib/utils';
import { groupBy, mapValues, pickBy } from 'lodash';
import { createSelector } from 'reselect';

import { EventsState } from './base';

export const Events = createSelector(EventsState, (eventsState) => {
    const eventsFetched = pickBy(eventsState.data, (e) => e !== undefined) as Record<
        string,
        CandidateEventApiModel
    >;
    return mapValues(eventsFetched, parseEventApiModel);
});

export const EventIdsToFetch = createSelector(EventsState, (eventsState) =>
    keysOf(pickBy(eventsState.data, (e) => e === undefined)),
);

export const UpcomingEventsFetcherData = createSelector(EventsState, (state) =>
    serializeFetcherState(state.fetchers.upcomingEvents),
);

export const MissingForProcessesEventsFetcherData = createSelector(
    EventsState,
    (state) => serializeFetcherState(state.fetchers.missingForProcesses),
);

export const ProcessEvents = createSelector(Events, (events) =>
    pickBy(events, (e) => !!e.processId),
);

export const ProcessEventsByProcId = createSelector(ProcessEvents, (processEvents) =>
    groupBy(processEvents, 'processId'),
);

export const ProcessIdsOfStoredEvents = createSelector(
    ProcessEventsByProcId,
    (eventByProcId) => keysOf(eventByProcId),
);
