import { ArrowRight, CandidatesIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import NewCandidateBlockMobile from '@/pages/candidates/NewCandidateBlockMobile';
import { homepage as homepageStrings } from '@/strings';

import { NewCandidatesSectionProps } from '../types';
import EmptySectionView from './EmptySectionView';

const strings = homepageStrings.newRequests;

const MAX_PROCESSES_TO_SHOW = 2;

function NewCandidatesSection({
    newProcesses,
    initiated,
    isLoading,
    goToCandidates,
    anchorRef,
}: NewCandidatesSectionProps) {
    const count = newProcesses.length;
    const isEmpty = count === 0;
    const shownProcesses = newProcesses.slice(0, MAX_PROCESSES_TO_SHOW);
    return (
        <div className={cn('py-12 border-b border-neutral200')} ref={anchorRef}>
            <div className={cn('w-full text-20 font-bold')}>
                <div className={cn('flex items-center justify-between')}>
                    <div>
                        {`${isEmpty && initiated ? strings.noCandidatesTitle : strings.title.func(initiated ? count : null)}`}
                    </div>
                    <div>
                        {!isEmpty && (
                            <div
                                className={cn('flex items-center gap-2 cursor-pointer')}
                                onClick={goToCandidates}
                            >
                                <div className={cn('text-13 font-bold')}>
                                    {strings.mobile.goToCandidates}
                                </div>
                                <ArrowRight className={cn('fill-black w-2')} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={cn('mt-10 flex flex-col items-stretch justify-center gap-10')}
            >
                {isEmpty && (
                    <EmptySectionView
                        Icon={CandidatesIcon}
                        label={strings.noCandidatesMsg}
                        loading={isLoading}
                        loaderProps={{ className: cn('my-[20px]') }}
                    />
                )}
                {!isEmpty &&
                    shownProcesses.map((process) => (
                        <NewCandidateBlockMobile
                            key={process.id}
                            process={process}
                            className={cn('w-full')}
                        />
                    ))}
            </div>
        </div>
    );
}

export default NewCandidatesSection;
