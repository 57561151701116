import { CurrentUserDisplayName } from '@/_selectors';
import { cn } from '@/lib/utils';
import { homepage as homepageStrings } from '@/strings';
import { useSelector } from 'react-redux';

import SectionSummaryTile from './SectionSummaryTile';

const strings = homepageStrings.mobile.summary;

function SummarySection({
    tasksCount,
    newCandidatesCount,
    upcomingEventsCount,
    goToPendingTasks,
    goToNewCandidates,
    goToUpcomingEvents,
    isLoadingTasks,
    isLoadingCandidates,
    isLoadingUpcomingEvents,
    initiatedTasks,
    initiatedCandidates,
    initiatedUpcomingEvents,
}: {
    tasksCount: number;
    newCandidatesCount: number;
    upcomingEventsCount: number;
    goToPendingTasks: () => void;
    goToNewCandidates: () => void;
    goToUpcomingEvents: () => void;
    isLoadingTasks: boolean;
    isLoadingCandidates: boolean;
    isLoadingUpcomingEvents: boolean;
    initiatedTasks: boolean;
    initiatedCandidates: boolean;
    initiatedUpcomingEvents: boolean;
}) {
    const { firstName } = useSelector(CurrentUserDisplayName);
    return (
        <div className={cn('py-12 border-b border-neutral200')}>
            <div className={cn('text-20 font-bold')}>
                {strings.header.func(firstName)}
            </div>
            <div className={cn('mt-10 flex items-stretch  justify-center gap-4')}>
                <SectionSummaryTile
                    count={tasksCount}
                    label={strings.tasksLabel}
                    onClick={goToPendingTasks}
                    isLoading={isLoadingTasks}
                    initiated={initiatedTasks}
                />
                <SectionSummaryTile
                    count={newCandidatesCount}
                    label={strings.newCandidatesLabel}
                    onClick={goToNewCandidates}
                    isLoading={isLoadingCandidates}
                    initiated={initiatedCandidates}
                />
                <SectionSummaryTile
                    count={upcomingEventsCount}
                    label={strings.upcomingEventsLabel}
                    onClick={goToUpcomingEvents}
                    isLoading={isLoadingUpcomingEvents}
                    initiated={initiatedUpcomingEvents}
                />
            </div>
        </div>
    );
}

export default SummarySection;
