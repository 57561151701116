import { EventType } from '@/lib/api/v1';
import {
    CandidateEvent,
    EventRelatedProcessStatus,
    Process,
    Profession,
} from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import ConfirmationDialog from '../confirmation/ConfirmationDialog';
import { BaseDialogPropsCommon } from '../types';

const strings = dialogsStrings.interviewDidntHappen;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    event: CandidateEvent;
    goToTerminate: () => void;
    onReschedule: () => Promise<void>;
}

function InterviewDidntHappenDialog({
    process,
    event,
    goToTerminate,
    onReschedule,
    ...dialogProps
}: Props) {
    const { candidate } = process;
    const { profession } = candidate;

    return (
        <ConfirmationDialog
            {...dialogProps}
            header={
                <CandidateTitle
                    process={process}
                    subtitle={strings.professionToName[profession as Profession]}
                />
            }
            content={{
                title: strings.title,
                description:
                    event.eventType === EventType.Orientation
                        ? strings.oreintationDescription
                        : strings.description,
                cancel: {
                    label: strings.terminateButton,
                    action: goToTerminate,
                },
                confirm: {
                    label: strings.rescheduleButton[
                        process.status as EventRelatedProcessStatus
                    ],
                    action: onReschedule,
                    failureTitle: strings.reschedule.failureTitle,
                },
            }}
            successContent={{
                title: strings.successStep.title,
                description: strings.successStep.description.func(
                    getFirstName(candidate),
                ),
            }}
        />
    );
}

export default InterviewDidntHappenDialog;
