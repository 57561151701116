import { EmailLottie } from '@/components/lotties';
import { ArrowLeft } from '@/components/svgs';
import { cn } from '@/lib/utils';
import strings from '@/strings/login';

interface Props {
    email: string;
    goBack: () => void;
}

function EmailSent({ email, goBack }: Props) {
    return (
        <>
            <div
                className={cn(
                    'absolute items-center flex top-12 left-12 cursor-pointer',
                )}
                onClick={goBack}
            >
                <ArrowLeft />
                <p className={cn('ml-3 text-15 font-semibold')}>
                    {strings.emailSent.goBackButton}
                </p>
            </div>
            <div className={cn('p-30')}>
                <div className={cn('w-[450px]')}>
                    <div className={cn('text-center')}>
                        <EmailLottie className={cn('mx-auto w-40')} />
                        <p className={cn('mt-8 font-bold text-24')}>
                            {strings.emailSent.title}
                        </p>
                        <p className={cn('mt-4 font-medium text-14')}>
                            {strings.emailSent.subtitle.func(email)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailSent;
