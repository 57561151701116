import { Process } from '@/lib/types';

import HistoryItemContent from './HistoryItemContent';
import HistoryRow from './HistoryRow';
import { getActionItemStrings } from './utils';

function CurrentActionItemRow({ process }: { process: Process }) {
    const { title, subtitle } = getActionItemStrings(process);
    return (
        <HistoryRow
            isTopItem
            isActionItem
            content={<HistoryItemContent title={title} subtitle={subtitle} />}
        />
    );
}

export default CurrentActionItemRow;
