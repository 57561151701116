import useHover from '@/hooks/useHover';
import useMutationObserver from '@/hooks/useMutationObserver';
import useResizeObserver from '@/hooks/useResizeObserver';
import useScrollbarWidth from '@/hooks/useScrollbarWidth';
import { cn } from '@/lib/utils';
import { HTMLAttributes, useCallback, useRef, useState } from 'react';

function doesHaveVerticalScrollbar(container: HTMLDivElement | null) {
    if (!container) return false;
    return container.scrollHeight > container.clientHeight;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    childContainerProps?: HTMLAttributes<HTMLDivElement>;
}

function ScrollOnHoverVerticalFirefox({
    children,
    className,
    childContainerProps,
    ...props
}: Props) {
    const containerRef = useRef<HTMLDivElement>(null);
    const hovering = useHover(containerRef);
    const scrollbarWidth = useScrollbarWidth();
    const [hasOverflow, setHasOverflow] = useState(false);
    const checkHasOverflow = useCallback(
        () => setHasOverflow(doesHaveVerticalScrollbar(containerRef.current)),
        [],
    );
    useMutationObserver(containerRef, checkHasOverflow);
    useResizeObserver(containerRef, checkHasOverflow);
    return (
        <div
            ref={containerRef}
            className={cn(
                'relative h-full overflow-y-auto stable-gutter',
                { 'hidden-scrollbar': !hovering || !hasOverflow },
                className,
            )}
            style={{
                paddingRight: hovering && hasOverflow ? 0 : scrollbarWidth,
                paddingLeft: scrollbarWidth - 8,
            }}
            {...props}
        >
            <div
                {...childContainerProps}
                className={cn('relative h-full', childContainerProps?.className)}
            >
                {children}
            </div>
        </div>
    );
}

export default ScrollOnHoverVerticalFirefox;
