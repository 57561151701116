import Loader from '@/components/lotties/Loader';
import { ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

import NoCandidatesView from '../NoCandidatesView';
import { InProcessCandidatesViewProps } from '../types';
import InProcessCollectionMobile from './InProcessCollectionMobile';
import InProcessTopRow from './InProcessTopRow';
import { getSortedProcesses } from './utils';

const strings = candidatesStrings.inProcess;

function InProcessMobileLayout({
    processes,
    filteredProcesses,
    filter,
    onFilterChange,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
    onProcessClick,
    isEmpty,
    isLoading,
    initiated,
}: InProcessCandidatesViewProps) {
    return (
        <ScrollableFillerDiv
            fixedDiv={
                <InProcessTopRow
                    filter={filter}
                    setFilter={onFilterChange}
                    models={processes}
                    initiatedRecruiterAv={initiatedRecruiterAv}
                    openSetAvailabilityDialog={openSetAvailabilityDialog}
                    openSetAvailabilityDialogDismissOthers={
                        openSetAvailabilityDialogDismissOthers
                    }
                />
            }
            className={cn('hidden-scrollbar')}
        >
            <div className={cn('w-full py-6')}>
                <div className={cn('w-full px-6')}>
                    <InProcessCollectionMobile
                        processes={getSortedProcesses(filteredProcesses, filter)}
                        onProcessClick={onProcessClick}
                    />
                    {isLoading && !initiated && (
                        <div
                            className={cn(
                                'absolute flex items-center justify-center w-full h-full top-0 left-0',
                            )}
                        >
                            <Loader variant='primary' sizing='lg' />
                        </div>
                    )}
                    {initiated && isEmpty && (
                        <NoCandidatesView text={strings.noCandidtes} />
                    )}
                </div>
            </div>
        </ScrollableFillerDiv>
    );
}

export default InProcessMobileLayout;
