import { serializeFetcherState } from '@/lib/utils';
import { parseProcessHistoryApiModel } from '@/lib/utils/processHistory';
import { mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { ProcessHistoryState } from './base';

export const ProcessesHistoryByProcessId = createSelector(ProcessHistoryState, (state) =>
    mapValues(state, (state) => ({
        history: state.history.map(parseProcessHistoryApiModel),
        fetcher: serializeFetcherState(state.fetcher),
    })),
);
