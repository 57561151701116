import { EventType } from '@/lib/api/v1';
import { EventWithRelations } from '@/lib/types';

import { BaseDialogPropsCommon } from '../types';

export type ImplementedEventType =
    | EventType.PhoneInterview
    | EventType.InPersonInterview
    | EventType.Orientation;

export enum DialogStep {
    EventDetails = 'eventDetails',
    CancelEvent = 'cancelEvent',
    RescheduleSuccess = 'rescheduleSuccess',
}

export interface EventDetailsDialogProps extends BaseDialogPropsCommon {
    event: EventWithRelations;
    goToTerminate: () => void;
    onReschedule: () => Promise<void>;
}

export interface EventDialogTitleProps {
    event: EventWithRelations;
    step: DialogStep;
}

export interface CancelEventStepProps {
    goToTerminate: () => void;
    reschedule: () => Promise<void>;
    event: EventWithRelations;
}

export interface EventDetailsStepProps {
    event: EventWithRelations;
    reschedule: () => Promise<void>;
    cacnelEvent: () => void;
}
