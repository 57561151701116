import { HeartLogo } from '@/components/svgs';
import { cn } from '@/lib/utils';

const LoginBackgroundImage = ({ withHeart = true }: { withHeart?: boolean }) => {
    return (
        <>
            <img className={cn('h-full object-cover w-full')} src='/img/image260.png' />
            {withHeart && (
                <div className={cn('absolute top-10 left-10')}>
                    <HeartLogo />
                </div>
            )}
        </>
    );
};

export default LoginBackgroundImage;
