import { updateSelectedJobMatches } from '@/_reducers/processes';
import { connect } from 'react-redux';

import CandidateJobMatches from './CandidateJobMatches';
import { JobMatchesTileProps } from './types';

function CandidateJobMatchesProvider({
    updateSelectedJobMatches,
    ...props
}: JobMatchesTileProps) {
    return (
        <CandidateJobMatches
            {...props}
            updateSelectedJobMatches={updateSelectedJobMatches}
        />
    );
}

const mapDispathToProps = {
    updateSelectedJobMatches,
};

export default connect(null, mapDispathToProps)(CandidateJobMatchesProvider);
