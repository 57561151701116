import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';

import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastThumbnail,
    ToastTitle,
    ToastViewport,
} from './toast';
import { ToasterMobile } from './toasterMobile';
import { useToast } from './use-toast';

export function Toaster() {
    const { toasts } = useToast();
    const isMobileScreen = useIsMobileScreen();

    return (
        <ToastProvider>
            {toasts.map(function (toast) {
                const {
                    id,
                    title,
                    description,
                    action,
                    variant,
                    icon,
                    thumbnailProps,
                    ...props
                } = toast;
                return isMobileScreen ? (
                    <ToasterMobile key={id} toast={toast} />
                ) : (
                    <Toast key={id} variant={variant} {...props}>
                        <ToastThumbnail
                            variant={variant}
                            icon={icon}
                            {...thumbnailProps}
                        />
                        <div className={cn('mr-12')}>
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && (
                                <ToastDescription>{description}</ToastDescription>
                            )}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                );
            })}
            <ToastViewport />
        </ToastProvider>
    );
}
