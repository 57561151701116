import { PageId } from '@/lib/types';
import { CandidatesTab } from '@/lib/types/queryParams';
import { getRouteForPageId } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';

function useGoTo() {
    const navigate = useNavigate();

    const goToHome = () => navigate(getRouteForPageId(PageId.Home));
    const goToCandidates = (tab?: CandidatesTab) =>
        navigate(`${getRouteForPageId(PageId.Candidates)}${tab ? `#${tab}` : ''}`);
    const goToCalendar = () => navigate(getRouteForPageId(PageId.Calendar));
    const goToSettings = () => navigate(getRouteForPageId(PageId.Settings));

    return {
        goToHome,
        goToCandidates,
        goToCalendar,
        goToSettings,
    };
}

export default useGoTo;
