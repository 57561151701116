import { useState } from 'react';

const useSetOpen = (
    externalOpen?: boolean,
    setExternalOpen?: (bool: boolean) => void,
) => {
    const [internalOpen, setInternalOpen] = useState(false);
    const isControlled = setExternalOpen !== undefined && externalOpen !== undefined;
    const open = isControlled ? externalOpen : internalOpen;
    const setOpen = isControlled ? setExternalOpen : setInternalOpen;

    const handleOpenChange = (isOpen: boolean) => {
        setOpen(isOpen);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    return {
        open,
        setOpen,
        handleOpen,
        handleClose,
        handleOpenChange,
    };
};

export default useSetOpen;
