import { ButtonsGroup, Select } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { InProcessFilter } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';
import { groupBy } from 'lodash';
import { useEffect } from 'react';

import { InProcessFilterProps } from '../types';
import { DEFAULT_FILTER } from './consts';
import { processToInProcessFilter, substatusFilters } from './utils';

const strings = candidatesStrings.inProcess;

function InProcessFilterView({ filter, setFilter, models }: InProcessFilterProps) {
    const isMobileScreen = useIsMobileScreen();

    const modelsByFilter = groupBy(models, processToInProcessFilter);
    const relevantFilters = substatusFilters.filter(
        (f) => modelsByFilter[f]?.length ?? 0 > 0,
    );

    useEffect(() => {
        if (filter !== DEFAULT_FILTER && !relevantFilters.includes(filter)) {
            setFilter(DEFAULT_FILTER);
        }
    }, [filter, setFilter, relevantFilters]);

    return (
        <>
            {isMobileScreen && (
                <Select
                    value={filter}
                    onChange={setFilter}
                    options={[
                        {
                            id: InProcessFilter.All,
                            value: InProcessFilter.All,
                            label: strings.showAllFilter.func(models.length),
                        },
                        ...relevantFilters.map((filter) => ({
                            id: filter,
                            value: filter,
                            label: strings.substatusFilters[filter].func(
                                modelsByFilter[filter]?.length ?? 0,
                            ),
                        })),
                    ]}
                    triggerProps={{
                        className: cn(
                            'w-fit xs:px-4 xs:py-3 text-12 font-semibold bg-accent300 rounded-xl border-none whitespace-nowrap',
                        ),
                    }}
                />
            )}
            {!isMobileScreen && (
                <ButtonsGroup
                    value={filter}
                    setValue={setFilter}
                    buttons={[
                        {
                            id: InProcessFilter.All,
                            label: strings.showAllFilter.func(models.length),
                        },
                        ...relevantFilters.map((f) => ({
                            id: f,
                            label: strings.substatusFilters[f].func(
                                modelsByFilter[f].length,
                            ),
                        })),
                    ]}
                />
            )}
        </>
    );
}

export default InProcessFilterView;
