import { SuccessLottie } from '@/components/lotties';
import { Process, ProcessNextStep } from '@/lib/types';
import { cn } from '@/lib/utils';

import { getStringsForDialog } from './utils';

export function SuccessContent({
    process,
    nextStep,
}: {
    process: Process;
    nextStep: ProcessNextStep;
}) {
    const { successTitle, successDescription } = getStringsForDialog(process, nextStep);
    return (
        <div
            className={cn(
                'relative flex flex-col items-center text-center pt-6 pb-[90px] px-12 xs:p-10 xs:pt-8 xs:min-h-[390px]',
            )}
        >
            <SuccessLottie className={cn('h-[270px] w-fit xs:w-[200px] xs:h-fit')} />
            <div className={cn('max-w-[450px] whitespace-pre-line')}>
                <p className={cn('mt-10 text-20 font-bold')}>{successTitle}</p>
                <p className={cn('mt-4 text-14 font-medium xs:text-15')}>
                    {successDescription}
                </p>
            </div>
        </div>
    );
}

export default SuccessContent;
