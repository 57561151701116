import useSetQueryParam from '@/hooks/useSetQueryParam';
import { QueryParam } from '@/lib/types/queryParams';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useProcessIdFromUrl({ callback }: { callback: (processId: string) => void }) {
    const location = useLocation();
    const setQueryParam = useSetQueryParam(QueryParam.PROCESS_ID);

    const { [QueryParam.PROCESS_ID]: processId } = queryString.parse(location.search);

    useEffect(() => {
        if (processId) {
            if (typeof processId === 'string') {
                callback(processId);
            }
            setQueryParam(null);
        }
    }, [processId, callback, setQueryParam]);

    return null;
}

export default useProcessIdFromUrl;
