import { RootState } from '@/_helpers/store';
import { getAllEnumValues } from '@/lib/base';
import { FetchModelStatus, ProcessApiModel } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy, mapValues } from 'lodash';

import {
    FetchProcessesFunc,
    FetcherName,
    ProcessesState,
    SetResumeLinkPayload,
} from './types';

export const saveProcesses = createAsyncThunk<
    ProcessApiModel[],
    { fetcherId: FetcherName; fetchFunction: FetchProcessesFunc | FetchProcessesFunc[] },
    { state: RootState }
>('processes/fetchProcesses', async ({ fetchFunction }, { rejectWithValue }) => {
    const fetchFuncs = Array.isArray(fetchFunction) ? fetchFunction : [fetchFunction];
    try {
        const fetchPromises = fetchFuncs.map((fetchFunc) => fetchFunc());
        const results = await Promise.all(fetchPromises);
        const processes = results.flat();
        return processes;
    } catch (error) {
        return rejectWithValue(String(error));
    }
});

const fetcherNames = getAllEnumValues(FetcherName);

const initialState = {
    data: {},
    fetchers: mapValues(
        keyBy(fetcherNames, (n) => n),
        generateEmptyFetcherState,
    ),
} as ProcessesState;

const processesSlice = createSlice({
    name: 'processes',
    initialState,
    reducers: {
        updateProcess: (state, action: PayloadAction<ProcessApiModel>) => {
            const processId = action.payload.id;
            if (action.payload) {
                const existingResumeUrl = state.data[processId]?.candidate.resumeUrl;
                state.data[processId] = action.payload;
                if (existingResumeUrl && !state.data[processId]!.candidate.resumeUrl) {
                    state.data[processId]!.candidate.resumeUrl = existingResumeUrl;
                }
            }
        },
        updateResumeForProcess: (state, action: PayloadAction<SetResumeLinkPayload>) => {
            const { processId, resumeLink } = action.payload;
            const processState = state.data[processId];
            if (processState) {
                processState.candidate.resumeUrl = resumeLink;
            }
        },
        markIdsToFetch: (state, action: PayloadAction<string[]>) => {
            const ids = action.payload;
            for (const id of ids) {
                if (!state.data[id]) {
                    state.data[id] = undefined;
                }
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveProcesses.pending, (state, action) => {
                const { fetcherId } = action.meta.arg;
                state.fetchers[fetcherId].status = FetchModelStatus.LOADING;
            })
            .addCase(saveProcesses.fulfilled, (state, action) => {
                const { fetcherId } = action.meta.arg;
                const fetcherState = state.fetchers[fetcherId];
                fetcherState.status = FetchModelStatus.SUCCESS;
                fetcherState.initiated = true;
                if (action.payload) {
                    state.data = {
                        ...state.data,
                        ...keyBy(action.payload, 'id'),
                    };
                }
            })
            .addCase(saveProcesses.rejected, (state, action) => {
                const { fetcherId } = action.meta.arg;
                const fetcherState = state.fetchers[fetcherId];
                fetcherState.status = FetchModelStatus.FAILURE;
                fetcherState.error = action.error.message;
            });
    },
});

export default processesSlice;
