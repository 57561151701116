import { TurnOffIcon } from '@/components/svgs';
import { cn, signOutFirebase } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { sidebar as strings } from '@/strings';

function UserMenu() {
    const onSignout = () => {
        trackEvent(MixpanelEvent.SignOut);
        signOutFirebase();
    };
    return (
        <div className={cn('rounded-xl overflow-hidden')}>
            <div
                className={cn(
                    'bg-white hover:bg-accent300  py-8 pl-6 pr-14 h-[64px] flex items-center cursor-pointer',
                )}
                onClick={onSignout}
            >
                <TurnOffIcon />
                <p className={cn('ml-4 text-13 font-semibold text-black')}>
                    {strings.userMenu.signout}
                </p>
            </div>
        </div>
    );
}

export default UserMenu;
