import { TimeInterval } from '@/lib/types';
import { format, parse, parseISO } from 'date-fns';

export function dayStringToLocalDate(day: string): Date {
    return parseISO(day);
}

export function isoStringToDayString(isoString: string): string {
    return isoString.split('T')[0];
}

export function getLocalDayFromIsoString(isoString: string): string {
    return dateTimeToDayString(new Date(isoString));
}

export function dateTimeToDayString(date: Date): string {
    return format(date, 'yyyy-MM-dd');
}

export function hourNumToString(hs: number): string {
    const parsedTime = parse(`${hs}`, 'HH', new Date());
    return format(parsedTime, 'hh:mm aa');
}

export function hourAndMinuteToString(hs: number, ms: number): string {
    const parsedTime = parse(`${hs}:${ms}`, 'HH:mm', new Date());
    return format(parsedTime, 'hh:mm aa');
}

export function sortIntervals(a: TimeInterval, b: TimeInterval): number {
    return a.start.valueOf() - b.start.valueOf();
}
