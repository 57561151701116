import { Dialog } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

import ConfirmationDialogContent from './ConfirmationDialogContent';
import SuccessContent from './SuccessContent';
import { ConfirmationDialogProps, Step } from './types';

function ConfirmationDialog({
    header,
    content,
    successContent,
    isOpen,
    setOpen,
}: ConfirmationDialogProps) {
    const [step, setStep] = useState<Step>(Step.Init);
    const dialogAnimationLength = useDialogAnimationLength();

    const hasSuccessStep = !!successContent;
    const confirmCallback = hasSuccessStep ? () => setStep(Step.Success) : undefined;

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => setStep(Step.Init), dialogAnimationLength);
        }
    }, [isOpen, setStep, dialogAnimationLength]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            contentProps={{ className: cn('w-[650px]') }}
            title={step === Step.Init ? header : null}
            headerProps={{
                className: cn('py-8', { 'border-0': step === Step.Success }),
            }}
        >
            <>
                {step === Step.Init && (
                    <ConfirmationDialogContent
                        {...content}
                        confirmCallback={confirmCallback}
                    />
                )}
                {step === Step.Success && hasSuccessStep && (
                    <SuccessContent {...successContent} />
                )}
            </>
        </Dialog>
    );
}

export default ConfirmationDialog;
