import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

const strings = candidatesStrings.newRequests.candidatesTable;

function NewRequestsHeader({ count }: { count: number }) {
    return (
        <div className={cn('inline-flex items-center justify-center')}>
            {count > 0 && (
                <div className={cn('rounded-full bg-green h-[8px] w-[8px]')} />
            )}
            <p className={cn('ml-3 text-black text-13 font-semibold')}>
                {strings.headerNewRequests.func(count)}
            </p>
        </div>
    );
}

export default NewRequestsHeader;
