import { MailIcon, PhoneIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import { Process, Profession } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.common.candidateTitle;

export function CandidateHeader({ process }: { process: Process }) {
    const { candidate } = process;
    const { profession, name } = candidate;
    return (
        <div className={cn('flex items-center justify-between w-full')}>
            <div className={cn('flex items-center w-full')}>
                <ProfessionAvatar
                    profession={profession as Profession}
                    className={cn('w-14 h-14')}
                />
                <div className={cn('pl-4')}>
                    <p className={cn('text-15 font-semibold')}>{name}</p>
                    <div className={cn('flex items-center gap-4')}>
                        <div className={cn('flex items-center')}>
                            <MailIcon className={cn('mr-2 mt-[2px]')} />
                            <p className={cn('text-11')}>
                                {candidate.email ?? strings.noEmail}
                            </p>
                        </div>
                        <div className={cn('w-1 h-1 bg-black rounded-full')} />
                        <div className={cn('flex items-center')}>
                            <PhoneIcon className={cn('mr-2')} />
                            <p className={cn('text-11')}>
                                {candidate.phone
                                    ? strings.phoneNumber.func(candidate.phone)
                                    : null}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div />
        </div>
    );
}

export default CandidateHeader;
