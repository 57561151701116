import { useAppDispatch } from '@/_helpers/store';
import { fetchResumeForProcess, resetResumeLink } from '@/_reducers/processes';
import { Process } from '@/lib/types';
import { useCallback, useEffect } from 'react';

export function useResumeUrlFetcher(process: Process, dontFetch = false) {
    const dispatch = useAppDispatch();
    const { candidate, id } = process;
    const resumeUrl = candidate?.resumeUrl;
    const hasResume = candidate?.hasResume;
    const hasFetcheedResume = typeof resumeUrl === 'string';

    const fetchResume = useCallback(() => {
        dispatch(fetchResumeForProcess(id));
    }, [id, dispatch]);

    const refreshResumeLink = useCallback(() => {
        dispatch(resetResumeLink(id));
        fetchResume();
    }, [id, fetchResume, dispatch]);

    useEffect(() => {
        if (!hasResume) return;
        if (!hasFetcheedResume && !dontFetch) {
            fetchResume();
        }
    }, [hasFetcheedResume, hasResume, dontFetch, fetchResume]);

    return {
        refresh: refreshResumeLink,
    };
}
