import {
    AlertIcon,
    ContactCalendarIcon,
    FileCheckIcon,
    OrientationIcon,
    PhonePlusIcon,
    RescheduleIcon,
    TerminateIcon,
    VIcon,
} from '@/components/svgs';
import { PopoverMenuItem } from '@/components/ui';
import { ProcessAction, ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { Process, ProcessNextStep } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

import { MoveForwardActionMenuItem } from './types';

const strings = candidatesStrings.inProcess;

export function getAllowedNextSteps(process: Process): ProcessNextStep[] {
    const res: ProcessNextStep[] = [];
    for (const action of process.actions) {
        if (action === ProcessAction.MoveToPhoneInterview) {
            res.push(ProcessStatus.PhoneInterview);
        } else if (action === ProcessAction.MoveToInPersonInterview) {
            res.push(ProcessStatus.InPersonInterview);
        } else if (action === ProcessAction.MoveToOrientation) {
            res.push(ProcessStatus.Orientation);
        } else if (action === ProcessAction.MoveToPaperwork) {
            res.push(ProcessStatus.Paperwork);
        } else if (action === ProcessAction.MarkSuccess) {
            res.push(ProcessStatus.Hired);
        }
    }
    return res;
}

export function getMoveForwardMenuItems(
    process: Process,
    moveForward: (nextStep: ProcessNextStep) => void,
): MoveForwardActionMenuItem[] {
    if (process.status === ProcessStatus.PhoneInterview) {
        return [
            {
                id: ProcessStatus.InPersonInterview,
                content: {
                    label: strings.actions[ProcessStatus.InPersonInterview],
                    Icon: ContactCalendarIcon,
                },
                onClick: () => moveForward(ProcessStatus.InPersonInterview),
            },
            {
                id: ProcessStatus.Paperwork,
                content: {
                    label: strings.actions[ProcessStatus.Paperwork],
                    Icon: FileCheckIcon,
                },
                onClick: () => moveForward(ProcessStatus.Paperwork),
            },
            {
                id: ProcessStatus.Orientation,
                content: {
                    label: strings.actions[ProcessStatus.Orientation],
                    Icon: OrientationIcon,
                },
                onClick: () => moveForward(ProcessStatus.Orientation),
            },
            {
                id: ProcessStatus.PhoneInterview,
                content: {
                    label: strings.actions[ProcessStatus.PhoneInterview],
                    Icon: PhonePlusIcon,
                },
                onClick: () => moveForward(ProcessStatus.PhoneInterview),
            },
        ];
    }
    if (process.status === ProcessStatus.InPersonInterview) {
        return [
            {
                id: ProcessStatus.Paperwork,
                content: {
                    label: strings.actions[ProcessStatus.Paperwork],
                    Icon: FileCheckIcon,
                },
                onClick: () => moveForward(ProcessStatus.Paperwork),
            },
            {
                id: ProcessStatus.Orientation,
                content: {
                    label: strings.actions[ProcessStatus.Orientation],
                    Icon: OrientationIcon,
                },
                onClick: () => moveForward(ProcessStatus.Orientation),
            },
            {
                id: ProcessStatus.InPersonInterview,
                content: {
                    label: strings.stateSpecificActions.in_person_interview[
                        ProcessStatus.InPersonInterview
                    ],
                    Icon: ContactCalendarIcon,
                },
                onClick: () => moveForward(ProcessStatus.InPersonInterview),
            },
        ];
    }
    if (process.status === ProcessStatus.Orientation) {
        return [
            {
                id: ProcessStatus.Hired,
                content: {
                    label: strings.stateSpecificActions.orientation.successCandidate,
                    Icon: VIcon,
                },
                onClick: () => moveForward(ProcessStatus.Hired),
            },
        ];
    }
    if (process.status === ProcessStatus.Paperwork) {
        return [
            {
                id: ProcessStatus.Orientation,
                content: {
                    label: strings.stateSpecificActions.paperwork[
                        ProcessStatus.Orientation
                    ],
                    Icon: OrientationIcon,
                },
                onClick: () => moveForward(ProcessStatus.Orientation),
            },
        ];
    }
    return [];
}

export function getAllowedMoveForwardActions(
    process: Process,
    moveForward: (nextStep: ProcessNextStep) => void,
): MoveForwardActionMenuItem[] {
    const allowedNextSteps = getAllowedNextSteps(process);
    return getMoveForwardMenuItems(process, moveForward).filter((item) =>
        allowedNextSteps.includes(item.id),
    );
}

export function getAlloweNegativeActions(
    process: Process,
    {
        terminate,
        reschedule,
        didntHappen,
    }: {
        terminate: () => void;
        reschedule: () => void;
        didntHappen: () => void;
    },
): PopoverMenuItem[] {
    const items: PopoverMenuItem[] = [];
    if (process.actions.includes(ProcessAction.RequestRescheduleEvent)) {
        const isPast = process.substatus === ProcessSubstatus.Done;
        if (isPast) {
            const isOrientation = process.status === ProcessStatus.Orientation;
            items.push({
                id: 'didntHappen',
                content: {
                    label: isOrientation
                        ? strings.actions.orientationDidntHappen
                        : strings.actions.interviewDidntHappen,
                    Icon: AlertIcon,
                },
                onClick: didntHappen,
            });
        } else {
            items.push({
                id: 'reschedule',
                content: {
                    label: strings.actions.reschedule,
                    Icon: RescheduleIcon,
                    iconProps: { className: cn('scale-[1.2]') },
                },
                onClick: reschedule,
            });
        }
    }
    if (process.actions.includes(ProcessAction.TerminateProcess)) {
        items.push({
            id: 'terminate',
            content: {
                label: strings.actions.terminate,
                Icon: TerminateIcon,
            },
            onClick: terminate,
        });
    }
    return items;
}
