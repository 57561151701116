import Loader from '@/components/lotties/Loader';
import { ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates as strings } from '@/strings';

import NewCandidatesTopRow from './NewCandidatesTopRow';
import NoCandidatesView from './NoCandidatesView';
import NewCandidatesTable from './newProcessesTable/NewCandidatesTable';
import { NewCandidatesViewProps } from './types';

const tableStrings = strings.newRequests.candidatesTable;

function NewRequestsLayout({
    processes,
    profFilter,
    setProfFilter,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
    filteredProcesses,
    totalNewCandidates,
    isLoadingProcesses,
    initiatedProcesses,
    noNewCandidates,
}: NewCandidatesViewProps) {
    return (
        <ScrollableFillerDiv
            growDivProps={{ className: cn('relative px-10 pt-8 pb-2 lg:px-8 lg:pt-6') }}
            relativeDivProps={{ className: cn('overflow-auto') }}
            absoluteDivProps={{ className: cn('min-w-fit pb-6 lg:pb-4') }}
            fixedDiv={
                <NewCandidatesTopRow
                    profFilter={profFilter}
                    setProfFilter={setProfFilter}
                    models={processes}
                    initiatedRecruiterAv={initiatedRecruiterAv}
                    openSetAvailabilityDialog={openSetAvailabilityDialog}
                    openSetAvailabilityDialogDismissOthers={
                        openSetAvailabilityDialogDismissOthers
                    }
                />
            }
        >
            <>
                <NewCandidatesTable
                    models={filteredProcesses}
                    totalNewCandidates={totalNewCandidates}
                />
                {isLoadingProcesses && !initiatedProcesses && (
                    <div
                        className={cn(
                            'absolute pt-20 flex items-center justify-center w-full h-full top-0 left-0',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' />
                    </div>
                )}
                {noNewCandidates && <NoCandidatesView text={tableStrings.emptyTable} />}
            </>
        </ScrollableFillerDiv>
    );
}

export default NewRequestsLayout;
