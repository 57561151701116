import config from '@/config';
import { deleteApp, getApp, getApps, initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
    apiKey: config.FIREBASE_API_KEY,
    authDomain: config.FIREBASE_AUTH_DOMAIN,
};

// Initialize Firebase, first reset the app if it already exists
if (getApps().length) {
    deleteApp(getApp());
}
const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

export const signinWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(firebaseAuth, provider);
};

export const signOutFirebase = async () => {
    await firebaseAuth.signOut();
};
