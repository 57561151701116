import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export const attributeRowVariants = cva('', {
    variants: {
        variant: {
            default: cn(''),
            big: cn('py-10 lg:py-6'),
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export type AttributeRowVariantProps = VariantProps<typeof attributeRowVariants>;

export const detailsCardHeaderVariants = cva('flex items-center w-full', {
    variants: {
        headerVariant: {
            default: 'h-24 lg:h-20 xs:h-20',
            small: 'h-20',
        },
    },
    defaultVariants: {
        headerVariant: 'default',
    },
});

export type DetailsCardHeaderVariantProps = VariantProps<
    typeof detailsCardHeaderVariants
>;
