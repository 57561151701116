import { DialogContentWithFooter } from '@/components/ui';
import { cn, getErrorsForAvailability, keysOf } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';
import { isEmpty, mapValues } from 'lodash';
import { ErrorOption, FormProvider, useForm } from 'react-hook-form';

import AvailabilityForm from './AvailabilityForm';
import AvailabilityFormProviderActions from './AvailabilityFormActions';
import { AvailabilityFormProviderProps, AvailabilityFormSchema } from './types';

const strings = candidatesStrings.newRequests.recruiterAvailability;

const customResolver = (data: AvailabilityFormSchema) => {
    const errors = {} as Record<
        keyof AvailabilityFormSchema,
        Record<number, ErrorOption>
    >;
    let valid = true;

    for (const dayKey of keysOf(data)) {
        const dayIntervals = data[dayKey];
        const dayErrors = getErrorsForAvailability(dayIntervals);
        if (!isEmpty(dayErrors)) {
            valid = false;
            errors[dayKey] = mapValues(dayErrors, (err) => ({
                message: strings.errors[err],
                type: err,
            }));
        }
    }
    return {
        values: valid ? data : {},
        errors,
    };
};

function AvailabilityFormProvider({
    initialState,
    ...formProps
}: AvailabilityFormProviderProps) {
    const methods = useForm<AvailabilityFormSchema>({
        defaultValues: initialState,
        resolver: customResolver,
        mode: 'onChange',
    });

    return (
        <FormProvider {...methods}>
            <DialogContentWithFooter
                footer={<AvailabilityFormProviderActions {...formProps} />}
                bodyProps={{
                    className: cn('p-0'),
                }}
            >
                <AvailabilityForm />
            </DialogContentWithFooter>
        </FormProvider>
    );
}

export default AvailabilityFormProvider;
