import Loader from '@/components/lotties/Loader';
import { cn } from '@/lib/utils';

function SectionSummaryTile({
    count,
    label,
    onClick,
    isLoading,
    initiated,
}: {
    count: number;
    label: string;
    onClick?: () => void;
    isLoading: boolean;
    initiated: boolean;
}) {
    return (
        <div
            onClick={onClick}
            className={cn(
                'bg-white rounded-2xl p-6 pr-4 w-full max-w-56 cursor-pointer',
            )}
        >
            {!initiated && isLoading && (
                <div className={cn('py-[8px]')}>
                    <Loader variant='primary' sizing={'md'} />
                </div>
            )}
            {initiated && (
                <div
                    className={cn('text-accent700 font-medium text-[40px] leading-[1]')}
                >
                    {count}
                </div>
            )}
            <div className={cn('mt-2 text-black text-11 font-semibold')}>{label}</div>
        </div>
    );
}

export default SectionSummaryTile;
