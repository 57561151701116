import { Dialog } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { RecruiterTerminationReason } from '@/lib/api/v1';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

import CandidateTitle from '../common/CandidateTitle';
import { BaseDialogPropsCommon } from '../types';
import { TerminateProcessDialogContent } from './content';
import TerminationSuccessContent from './successContent';
import { TerminateFunc } from './types';

interface Props extends BaseDialogPropsCommon {
    process: Process;
    terminate: TerminateFunc;
    defaultSelection?: RecruiterTerminationReason;
}

enum Step {
    Form = 'form',
    Terminated = 'terminated',
}

function TerminateProcessDialog({
    process,
    isOpen,
    setOpen,
    terminate,
    handleClose,
    defaultSelection,
}: Props) {
    const dialogAnimationLength = useDialogAnimationLength();
    const [step, setStep] = useState<Step>(Step.Form);

    const onTermination: TerminateFunc = async (params) => {
        return await terminate(params).then(() => setStep(Step.Terminated));
    };

    const noBorderHeader = step === Step.Terminated;

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => setStep(Step.Form), dialogAnimationLength);
        }
    }, [isOpen, setStep, dialogAnimationLength]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            title={<>{step === Step.Form && <CandidateTitle process={process} />}</>}
            contentProps={{ className: cn('w-[650px]') }}
            headerProps={{
                className: cn('py-8', { 'border-0': noBorderHeader }),
            }}
        >
            <>
                {step === Step.Form && (
                    <TerminateProcessDialogContent
                        process={process}
                        handleClose={handleClose}
                        defaultReason={defaultSelection}
                        terminate={onTermination}
                    />
                )}
                {step === Step.Terminated && (
                    <TerminationSuccessContent process={process} />
                )}
            </>
        </Dialog>
    );
}

export default TerminateProcessDialog;
