import { ProcessStatus } from '@/lib/api/v1';
import { Process, ProcessNextStep } from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.moveProcessForward;

interface StringsOutput {
    title: string;
    description: string;
    submitButton: string;
    freeTextPlaceholder: string;
    cancelButton: string;
    successTitle: string;
    successDescription: string;
}

export function getStringsForDialog(
    process: Process,
    nextStep: ProcessNextStep,
): StringsOutput {
    const sameStepStringsOverride =
        process.status === nextStep &&
        (process.status === ProcessStatus.Orientation ||
            process.status === ProcessStatus.InPersonInterview)
            ? strings.moveToSameStatus[process.status]
            : undefined;
    return {
        cancelButton: strings.cancelButton,
        description: strings.description,
        freeTextPlaceholder: strings.freeTextPlaceholder,
        successTitle: strings.successStep.title.func(getFirstName(process.candidate)),
        successDescription:
            sameStepStringsOverride?.successDescription ??
            strings.successStep.description[nextStep],
        submitButton: strings[nextStep].submitButton,
        title: sameStepStringsOverride?.title ?? strings[nextStep].title,
    };
}
