import { LoadingButton, TextInput } from '@/components/ui';
import { cn } from '@/lib/utils';
import strings from '@/strings/login';

import { SignInSectionProps } from './types';
import useSignIn from './useSignIn';

function SignInSectionMobile({
    onEmailSent,
    setError,
    error,
    email,
    setEmail,
    signInFromEmailLink,
}: SignInSectionProps) {
    const { clearError, onKeyDown, onSubmit } = useSignIn({
        onEmailSent,
        setError,
        email,
        signInFromEmailLink,
    });

    return (
        <div className={cn('w-full flex justify-center')}>
            <div className={cn('max-w-[450px] w-full')}>
                <div className={cn('')}>
                    <p className={cn('font-bold text-24')}>{strings.loginTitle}</p>
                </div>
                <div className={cn('mt-10')}>
                    <TextInput
                        label={strings.emailInput.label}
                        value={email}
                        setValue={setEmail}
                        error={error}
                        clearError={clearError}
                        placeholder={strings.emailInput.placeholder}
                        onKeyDown={onKeyDown}
                    />
                    <LoadingButton
                        onClick={onSubmit}
                        disabled={email.length === 0}
                        className={cn('w-full mt-10')}
                        size={'xl'}
                    >
                        {strings.signInButton}
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
}

export default SignInSectionMobile;
