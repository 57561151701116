import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';

import ProcessBlock from './ProcessBlock';
import { PROCESS_BLOCK_FLEX_CLASS } from './consts';

function InProcessCollection({
    processes,
    onProcessClick,
}: {
    processes: Process[];
    onProcessClick: (processId: string) => void;
}) {
    return (
        <div className={cn('relative h-full w-fit flex justify-center')}>
            <div
                className={cn(
                    'relative pt-8 mx-auto inline-flex gap-x-8 justify-center flex-wrap min-w-[600px]',
                )}
            >
                {processes.map((process) => (
                    <ProcessBlock
                        key={process.id}
                        process={process}
                        onClick={() => {
                            trackEvent(MixpanelEvent.ExpandCandidateInProcess, {
                                candidate_id: process.candidate.id,
                                candidate_name: process.candidate.name,
                                via: 'in_process_block',
                            });
                            onProcessClick(process.id);
                        }}
                    />
                ))}
                {Array.from({ length: 7 }).map((_, i) => (
                    <div key={i} className={cn(PROCESS_BLOCK_FLEX_CLASS)} />
                ))}
            </div>
        </div>
    );
}

export default InProcessCollection;
