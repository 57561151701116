import { cn } from '@/lib/utils';
import { HTMLAttributes, ReactNode } from 'react';

type DefualtDivProps = HTMLAttributes<HTMLDivElement>;

interface Props extends DefualtDivProps {
    children: ReactNode;
    fixedDiv: ReactNode;
    rootDivProps?: DefualtDivProps;
    growDivProps?: DefualtDivProps;
    relativeDivProps?: DefualtDivProps;
    absoluteDivProps?: DefualtDivProps;
    contentWrapperProps?: DefualtDivProps;
}

function ScrollableFillerDiv({
    children,
    className,
    fixedDiv,
    rootDivProps,
    growDivProps,
    relativeDivProps,
    absoluteDivProps,
    contentWrapperProps,
    ...restProps
}: Props) {
    const { className: rootDivClassName, ...rootDivRestProps } = rootDivProps ?? {};
    const { className: growDivClassName, ...growDivRestProps } = growDivProps ?? {};
    const { className: relativeDivClassName, ...relativeDivRestProps } =
        relativeDivProps ?? {};
    const { className: absoluteDivClassName, ...absoluteDivRestProps } =
        absoluteDivProps ?? {};
    const { className: contentWrapperClassName, ...contentWrapperRestProps } =
        contentWrapperProps ?? {};
    return (
        <div
            className={cn('flex flex-col h-full', rootDivClassName)}
            {...rootDivRestProps}
        >
            {fixedDiv}
            <div className={cn('flex-grow', growDivClassName)} {...growDivRestProps}>
                <div
                    className={cn('relative h-full w-full', relativeDivClassName)}
                    {...relativeDivRestProps}
                >
                    <div
                        className={cn('absolute h-full w-full', absoluteDivClassName)}
                        {...absoluteDivRestProps}
                    >
                        <div
                            className={cn('relative h-full', contentWrapperClassName)}
                            {...contentWrapperRestProps}
                        >
                            <div
                                className={cn('h-full overflow-auto', className)}
                                {...restProps}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScrollableFillerDiv;
