import { ProfessionAvatar } from '@/components/ui';
import { Process, Profession } from '@/lib/types';
import { cn } from '@/lib/utils';

export function CandidateHeaderMobile({ process }: { process: Process }) {
    const { candidate } = process;
    const { profession, name } = candidate;
    return (
        <div className={cn('flex items-center gap-4 w-full')}>
            <ProfessionAvatar
                profession={profession as Profession}
                className={cn('w-14 h-14')}
            />
            <p className={cn('text-15 font-bold')}>{name}</p>
        </div>
    );
}

export default CandidateHeaderMobile;
