// import { HomeIcon, CandidatesIcon, CalendarIcon, SettingsIcon } from "@/components/svgs";
import { CandidatesIcon, HomeIcon } from '@/components/svgs';
import useGoTo from '@/hooks/useGoTo';
import { PageId } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { sidebar as strings } from '@/strings';

import { NavItemConfig } from './types';

function useNavItemsConfig() {
    // const { goToHome, goToCalendar, goToCandidates, goToSettings } = useGoTo();
    const { goToHome, goToCandidates } = useGoTo();

    const items: NavItemConfig[] = [
        {
            id: PageId.Home,
            label: strings.home,
            action: () => {
                trackEvent(MixpanelEvent.NavigateHomePage);
                goToHome();
            },
            Icon: HomeIcon,
        },
        {
            id: PageId.Candidates,
            label: strings.candidates,
            action: () => {
                trackEvent(MixpanelEvent.NavigateCandidatesPage);
                goToCandidates();
            },
            Icon: CandidatesIcon,
        },
        // {
        //     id: PageId.Calendar,
        //     label: "Calendar",
        //     action: goToCalendar,
        //     Icon: CalendarIcon,
        // },
        // {
        //     id: PageId.Settings,
        //     label: "Settings",
        //     action: goToSettings,
        //     Icon: SettingsIcon,
        // },
    ];
    return items;
}

export default useNavItemsConfig;
