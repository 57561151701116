import { useEffect, useState } from 'react';

const getScrollbarWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    document.body.removeChild(outer);

    return scrollbarWidth;
};

function useScrollbarWidth() {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);
    if (!scrollbarWidth) {
        setScrollbarWidth(getScrollbarWidth());
    }
    useEffect(() => {
        const handleResize = () => {
            setScrollbarWidth(getScrollbarWidth());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return scrollbarWidth;
}

export default useScrollbarWidth;
