import { ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { InProcessFilter, Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candidateStrings } from '@/strings';
import { groupBy, sortBy } from 'lodash';

import { InProcessStep } from '../types';
import { SubStatusFilter } from './types';

const strings = candidateStrings.inProcess;

export const PROCESS_BLOCK_FLEX_CLASS = cn('w-[376px] max-w-[450px] flex-grow');

function isProcessEventRelated(process: Process): boolean {
    return (
        process.status === ProcessStatus.PhoneInterview ||
        process.status === ProcessStatus.InPersonInterview ||
        process.status === ProcessStatus.Orientation
    );
}

export function getSubstatusText(process: Process) {
    if (isProcessEventRelated(process)) {
        if (process.substatus === ProcessSubstatus.Pending) {
            if (process.status === ProcessStatus.Orientation) {
                return strings.substatusesDescription.orientationRequestSent;
            }
            return strings.substatusesDescription.interviewRequestSent;
        }
        if (process.substatus === ProcessSubstatus.Done) {
            return strings.substatusesDescription.pendingRecruiterAciton;
        }
        if (process.substatus === ProcessSubstatus.Scheduled) {
            const relevantEvent = process.relevantEvent;

            if (!relevantEvent) {
                return '';
            }
            const { startAt } = relevantEvent;
            if (startAt < new Date()) {
                return strings.substatusesDescription.upcomingEventPast.func(startAt);
            }
            return strings.substatusesDescription.upcomingEvent.func(startAt);
        }
    }
    return '';
}

export function shouldShowClockSvg(process: Process) {
    if (process.substatus !== ProcessSubstatus.Scheduled) {
        return false;
    }
    return isProcessEventRelated(process);
}

export function processToInProcessStep(process: Process): InProcessStep {
    if (process.status === ProcessStatus.PhoneInterview) {
        return InProcessStep.PhoneInterview;
    }
    if (process.status === ProcessStatus.InPersonInterview) {
        return InProcessStep.InPersonInterview;
    }
    if (process.status === ProcessStatus.Paperwork) {
        return InProcessStep.Paperwork;
    }
    if (process.status === ProcessStatus.Orientation) {
        return InProcessStep.Orientation;
    }
    if (process.status === ProcessStatus.Terminated) {
        return InProcessStep.Terminated;
    }
    return InProcessStep.InProgress;
}

export function getStatusBadgeText(process: Process) {
    const inProcessStep = processToInProcessStep(process);
    if (inProcessStep === InProcessStep.PhoneInterview) {
        return strings.statuses.phoneInterview;
    }
    if (inProcessStep === InProcessStep.InPersonInterview) {
        return strings.statuses.inPersonInterview;
    }
    if (inProcessStep === InProcessStep.Paperwork) {
        return strings.statuses.paperwork;
    }
    if (inProcessStep === InProcessStep.Orientation) {
        return strings.statuses.orientation;
    }
    if (inProcessStep === InProcessStep.Terminated) {
        return strings.statuses.terminated;
    }
    return strings.statuses.inProcess;
}

export function getStatusBadgeColorsCls(process: Process): string {
    const inProcessStep = processToInProcessStep(process);

    if (inProcessStep === InProcessStep.PhoneInterview) {
        return cn('text-black bg-phoneInterview');
    }
    if (inProcessStep === InProcessStep.InPersonInterview) {
        return cn('text-black', 'bg-inPersonInterview');
    }
    if (inProcessStep === InProcessStep.Paperwork) {
        return cn('text-black bg-paperwork');
    }
    if (inProcessStep === InProcessStep.Orientation) {
        return cn('text-black bg-orientation');
    }
    if (inProcessStep === InProcessStep.Terminated) {
        return cn('text-black bg-rejected');
    }
    return cn('text-black bg-accent300');
}

export function processToInProcessFilter(process: Process): InProcessFilter {
    const inProcessStep = processToInProcessStep(process);
    if (inProcessStep === InProcessStep.InProgress) {
        return InProcessFilter.InProgress;
    }
    if (inProcessStep === InProcessStep.Paperwork) {
        return InProcessFilter.Paperwork;
    }
    if (process.substatus === ProcessSubstatus.Done) {
        return InProcessFilter.PendingAction;
    }
    if (process.substatus === ProcessSubstatus.Scheduled) {
        return InProcessFilter.Upcoming;
    }
    if (process.substatus === ProcessSubstatus.Pending) {
        return InProcessFilter.InterviewRequest;
    }
    return InProcessFilter.InProgress;
}

export const substatusFilters: SubStatusFilter[] = [
    InProcessFilter.InterviewRequest,
    InProcessFilter.PendingAction,
    InProcessFilter.Upcoming,
    InProcessFilter.Paperwork,
    InProcessFilter.InProgress,
];

function sortProcesses(processes: Process[], filterGroup: SubStatusFilter): Process[] {
    if (filterGroup === InProcessFilter.Upcoming) {
        return sortBy(processes, (p) => p.relevantEvent?.startAt ?? Infinity);
    }
    return sortBy(processes, 'updatedAt').reverse();
}

export function getSortedProcesses(
    processes: Process[],
    filter: InProcessFilter,
): Process[] {
    if (filter !== InProcessFilter.All) {
        return sortProcesses(processes, filter);
    }
    const groupedProcesses = groupBy(processes, processToInProcessFilter);
    return substatusFilters
        .map((filterGroup) => sortProcesses(groupedProcesses[filterGroup], filterGroup))
        .flat();
}
