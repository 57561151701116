import { cn } from '@/lib/utils';

import NavItemsMenuMobile from './navItemsMenuMobile';

export default function FooterMenu() {
    return (
        <div
            className={cn(
                'absolute bottom-0 w-full h-[66px] border-t border-neutral100',
            )}
        >
            <div className={cn('relative w-full h-full bg-white')}>
                <NavItemsMenuMobile />
            </div>
        </div>
    );
}
