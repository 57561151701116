import * as Sentry from '@sentry/react';

const createLogger = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = (error: any) => {
        console.error(error);
        Sentry.captureException(typeof error === 'string' ? new Error(error) : error);
    };

    const warn = (warning: string) => {
        console.warn(warning);
        Sentry.captureMessage(warning, 'warning');
    };

    const log = (msg: string) => {
        console.log(msg);
        Sentry.captureMessage(msg, 'info');
    };

    return { error, warn, log };
};

const Logger = createLogger();
export default Logger;
