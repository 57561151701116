import { CarIcon, VIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';

import { JobMatchRowProps } from './types';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function JobMatchRow({ jobMatch, selected, onClick }: JobMatchRowProps) {
    return (
        <div
            className={cn(
                'p-6 lg:p-4 rounded-2xl w-full border border-neutral200',
                'transition-colors duration-200 ease-in-out',
                'flex items-center justify-between gap-2 cursor-pointer',
                { 'border-primary cursor-default': selected },
                { 'hover:border-neutral300': !selected },
            )}
            onClick={!selected ? () => onClick(jobMatch.id) : undefined}
        >
            <div className={cn('relative flex items-center')}>
                {selected && (
                    <VIcon
                        className={cn(
                            'absolute top-1/2 left-0  -translate-y-1/2 h-4 w-6 stroke-primary fill-primary',
                        )}
                    />
                )}
                <div
                    className={cn('pr-8 text-13-23 font-semibold', {
                        'pl-8 pr-0': selected,
                    })}
                >
                    {jobMatch.facility.name}
                </div>
            </div>
            <div className={cn('flex items-center gap-2 whitespace-nowrap')}>
                <CarIcon />
                <div className={cn('text-12 font-semibold')}>
                    {strings.facilityDistance.func(jobMatch.commuteDuration)}
                </div>
            </div>
        </div>
    );
}

export default JobMatchRow;
