import { fetchUpcomingEvents } from '@/_reducers/events';
import { UpcomingEventsFetcherData, UpcomingEventsSelector } from '@/_selectors';
import useFetchers from '@/hooks/useFetchers';
import useMissingProcessesFetcher from '@/hooks/useMissingProcessesFetcher';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName } from '@/lib/types';
import { valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

function useUpcomingEvents() {
    useFetchers(fetchUpcomingEvents);
    const { isLoading: isLoadingMissingProcesses } = useMissingProcessesFetcher();
    const { openDialog: openEventDetailsDialog } = useOpenDialog(
        DialogName.EventDetails,
    );
    const eventsById = useSelector(UpcomingEventsSelector);

    const { initiated: initiatedEvents, isLoading: isLoadingEvents } = useSelector(
        UpcomingEventsFetcherData,
    );

    const onEventBlockClick = (eventId: string) => {
        const event = eventsById[eventId];
        trackEvent(MixpanelEvent.ClickUpcomingEvent, {
            candidate_id: event?.process?.candidate.id,
            candidate_name: event?.process?.candidate.name,
        });
        openEventDetailsDialog({ eventId });
    };

    const loading = isLoadingEvents || isLoadingMissingProcesses;
    const initiated = initiatedEvents && !isLoadingMissingProcesses;
    const showLoader = loading && !initiated;
    const events = valuesOf(eventsById);
    const totalCount = events.length;
    const isEmpty = totalCount === 0;
    return {
        totalCount,
        isEmpty,
        initiated,
        events: sortBy(events, 'startAt'),
        showLoader,
        isLoading: loading,
        onEventBlockClick,
    };
}

export default useUpcomingEvents;
