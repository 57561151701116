import { RootState } from '@/_helpers/store';

export const RecruiterAvailability = (state: RootState) => state.recruiterAvailability;
export const AuthState = (state: RootState) => state.auth;
export const ProcessesState = (state: RootState) => state.processes;
export const DialogsState = (state: RootState) => state.dialogs;
export const EventsState = (state: RootState) => state.events;
export const SessionSettingsState = (state: RootState) => state.sessionSettings;
export const ProcessHistoryState = (state: RootState) => state.processHistory;
export const IsMobileScreen = (state: RootState) => state.isMobileScreen;
