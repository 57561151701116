import { CalendarIcon } from '@/components/svgs';
import { EventWithRelations } from '@/lib/types';
import { cn } from '@/lib/utils';
import { homepage as homepageStrings } from '@/strings';

import EmptySectionView from './EmptySectionView';
import UpcomingEventBlockMobile from './UpcomingEventBlockMobile';

const strings = homepageStrings.upcomingEvents;

function UpcomingEventsSection({
    events,
    initiated,
    isLoading,
    onEventBlockClick,
    anchorRef,
}: {
    events: EventWithRelations[];
    initiated: boolean;
    isLoading: boolean;
    onEventBlockClick: (eventId: string) => void;
    anchorRef: React.RefObject<HTMLDivElement>;
}) {
    const count = events.length;
    const isEmpty = count === 0;

    return (
        <div className={cn('py-12')} ref={anchorRef}>
            <div className={cn('text-20 font-bold')}>
                {isEmpty && initiated
                    ? strings.mobile.noEventsTitle
                    : strings.title.func(initiated ? count : null)}
            </div>
            <div
                className={cn('mt-10 flex flex-col items-stretch justify-center gap-10')}
            >
                {isEmpty && (
                    <EmptySectionView
                        Icon={CalendarIcon}
                        label={strings.noEventsMsg}
                        loading={isLoading}
                        loaderProps={{ className: cn('my-[16px]') }}
                        iconProps={{ className: cn('w-14 h-14') }}
                    />
                )}
                {!isEmpty &&
                    events.map((event) => (
                        <UpcomingEventBlockMobile
                            key={event.id}
                            event={event}
                            onClick={() => onEventBlockClick(event.id)}
                        />
                    ))}
            </div>
        </div>
    );
}

export default UpcomingEventsSection;
