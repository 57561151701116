import { cn } from '@/lib/utils';

import MainDetailData from './MainDetailData';
import { MainDetailProps } from './types';

function MainDetail({ text, title, icon, noDivider }: MainDetailProps) {
    return (
        <>
            <div className={cn('flex items-center flex-shrink-0 flex-grow-0 w-auto')}>
                <div>
                    <div className={cn('flex items-center h-8')}>
                        <MainDetailData icon={icon} text={text} />
                    </div>
                    <div
                        className={cn(
                            'mt-1 font-medium text-neutral300 text-12 whitespace-nowrap',
                        )}
                    >
                        {title}
                    </div>
                </div>
            </div>
            {!noDivider && (
                <div
                    className={cn(
                        'flex items-center justify-center flex-grow flex-shrink basis-0 max-w-40',
                    )}
                >
                    <div className={cn('w-0 h-6 border-r border-neutral200')} />
                </div>
            )}
        </>
    );
}

export default MainDetail;
