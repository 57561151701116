import {
    DialogNameWithData,
    openDialogDismissOthers,
    setDialogOpen,
} from '@/_reducers/dialogs';
import { requestRescheduleEvent } from '@/_reducers/events';
import { fetchProcessesByIds } from '@/_reducers/processes';
import {
    InterviewDidntHappenDialogData,
    IsInterviewDidntHappenDialogOpen,
} from '@/_selectors';
import { RecruiterTerminationReason } from '@/lib/api/v1';
import { DialogName } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import InterviewDidntHappenDialog from './InterviewDidntHappenDialog';

function InterviewDidntHappenDialogProvider({
    setDialogOpenAction,
    openDialogDismissOthers,
    requestRescheduleEvent,
    fetchProcessesByIds,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    requestRescheduleEvent: (eventId: string) => Promise<void>;
    fetchProcessesByIds: (ids: string[]) => void;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
}) {
    const shouldBeOpen = useSelector(IsInterviewDidntHappenDialogOpen);
    const { process, event } = useSelector(InterviewDidntHappenDialogData);
    const hasData = !!process && !!event;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.InterviewDidntHappen, open);
    };
    const goToTerminate = () => {
        trackEvent(MixpanelEvent.UnfulfilledEventTerminate);
        openDialogDismissOthers(DialogName.TerminateProcess, {
            processId: process!.id,
            defaultSelection: RecruiterTerminationReason.Ghosting,
        });
    };

    const handleClose = () => setOpen(false);

    const onRescheduleClick = async () => {
        if (!event) return;
        trackEvent(MixpanelEvent.UnfulfilledEventReschedule);
        await requestRescheduleEvent(event.id).then(() => {
            fetchProcessesByIds([event.processId]);
        });
    };

    return (
        <>
            {hasData && (
                <InterviewDidntHappenDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    event={event}
                    goToTerminate={goToTerminate}
                    onReschedule={onRescheduleClick}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    openDialogDismissOthers,
    requestRescheduleEvent,
    fetchProcessesByIds,
};

export default connect(null, mapDispathToProps)(InterviewDidntHappenDialogProvider);
