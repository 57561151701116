import { Time } from '@/lib/base';
import { TimeIntervalWithDay } from '@/lib/types';
import {
    keysOfAsNumber,
    serializeFetcherState,
    timeIntervalsToAvailabilityRecords,
    valuesOf,
} from '@/lib/utils';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { RecruiterAvailability } from './base';

export const RecruiterAvailabilityData = createSelector(
    RecruiterAvailability,
    (s): TimeIntervalWithDay[] => {
        return s.data.map((range) => ({
            day: range.dayOfWeek,
            start: Time.createFromString(range.startTime),
            end: Time.createFromString(range.endTime),
        }));
    },
);

export const RecruiterAvailabilityFetcherData = createSelector(
    RecruiterAvailability,
    (s) => serializeFetcherState(s.fetcher),
);

export const AvailabilityByDay = createSelector(
    RecruiterAvailabilityData,
    (availability) => {
        if (isEmpty(availability)) {
            return undefined;
        }
        return timeIntervalsToAvailabilityRecords(availability);
    },
);

export const AvailableDays = createSelector(
    AvailabilityByDay,
    (availabilityRecordByDay) => {
        if (!availabilityRecordByDay) {
            return [];
        }
        return keysOfAsNumber(availabilityRecordByDay).filter(
            (day) => !!valuesOf(availabilityRecordByDay[day]).find((bool) => bool),
        );
    },
);

export const FillingAvailabilityRequired = createSelector(
    AvailableDays,
    (availableDays) => {
        return availableDays.length === 0;
    },
);
