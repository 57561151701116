import { RecordKey } from '@/lib/types';

export function keysOf<T extends string, M>(
    obj: Record<T, M> | Partial<Record<T, M>>,
): T[] {
    const keys: T[] = [];
    for (const key of Object.keys(obj)) {
        keys.push(key as T);
    }
    return keys;
}

export function keysOfAsNumber<K extends number, M>(obj: Record<K, M>): K[] {
    const keys: K[] = [];
    for (const key of Object.keys(obj)) {
        keys.push(Number(key) as K);
    }
    return keys;
}

export function valuesOf<T extends RecordKey, M>(
    obj: Record<T, M> | Partial<Record<T, M>>,
): M[] {
    const values: M[] = [];
    for (const val of Object.values(obj)) {
        values.push(val as M);
    }
    return values;
}
