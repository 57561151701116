import { PatchProcessStatus } from '@/_reducers/processes';
import {
    CandidateEvent,
    EventWithRelations,
    Process,
    ProcessPendingEventFeedback,
    ProcessWithRelations,
} from '@/lib/types';
import { ReactNode } from 'react';

export enum TaskType {
    PhoneInterviewFeedback = 'phone_interview_feedback',
    InPersonInterviewFeedback = 'in_person_interview_feedback',
    OrientationFeedback = 'orientation_feedback',
}

export type TaskBlockActionsProps = {
    process: Process;
    taskType: TaskType;
};

export type PendingTaskProcess = ProcessPendingEventFeedback & ProcessWithRelations;

export interface PendingTasksViewProps {
    processes: PendingTaskProcess[];
    initiated: boolean;
    loading: boolean;
}

export interface PendingTasksSectionProps {
    pendingTasksProcesses: PendingTaskProcess[];
    initiatedTasks: boolean;
    isLoadingTasks: boolean;
    anchorRef: React.RefObject<HTMLDivElement>;
}

export type HompageLayoutProps = Omit<PendingTasksSectionProps, 'anchorRef'>;

export interface NewCandidatesSectionProps {
    newProcesses: Process[];
    initiated: boolean;
    isLoading: boolean;
    goToCandidates: () => void;
    anchorRef: React.RefObject<HTMLDivElement>;
}

export interface SmallAttributeProps {
    text: string;
    icon: ReactNode;
    isFirst?: boolean;
}

export interface NewRequestsProps {
    process: Process;
    openCandidateDetails: () => void;
}

export interface UpcomingEventBlockBaseProps {
    event: EventWithRelations;
    onClick: (eventId: string) => void;
}

export interface UpcomingEventBlockProps extends UpcomingEventBlockBaseProps {
    openCandidateDetails: (process: Process) => void;
}

export type UpdateProcessFormParams = Omit<PatchProcessStatus, 'processId'>;

export type UpdateProcessFunc = (params: UpdateProcessFormParams) => Promise<void>;

export interface TaskBlockProps {
    taskType: TaskType;
    process: Process;
    event: CandidateEvent;
    openCandidateDialog: () => void;
}

export type NewCandidatesLayoutProps = {
    totalCount: number;
    isEmpty: boolean;
    initiated: boolean;
    isLoading: boolean;
    openCandidateDetails: (params: { processId: string }) => void;
    newCandidates: Process[];
    goToCandidates: () => void;
};
