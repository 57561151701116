import { ProcessHistory, ProcessHistoryApiModel } from '@/lib/types';
import { processHistory as strings } from '@/strings';

import { ProcessAction } from '../api/v1';
import {
    CustomHistoryAction,
    ProcessHistoryWithParsedAction,
} from '../types/processHistory';

export const parseProcessHistoryApiModel = (
    history: ProcessHistoryApiModel,
): ProcessHistory => {
    return {
        ...history,
        createdAt: new Date(history.createdAt),
    };
};

export const serializeProcessHistoryCollection = (
    sortedHistoryItems: ProcessHistory[],
): ProcessHistoryWithParsedAction[] => {
    const res: ProcessHistoryWithParsedAction[] = [];
    let isPrevActionWasTermination = false;
    for (const historyItem of sortedHistoryItems) {
        const isTermination = historyItem.action === ProcessAction.TerminateProcess;
        let actionName = parseProcessHistoryActionString(historyItem);
        if (actionName) {
            if (isPrevActionWasTermination && !isTermination) {
                actionName = parseProcessHistoryActionString({
                    ...historyItem,
                    action: CustomHistoryAction.Revive,
                })!;
            }
            isPrevActionWasTermination = isTermination;
            res.push({
                ...historyItem,
                actionName,
            });
        }
    }
    return res;
};

export function parseProcessHistoryActionString(history: ProcessHistory): string | null {
    const stringObj = strings.processHistoryActionToString[history.action];
    if (typeof stringObj === 'string') {
        return stringObj;
    }
    if (!stringObj || !history.status) {
        return null;
    }
    return stringObj.func(history.status);
}
