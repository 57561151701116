import { SelectionButton } from '@/components/ui';
import InProcessActionsView from '@/inProcessActionsMenu/InProcessActionsView';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as homepageStrings } from '@/strings';
import { useState } from 'react';

import { TaskBlockActionsProps } from './types';

const strings = homepageStrings.tasks;

function TaskBlockActions({ process, taskType }: TaskBlockActionsProps) {
    const [open, setOpen] = useState(false);

    return (
        <div className={cn('flex items-center')}>
            <InProcessActionsView
                open={open}
                setOpen={setOpen}
                process={process}
                triggerProps={{ asChild: true }}
                trigger={
                    <div onClick={(e) => e.stopPropagation()}>
                        <SelectionButton
                            open={open}
                            label={strings.moveForwardButton}
                            onClick={() => {
                                trackEvent(MixpanelEvent.PendingTaskOpenActionsMenu, {
                                    task_type: taskType,
                                    candidate_id: process.candidate.id,
                                    candidate_name: process.candidate.name,
                                });
                            }}
                        />
                    </div>
                }
            />
        </div>
    );
}

export default TaskBlockActions;
