import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

import { TIME_INTERVAL_ROW_HEIGHT_CLASS } from './common';

const strings = candidatesStrings.newRequests.recruiterAvailability;

function UnavailabileIndication() {
    return (
        <div
            className={cn(
                TIME_INTERVAL_ROW_HEIGHT_CLASS,
                'relative ml-10 xs:ml-0 w-[263px] flex items-center',
            )}
        >
            <p className={cn('text-14 text-neutral300 leading-1')}>
                {strings.emptyDayLabel}
            </p>
        </div>
    );
}

export default UnavailabileIndication;
