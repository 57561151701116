import { AvailabilityFormSchema } from '@/dialogs/recruiterAvailability/types';
import { mapValues } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { isMixpanelOn, sendEventToLog } from './utils';

export enum MixpanelEvent {
    Login = 'Login',
    SignOut = 'Sign Out',
    SetAvailability = 'Set Availability',
    NewRequestTerminateCandidate = 'New Request: Terminate Candidate',
    NewRequestConfirmTerminateCandidate = 'New Request: Confirm Terminate Candidate',
    ApproveCandidate = 'New Request: Approve Candidate',
    PageView = 'View Page',
    FilterNewCandidates = 'Filter New Candidates',
    FilterCandidatesInProcess = 'Filter Candidates In Process',
    ExpandNewCandidate = 'Expand New Candidate',
    ExpandCandidateInProcess = 'Expand Candidate In Process',
    ViewSectionInProcess = 'Candidates: View Section In Process',
    ViewSectionNewRequests = 'Candidates: View Section New Requests',
    ViewResume = 'View Resume',
    DownloadResume = 'Download Resume',
    HomepageNavigateNewCandidates = 'Homepage: Navigate New Candidates',
    NavigateCandidatesPage = 'Navigate Candidates Page',
    NavigateHomePage = 'Navigate Homepage',
    HomepageClickCandidateName = 'HomePage: Expand Candidate Details',
    ClickUpcomingEvent = 'Homepage: Click Upcoming Event',
    FacilityPickerSubmit = 'Facility Picker: Submit',

    PendingTaskOpenActionsMenu = 'Pending Task: Open Actions Menu',
    ProcessCardOpenActionsMenu = 'Process Card: Open Actions Menu',
    CandidateDialogOpenActionsMenu = 'Candidate Dialog: Open Actions Menu',

    ProcessActionsMoveForward = 'Process Actions: Move Forward Candidate',
    ProcessActionsUnfulfilledEvent = 'Process Actions: Feedback Unfulfilled Event',
    ProcessActionsRescheduleEvent = 'Process Actions: Reschedule Event',
    ProcessActionsTerminateCandidate = 'Process Actions: Terminate Candidate',
    MoveForwardCandidate = 'Move Forward Candidate',
    TerminateCandidate = 'Terminate Candidate',
    UnfulfilledEventReschedule = 'Unfulfilled Event: Reschedule Event',
    UnfulfilledEventTerminate = 'Unfulfilled Event: Terminate Candidate',
    ConfirmRescheduleEvent = 'Confirm Reschedule Event',
    EventDetailsRescheduleEvent = 'Event Details: Reschedule Event',
    EventDetailsCancelEvent = 'Event Details: Cancel Event',
    CancelEventRescheduleEvent = 'Cancel Event: Reschedule Event',
    CancelEventTerminateCandidate = 'Cancel Event: Terminate Candidate',

    // Techincal:
    WindowResize = 'Resize Window',
}

type CandidateMixpanelData = {
    candidate_name: string;
    candidate_id: string;
};

export interface AllMixpanelEventsData {
    [MixpanelEvent.WindowResize]: {
        'Window Height': number;
        'Window Width': number;
    };
    [MixpanelEvent.PageView]: {
        page: string;
        path: string;
        search: string;
    };
    [MixpanelEvent.ApproveCandidate]: CandidateMixpanelData;
    [MixpanelEvent.NewRequestTerminateCandidate]: CandidateMixpanelData;
    [MixpanelEvent.NewRequestConfirmTerminateCandidate]: CandidateMixpanelData;
    [MixpanelEvent.ExpandNewCandidate]: CandidateMixpanelData & {
        via: string;
    };
    [MixpanelEvent.ExpandCandidateInProcess]: CandidateMixpanelData & {
        via: string;
    };
    [MixpanelEvent.SetAvailability]: {
        availability: AvailabilityFormSchema;
    };
    [MixpanelEvent.FilterCandidatesInProcess]: {
        filter_value: string;
    };
    [MixpanelEvent.FilterNewCandidates]: {
        filter_value: string;
    };
    [MixpanelEvent.HomepageClickCandidateName]: CandidateMixpanelData & {
        via: string;
    };
    [MixpanelEvent.ClickUpcomingEvent]: CandidateMixpanelData;
    [MixpanelEvent.FacilityPickerSubmit]: CandidateMixpanelData;
    [MixpanelEvent.ViewResume]: CandidateMixpanelData & {
        via: string;
    };
    [MixpanelEvent.DownloadResume]: CandidateMixpanelData & {
        via: string;
    };
    [MixpanelEvent.MoveForwardCandidate]: {
        next_step: string;
    };
    [MixpanelEvent.ProcessActionsMoveForward]: {
        next_step: string;
    };
    [MixpanelEvent.PendingTaskOpenActionsMenu]: CandidateMixpanelData & {
        task_type: string;
    };
    [MixpanelEvent.ProcessCardOpenActionsMenu]: CandidateMixpanelData;
    [MixpanelEvent.CandidateDialogOpenActionsMenu]: CandidateMixpanelData;
}

type MixpanelEventWithData = {
    [T in MixpanelEvent]: T extends keyof AllMixpanelEventsData
        ? [T, AllMixpanelEventsData[T]]
        : [T];
}[MixpanelEvent];

const TECHNICAL_EVENTS = new Set([MixpanelEvent.WindowResize]);

interface DefaultProperties {
    is_technical_event?: true;
}

function getDefulatProperties(event: MixpanelEvent) {
    const properties: DefaultProperties = {};
    if (TECHNICAL_EVENTS.has(event)) {
        properties.is_technical_event = true;
    }
    return properties;
}

function parseEventName([event, data]: MixpanelEventWithData): string {
    if (event === MixpanelEvent.PageView) {
        return `${event} ${data.page}`;
    }
    return event;
}

export const trackEvent = (...args: MixpanelEventWithData) => {
    if (!isMixpanelOn() && !sendEventToLog()) {
        return;
    }
    const [event, data] = args;
    const properties = { ...getDefulatProperties(event), ...data };
    const parsedProperties = mapValues(properties, (prop) =>
        typeof prop === 'object' ? JSON.stringify(prop) : prop,
    );
    const parsedName = parseEventName(args);
    if (sendEventToLog()) {
        console.log('[track_mixpanel_event]', parsedName, parsedProperties);
    }
    if (isMixpanelOn()) {
        mixpanel.track(parsedName, parsedProperties);
    }
};
