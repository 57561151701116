import { EventWithRelations } from '@/lib/types';
import { isUpcomingEvent } from '@/lib/utils';
import { mapValues, pickBy } from 'lodash';
import { createSelector } from 'reselect';

import { ProcessEvents, Processes } from '../generation_1';

export const EventsWithProcesses = createSelector(
    ProcessEvents,
    Processes,
    (processEventsById, processesById) => {
        return pickBy(
            mapValues(
                processEventsById,
                (event): EventWithRelations => ({
                    ...event,
                    process: processesById[event.processId],
                }),
            ),
            (e) => !!e.process,
        );
    },
);

export const UpcomingEventsSelector = createSelector(EventsWithProcesses, (events) =>
    pickBy(events, (e) => isUpcomingEvent(e)),
);
