import { cn, isFirefox, isSafari } from '@/lib/utils';
import { HTMLAttributes } from 'react';

import ScrollOnHoverVerticalFirefox from './ScrollOnHoverVerticalFirefox';
import ScrollOnHoverVerticalSafari from './ScrollOnHoverVerticalSafari';

interface Props extends HTMLAttributes<HTMLDivElement> {
    childContainerProps?: HTMLAttributes<HTMLDivElement>;
}

function ScrollOnHoverVertical(props: Props) {
    if (isFirefox()) {
        return <ScrollOnHoverVerticalFirefox {...props} />;
    }
    if (isSafari()) {
        return <ScrollOnHoverVerticalSafari {...props} />;
    }
    const { children, className, ...restProps } = props;
    return (
        <div
            className={cn(
                'relative h-full overflow-y-auto stable-gutter',
                '[&:not(:hover)]:hidden-scrollbar [&:not(:hover)]:pr-[8px]',
                className,
            )}
            {...restProps}
        >
            {children}
        </div>
    );
}

export default ScrollOnHoverVertical;
