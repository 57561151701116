import { useAppDispatch } from '@/_helpers/store';
import sessionSettingsSlice, { SessionSettings } from '@/_reducers/sessionSettings';
import { SessionSettingsState } from '@/_selectors';
import { useSelector } from 'react-redux';

function useSessionSetting<T extends keyof SessionSettings>(setting: T) {
    const dispatch = useAppDispatch();
    const setVal = (value: SessionSettings[T]) => {
        dispatch(sessionSettingsSlice.actions.updateSettings({ [setting]: value }));
    };
    const val = useSelector(SessionSettingsState)[setting];

    return {
        val,
        setVal,
    };
}

export default useSessionSetting;
