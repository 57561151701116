import { useAppDispatch } from '@/_helpers/store';
import { fetchProcessHistory } from '@/_reducers/history';
import { Processes } from '@/_selectors';
import { ProcessesHistoryByProcessId } from '@/_selectors/generation_1/processHistory';
import { generateEmptyFetcherState } from '@/lib/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useProcessHistory(processId: string, withFetch = true) {
    const dispatch = useAppDispatch();
    const historyDataByProcessId = useSelector(ProcessesHistoryByProcessId);
    const processesById = useSelector(Processes);
    const process = processesById[processId];
    const lastUpdated = process?.updatedAt.toISOString();

    useEffect(() => {
        if (!withFetch) return;
        dispatch(fetchProcessHistory({ processId }));
    }, [processId, lastUpdated, withFetch, dispatch]);

    return (
        historyDataByProcessId[processId] ?? {
            history: [],
            fetcher: generateEmptyFetcherState(),
        }
    );
}

export default useProcessHistory;
