import { cn } from '@/lib/utils';

import NewRequests from './NewRequests';
import UpcomingEvents from './UpcomingEvents';

function PanelRight() {
    return (
        <div className={cn('ml-12 relative w-1/3 min-w-[470px] max-w-[700px]')}>
            <div className={cn('relative h-1/2 w-full')}>
                <NewRequests />
            </div>
            <div className={cn('relative h-1/2 pt-12 w-full')}>
                <UpcomingEvents />
            </div>
        </div>
    );
}

export default PanelRight;
