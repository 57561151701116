import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { MixpanelEvent, trackEvent } from './events';

export enum MixpanelMetadata {
    WindowWidth = 'Window Width',
    WindowHeight = 'Window Height',
}

export function registerScreenSizeInMixpanel(sendEvent = true) {
    const data = {
        [MixpanelMetadata.WindowHeight]: window.innerHeight,
        [MixpanelMetadata.WindowWidth]: window.innerWidth,
    };
    mixpanel.register(data);
    if (sendEvent) {
        trackEvent(MixpanelEvent.WindowResize, data);
    }
}

export const updateScereenSizeInMixpanel = debounce(registerScreenSizeInMixpanel, 1000);
