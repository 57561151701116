import { PopoverMenuDelimiter, PopoverMenuProps } from '@/components/ui';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName, Process, ProcessNextStep } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';

import { InProcessActionsMenu } from './InProcessActionsMenu';
import { InProcessActionsViewProps } from './types';
import { getAlloweNegativeActions, getAllowedMoveForwardActions } from './utils';

function getProcessActionItems(
    process: Process,
    {
        moveForward,
        terminate,
        reschedule,
        didntHappen,
    }: {
        moveForward: (nextStep: ProcessNextStep) => void;
        terminate: () => void;
        reschedule: () => void;
        didntHappen: () => void;
    },
): PopoverMenuProps['items'] {
    const moveForwardActions = getAllowedMoveForwardActions(process, moveForward);
    const negativeActions = getAlloweNegativeActions(process, {
        terminate,
        reschedule,
        didntHappen,
    });
    const hasDelimiter = negativeActions.length > 0 && moveForwardActions.length > 0;
    const delimiterItems: PopoverMenuDelimiter[] = hasDelimiter
        ? [{ isDelimiter: true }]
        : [];
    return [...moveForwardActions, ...delimiterItems, ...negativeActions];
}

export function InProcessActionsView({ process, ...props }: InProcessActionsViewProps) {
    const { openDialog: didntHappen } = useOpenDialog(DialogName.InterviewDidntHappen);
    const { openDialog: terminate } = useOpenDialog(DialogName.TerminateProcess);
    const { openDialog: moveForward } = useOpenDialog(DialogName.MoveProcessForward);
    const { openDialog: reschedule } = useOpenDialog(DialogName.RescheduleEventConfirm);

    const items = getProcessActionItems(process, {
        moveForward: (nextStep: ProcessNextStep) => {
            trackEvent(MixpanelEvent.ProcessActionsMoveForward, { next_step: nextStep });
            moveForward({ processId: process.id, nextStep });
        },
        terminate: () => {
            trackEvent(MixpanelEvent.ProcessActionsTerminateCandidate);
            terminate({ processId: process.id });
        },
        reschedule: () => {
            const nextEventId = process.relevantEvent?.id;
            trackEvent(MixpanelEvent.ProcessActionsRescheduleEvent);
            nextEventId && reschedule({ eventId: nextEventId });
        },
        didntHappen: () => {
            const doneEventId = process.relevantEvent?.id;
            trackEvent(MixpanelEvent.ProcessActionsUnfulfilledEvent);
            doneEventId && didntHappen({ eventId: doneEventId });
        },
    });
    if (items.length === 0) {
        return null;
    }
    return <InProcessActionsMenu {...props} items={items} />;
}

export default InProcessActionsView;
