import { Profession } from '@/lib/types';
import { professionToName } from '@/strings/common';
import { format } from 'date-fns';

export default {
    title: {
        func: (count: number) => `You have ${count} decisions to make`,
        example: [9],
    },
    noTasksTitle: 'No new tasks',
    noTasksMsg: "There's nothing to action right now.",
    candidateSubtitle: {
        func: (profession: Profession, firstFacility: string, jobMatchesCount: number) =>
            `${professionToName[profession]} • ${firstFacility}${jobMatchesCount > 1 ? ` +${jobMatchesCount - 1}` : ''}`,
        example: [Profession.CNA, 'Golden Care', 2],
    },
    mobile: {
        header: {
            func: (count: number | null) =>
                `${count !== null ? count + ' ' : ''}Decisions to make`,
            example: [3],
        },
        headerNoTasks: 'No decisions to make',
        noTasksLabel: 'All clear! No new tasks',
        showMoreTasks: {
            func: (additionalCount: number) => `Show ${additionalCount} more`,
            example: [3],
        },
        showLessTasks: 'View less',
    },
    moveForwardButton: 'Next step',
    phone_interview_feedback: {
        questionTitle: {
            func: (firstName: string) => `How was ${firstName}'s phone interview?`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
        questionInfo: {
            func: (firstName: string, interviewTime: Date) =>
                `${firstName} had a phone interview on ${format(interviewTime, 'MMM dd')}. Choose the next step.`,
            example: ['[CANDIDATE_FIRST_NAME]', new Date('2024-02-12T00:00:00Z')],
        },
    },
    in_person_interview_feedback: {
        questionTitle: {
            func: (firstName: string) => `How was ${firstName}'s in-person interview?`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
        questionInfo: {
            func: (firstName: string, interviewTime: Date) =>
                `${firstName} had an in-person interview on ${format(interviewTime, 'MMM dd')}. Choose the next step.`,
            example: ['[CANDIDATE_FIRST_NAME]', new Date('2024-02-12T00:00:00Z')],
        },
    },
    orientation_feedback: {
        questionTitle: {
            func: (firstName: string) => `How was ${firstName}'s orientation?`,
            example: ['[Candy]'],
        },
        questionInfo: {
            func: (firstName: string, interviewTime: Date, facilityName: string) =>
                `${firstName} had orientation on ${format(interviewTime, 'MMM dd')} at ${facilityName}. Choose the next step.`,
            example: [
                '[Candy]',
                new Date('2024-02-12T00:00:00Z'),
                'RegalCare At Sharon',
            ],
        },
    },
};
