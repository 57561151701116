import { getAllEnumValues } from '@/lib/base';

export enum Profession {
    RN = 'RN',
    LPN = 'LPN',
    CNA = 'CNA',
    HHA = 'HHA',
    NP = 'NP',
}

export const professionTypes = getAllEnumValues(Profession);
