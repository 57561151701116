import { EventType } from '@/lib/api/v1';

import { genericToasts, professionToName } from '../common';

const strings = {
    title: "Choose what you'd like to happen next",
    description:
        'We do our best to ensure candidates show up to interviews on time. \nWhat would you like to do next?',
    oreintationDescription:
        'We do our best to ensure candidates show up to orientations on time. \nWhat would you like to do next?',
    terminateButton: 'Reject candidate',
    rescheduleButton: {
        [EventType.PhoneInterview]: 'Reschedule phone interview',
        [EventType.InPersonInterview]: 'Reschedule in-person interview',
        [EventType.Orientation]: 'Reschedule orientation',
    },
    professionToName,
    successStep: {
        title: "Great, we're on it!",
        description: {
            func: (firstName: string) =>
                `We'll reach out to ${firstName} to reschedule this event.`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
    },
    reschedule: {
        ...genericToasts.failure,
    },
};

export default strings;
