import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import NewRequestActionsViewProvider from '@/newRequests/NewRequestActionsViewProvider';

export function ProcessActionsView({
    process,
    rowExpanded,
    rowOnHover,
}: {
    process: Process;
    rowExpanded: boolean;
    rowOnHover: boolean;
}) {
    return (
        <div
            className={cn('opacity-0', {
                'opacity-100': rowExpanded || rowOnHover,
            })}
        >
            <NewRequestActionsViewProvider
                process={process}
                rejectButtonProps={{ className: cn('w-[98px]') }}
            />
        </div>
    );
}

export default ProcessActionsView;
