import { openCandidateDetailsDialog, setDialogOpen } from '@/_reducers/dialogs';
import { fetchProcess } from '@/_reducers/processes';
import {
    CandidateDetailsDialogBaseData,
    CandidateDetailsDialogData,
    IsCandidateDetailsDialogOpen,
} from '@/_selectors';
import { DialogName } from '@/lib/types';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import CandidateDetailsDialog from './CandidateDetailsDialog';
import useProcessIdFromUrl from './useCandidateFromUrl';

function CandidateDetailsDialogProvider({
    setDialogOpenAction,
    openCandidateDetailsDialog,
    fetchProcess,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    openCandidateDetailsDialog: (processId: string) => void;
    fetchProcess: (processId: string, retry?: boolean) => void;
}) {
    useProcessIdFromUrl({ callback: openCandidateDetailsDialog });
    const shouldBeOpen = useSelector(IsCandidateDetailsDialogOpen);
    const { processId } = useSelector(CandidateDetailsDialogBaseData);
    const { process } = useSelector(CandidateDetailsDialogData);
    const hasData = !!process;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.CandidateDetails, open);
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (processId) {
            fetchProcess(processId, true);
        }
    }, [processId, fetchProcess]);

    return (
        <>
            {hasData && (
                <CandidateDetailsDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    openCandidateDetailsDialog,
    fetchProcess,
};

export default connect(null, mapDispathToProps)(CandidateDetailsDialogProvider);
