import { Button, LoadingButton, useToast } from '@/components/ui';
import { cn, getFirstName } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import { CancelEventStepProps, ImplementedEventType } from './types';

const strings = dialogsStrings.eventDetails.cancelEvent;
const rescheduleStrings = dialogsStrings.eventDetails.reschedule;

export function CancelEventStep({
    goToTerminate,
    reschedule,
    event,
}: CancelEventStepProps) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const eventType = event.eventType as ImplementedEventType;

    const onTerminateClick = () => {
        trackEvent(MixpanelEvent.CancelEventTerminateCandidate);
        goToTerminate();
    };

    const onSubmit = async () => {
        trackEvent(MixpanelEvent.CancelEventRescheduleEvent);
        setIsLoading(true);
        await reschedule()
            .catch((error) => {
                toast({
                    title: rescheduleStrings.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={cn('relative w-[650px] max-w-full')}>
            <div className={cn('p-12 xs:p-8 xs:pb-12 text-center xs:text-left')}>
                <p className={cn('text-18 leading-10 font-semibold')}>
                    {strings[eventType].title.func(
                        getFirstName(event.process.candidate),
                    )}
                </p>
                <p className={cn('text-14 whitespace-pre-line')}>
                    {strings[eventType].description}
                </p>
            </div>
            <div className={cn('flex py-8 px-10 border-t border-neutral100')}>
                <div className={cn('w-full flex xs:flex-wrap-reverse  gap-6')}>
                    <div className={cn('relative basis-1/2 flex-grow')}>
                        <LoadingButton
                            className={cn('w-full')}
                            variant={'secondary'}
                            size={'lg'}
                            onClick={onSubmit}
                        >
                            {strings[eventType].rescheduleButton}
                        </LoadingButton>
                    </div>
                    <div className={cn('relative basis-1/2 flex-grow')}>
                        <Button
                            className={cn('w-full')}
                            size={'lg'}
                            variant={'danger'}
                            onClick={onTerminateClick}
                            disabled={isLoading}
                        >
                            {strings[eventType].terminateButton}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CancelEventStep;
