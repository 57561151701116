import { ProfessionAvatar } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn, handlerNoPropagation } from '@/lib/utils';
import ProcessStatusBadge from '@/pages/candidates/inProcess/ProcessStatusBadge';
import { homepage as homepageStrings } from '@/strings';
import {
    dateToDayInMonthShort,
    dateToLongMonthName,
    dateToTimeStringShort12,
} from '@/strings/common';

import { UpcomingEventBlockProps } from './types';

const strings = homepageStrings.upcomingEvents;

function UpcomingEventBlock({
    event,
    openCandidateDetails,
    onClick,
}: UpcomingEventBlockProps) {
    const { startAt, endAt, process } = event;
    const { candidate } = process;
    const { name, profession } = candidate;
    const transitionCls = cn('transition-all ease-out duration-150');

    return (
        <div
            className={cn(
                'flex items-center',
                'bg-white rounded-2xl',
                'w-full h-[110px] px-10 mt-4 first-of-type:mt-0',
                'border border-white hover:border-primary/50 cursor-pointer',
                'transition-colors duration-200 ease-in-out',
            )}
            onClick={() => onClick(event.id)}
        >
            <div
                className={cn('relative flex items-center justify-between gap-3 w-full')}
            >
                <div className={cn('flex items-center')}>
                    <ProfessionAvatar
                        className={cn('h-12 w-12')}
                        profession={profession as Profession}
                    />
                    <div className={cn('ml-4')}>
                        <p
                            className={cn(
                                'text-13-23 font-bold cursor-pointer hover:text-primary inline',
                                transitionCls,
                            )}
                            onClick={handlerNoPropagation(() =>
                                openCandidateDetails(process),
                            )}
                        >
                            {name}
                        </p>
                        <div className={cn('text-11 leading-[18px] font-medium')}>
                            {
                                strings.eventBlock.professionToName[
                                    profession as Profession
                                ]
                            }
                        </div>
                    </div>
                </div>
                <div className={cn('flex items-center gap-8')}>
                    <div className={cn('text-right')}>
                        <div className={cn('text-13 font-bold text-nowrap')}>
                            {`${dateToTimeStringShort12(startAt)} - ${dateToTimeStringShort12(endAt)}`}
                        </div>
                        <div className={cn('mt-2 ml-auto w-fit')}>
                            <ProcessStatusBadge process={process} />
                        </div>
                    </div>
                    <div className={cn('flex flex-col items-center')}>
                        <div className={cn('text-[24px] leading-[1] font-medium')}>
                            {dateToDayInMonthShort(startAt)}
                        </div>
                        <div
                            className={cn('text-[10px] leading-[1] font-semibold mt-2')}
                        >
                            {dateToLongMonthName(startAt)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpcomingEventBlock;
