import { EventStatus } from '@/lib/api/v1';
import {
    CandidateEvent,
    CandidateEventApiModel,
    FlatCandidateEvent,
    FlatCandidateEventApiModel,
} from '@/lib/types';

export const parseEventApiModel = (event: CandidateEventApiModel): CandidateEvent => {
    return {
        ...event,
        startAt: new Date(event.startAt),
        endAt: new Date(event.endAt),
    };
};

export const parseFlatEventApiModel = (
    event: FlatCandidateEventApiModel,
): FlatCandidateEvent => {
    return {
        ...event,
        startAt: new Date(event.startAt),
        endAt: new Date(event.endAt),
    };
};

export const isUpcomingEvent = (event: CandidateEvent) => {
    return event.status === EventStatus.Scheduled;
};
