import { DialogName } from '@/lib/types/dialogs';
import { createSelector } from 'reselect';

import {
    AllProcesses,
    CandidateDetailsDialogBaseData,
    DialogsDataState,
    Events,
    Processes,
} from '../generation_1';

export const RejectCandidateConfirmDialogData = createSelector(
    DialogsDataState,
    AllProcesses,
    (dataState, processesById) => {
        const { processId } = dataState[DialogName.RejectCandidateConfirm] ?? {};
        if (!processId) {
            return {};
        }
        return { process: processesById[processId] };
    },
);

export const TerminateProcessDialogData = createSelector(
    DialogsDataState,
    AllProcesses,
    (dataState, processesById) => {
        const { processId, defaultSelection } =
            dataState[DialogName.TerminateProcess] ?? {};
        if (!processId) {
            return {};
        }
        return { process: processesById[processId], defaultSelection };
    },
);

export const MoveProcessForwardDialogData = createSelector(
    DialogsDataState,
    Processes,
    (dataState, processesById) => {
        const { processId, nextStep } = dataState[DialogName.MoveProcessForward] ?? {};
        if (!processId) {
            return {};
        }
        return { process: processesById[processId], nextStep };
    },
);

export const InterviewDidntHappenDialogData = createSelector(
    DialogsDataState,
    Events,
    Processes,
    (dataState, eventsById, processesById) => {
        const { eventId } = dataState[DialogName.InterviewDidntHappen] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        const process = processesById[event.processId];
        if (!process) {
            return {};
        }
        return { process, event };
    },
);

export const RescheduleEventConfirmDialogData = createSelector(
    DialogsDataState,
    Events,
    Processes,
    (dataState, eventsById, processesById) => {
        const { eventId } = dataState[DialogName.RescheduleEventConfirm] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        const process = processesById[event.processId];
        if (!process) {
            return {};
        }
        return { process, event };
    },
);

export const CandidateDetailsDialogData = createSelector(
    CandidateDetailsDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId } = baseData;
        if (!processId) {
            return {};
        }
        return { process: processesById[processId] };
    },
);
