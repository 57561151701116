import ProfessionFilter from './ProfessionFilter';
import CandidatesTopRow from './TopRow';
import { NewCandidatesTopRowProps } from './types';

function NewCandidatesTopRow({
    profFilter,
    setProfFilter,
    models,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
}: NewCandidatesTopRowProps) {
    return (
        <CandidatesTopRow
            models={models}
            filterView={
                <ProfessionFilter
                    profFilter={profFilter}
                    setProfFilter={setProfFilter}
                    models={models}
                />
            }
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
        />
    );
}

export default NewCandidatesTopRow;
