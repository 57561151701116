import { LoadingButton, useToast } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn, getSelectedJobMatch } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candStrings } from '@/strings';
import { useState } from 'react';

import DetailsCard from './DetailsCard';
import JobMatchRow from './JobMatchRow';
import { JobMatchesCardTitleProps, JobMatchesTileProps } from './types';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function CandidatesDetailsCardTitle({ onSubmit }: JobMatchesCardTitleProps) {
    return (
        <div className={cn('mx-8 flex items-center justify-between w-full')}>
            <p className={cn('font-bold text-14 text-black')}>
                {strings.jobMatchesTitle}
            </p>
            <div>
                {!!onSubmit && (
                    <LoadingButton size={'sm'} onClick={onSubmit}>
                        {strings.updateFacility.button}
                    </LoadingButton>
                )}
            </div>
        </div>
    );
}

function CandidateJobMatches({
    process: p,
    cardProps,
    updateSelectedJobMatches,
}: JobMatchesTileProps) {
    const isMobileScreen = useIsMobileScreen();
    const { toast } = useToast();
    const jobMatches = p.jobMatches;
    const currentSelectedMatchId = getSelectedJobMatch(jobMatches)?.id;
    const [selectedMatchId, setSelectedMatchId] = useState<string>(
        currentSelectedMatchId ?? '',
    );
    const hasChange = !!selectedMatchId && selectedMatchId !== currentSelectedMatchId;

    const onJobMatchClick = (id: string) => {
        setSelectedMatchId(id);
    };

    const onSubmit = async () => {
        trackEvent(MixpanelEvent.FacilityPickerSubmit, {
            candidate_id: p.candidate.id,
            candidate_name: p.candidate.name,
        });
        await updateSelectedJobMatches({
            selectedMatchId,
            processId: p.id,
        }).catch((error) => {
            toast({
                title: strings.updateFacility.failureTitle,
                description: error.message || error,
                variant: 'destructive',
            });
        });
    };

    const showSubmitButtonInTitle = hasChange && !isMobileScreen;
    const showSubmitButtonInBody = hasChange && isMobileScreen;

    return (
        <DetailsCard
            title={
                <CandidatesDetailsCardTitle
                    onSubmit={showSubmitButtonInTitle ? onSubmit : undefined}
                />
            }
            {...cardProps}
        >
            <div className={cn('relative p-6 flex flex-col gap-6 lg:p-4 lg:gap-3')}>
                {jobMatches.map((jm) => (
                    <JobMatchRow
                        key={jm.id}
                        jobMatch={jm}
                        selected={selectedMatchId === jm.id}
                        onClick={onJobMatchClick}
                    />
                ))}
                {showSubmitButtonInBody && (
                    <LoadingButton className={cn('w-full text-13')} onClick={onSubmit}>
                        {strings.updateFacility.button}
                    </LoadingButton>
                )}
            </div>
        </DetailsCard>
    );
}

export default CandidateJobMatches;
