import { Select } from '@/components/ui';
import DialogContentWithFooter from '@/components/ui/dialog/contentWithFooter';
import { Textarea } from '@/components/ui/textarea';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { RecruiterTerminationReason } from '@/lib/api/v1';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import RejectCandidateDialogFooter from './dialogFooter';
import { RejectCandidateFunc, SelectedRejectReason } from './types';

const strings = dialogsStrings.rejectCandidate;

const terminationReasonsByOrder = [
    RecruiterTerminationReason.Commute,
    RecruiterTerminationReason.PayRate,
    RecruiterTerminationReason.LackOfQualifications,
    RecruiterTerminationReason.NoOpenRoles,
    RecruiterTerminationReason.Other,
];

export function RejectCandidateDialogContent({
    process,
    handleClose,
    terminate,
    defaultReason,
}: {
    process: Process;
    handleClose: () => void;
    terminate: RejectCandidateFunc;
    defaultReason?: RecruiterTerminationReason;
}) {
    const [reason, setReason] = useState<SelectedRejectReason>(defaultReason ?? '');
    const [freeText, setFreeText] = useState('');
    const isMobileScreen = useIsMobileScreen();

    return (
        <DialogContentWithFooter
            footer={
                <RejectCandidateDialogFooter
                    process={process}
                    handleClose={handleClose}
                    terminate={terminate}
                    reason={reason}
                    freeText={freeText}
                />
            }
        >
            <div className={cn('relative')}>
                <div
                    className={cn('text-center p-12', {
                        'p-0 text-left pb-2': isMobileScreen,
                    })}
                >
                    <p className={cn('text-18 leading-10 font-semibold')}>
                        {strings.title}
                    </p>
                    <p className={cn('text-14 whitespace-pre-line')}>
                        {strings.subtitle.func(process.candidate.name)}
                    </p>
                    <Select
                        triggerProps={{
                            className: cn('w-full mt-12 xs:mt-8'),
                        }}
                        value={reason}
                        onChange={(v) => {
                            setReason(v);
                        }}
                        placeholder={strings.selectReasonPlaceholder}
                        options={terminationReasonsByOrder.map((r) => ({
                            value: r,
                            label: strings.terminationReasons[r],
                            id: r,
                        }))}
                    />
                    <Textarea
                        className={cn('mt-6 h-[250px] xs:h-[150px] resize-none')}
                        value={freeText}
                        onChange={(e) => setFreeText(e.target.value)}
                        placeholder={strings.freeTextPlaceholder}
                        tabIndex={-1}
                    />
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default RejectCandidateDialogContent;
