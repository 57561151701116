import Logger from '@/_helpers/logger';

export const getLocalStorageGracefully = () => {
    const TEST_LOCAL_STORAGE_ITEM = 'carefam_local_storage_test';
    try {
        localStorage.setItem(TEST_LOCAL_STORAGE_ITEM, '1');
        localStorage.removeItem(TEST_LOCAL_STORAGE_ITEM);
        return localStorage;
    } catch (e) {
        Logger.warn(`Can't access localStorage: ${e}`);
        return null;
    }
};
