import Loader from '@/components/lotties/Loader';
import { ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

import NoCandidatesView from '../NoCandidatesView';
import { InProcessCandidatesViewProps } from '../types';
import InProcessCollection from './InProcessCollection';
import InProcessTopRow from './InProcessTopRow';
import { getSortedProcesses } from './utils';

const strings = candidatesStrings.inProcess;

function InProcessLayout({
    processes,
    filteredProcesses,
    filter,
    onFilterChange,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
    onProcessClick,
    isEmpty,
    isLoading,
    initiated,
}: InProcessCandidatesViewProps) {
    return (
        <ScrollableFillerDiv
            growDivProps={{ className: cn('relative py-4 lg:py-2 bg-accent200') }}
            relativeDivProps={{ className: cn('overflow-auto') }}
            absoluteDivProps={{ className: cn('min-w-fit') }}
            fixedDiv={
                <InProcessTopRow
                    filter={filter}
                    setFilter={onFilterChange}
                    models={processes}
                    initiatedRecruiterAv={initiatedRecruiterAv}
                    openSetAvailabilityDialog={openSetAvailabilityDialog}
                    openSetAvailabilityDialogDismissOthers={
                        openSetAvailabilityDialogDismissOthers
                    }
                />
            }
            className={cn('hidden-scrollbar')}
        >
            <div className={cn('px-10 lg:px-8')}>
                <InProcessCollection
                    processes={getSortedProcesses(filteredProcesses, filter)}
                    onProcessClick={onProcessClick}
                />
                {isLoading && !initiated && (
                    <div
                        className={cn(
                            'absolute flex items-center justify-center w-full h-full top-0 left-0',
                        )}
                    >
                        <Loader variant='primary' sizing='lg' />
                    </div>
                )}
                {initiated && isEmpty && <NoCandidatesView text={strings.noCandidtes} />}
            </div>
        </ScrollableFillerDiv>
    );
}

export default InProcessLayout;
