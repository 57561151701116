import { EventType, ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import {
    JobMatch,
    Process,
    ProcessApiModel,
    eventableProcessStatuses,
    processStatusToEventTypeMap,
} from '@/lib/types';

import { parseFlatEventApiModel } from './events';

const getRelevantJobMatches = (matches: JobMatch[]) => {
    return matches.filter((jm) => jm.candidateInterested);
};

export const parseProcessApiModel = (p: ProcessApiModel): Process => {
    return {
        ...p,
        createdAt: new Date(p.createdAt),
        updatedAt: new Date(p.updatedAt),
        statusUpdatedAt: new Date(p.statusUpdatedAt),
        relevantEvent: p.relevantEvent
            ? parseFlatEventApiModel(p.relevantEvent)
            : undefined,
        jobMatches: getRelevantJobMatches(p.jobMatches),
    };
};

export const isTerminated = (p: Process) => p.status === ProcessStatus.Terminated;

export const isNewProcess = (p: Process) => {
    if (p.status === ProcessStatus.Matchmaking && !p.recruiterApproved) {
        return true;
    }
    return false;
};

export const isInProcess = (p: Process) => {
    if (
        [
            ProcessStatus.PhoneInterview,
            ProcessStatus.InPersonInterview,
            ProcessStatus.Paperwork,
            ProcessStatus.Orientation,
        ].includes(p.status)
    ) {
        return true;
    }
    return false;
};

export const isPendingFeedback = (p: Process) => {
    if (p.substatus === ProcessSubstatus.Done && !!p.relevantEvent) {
        return eventableProcessStatuses.includes(p.status);
    }
    return false;
};

export const getCurrentFacilityName = (jobMatches: JobMatch[]): string => {
    const currentRelevantJobMatch = getSelectedJobMatch(jobMatches);
    if (currentRelevantJobMatch) {
        return currentRelevantJobMatch.facility.name;
    }
    return jobMatches[0]?.facility.name;
};

export const getFacilityNameAndCount = (p: Process) => {
    const jobMatches = p.jobMatches;
    const facilityName = getCurrentFacilityName(jobMatches);
    return {
        facilityName,
        facilitiesCount: jobMatches.length,
    };
};

export const getSelectedJobMatch = (matches: JobMatch[]) => {
    return matches.find((jm) => jm.selected);
};

export const isPhoneInterviewDoneProc = (p: Process) => {
    return (
        p.status === ProcessStatus.PhoneInterview &&
        p.substatus === ProcessSubstatus.Done
    );
};

export function getProcessWithParsedStatus(process: Process): Process {
    if (process.status === ProcessStatus.Matchmaking && process.recruiterApproved) {
        return {
            ...process,
            status:
                process.recruiter.processFlow?.first_step ??
                ProcessStatus.PhoneInterview,
            substatus: ProcessSubstatus.Pending,
        };
    }
    return process;
}

export function getParsedProcess(processApiModel: ProcessApiModel): Process {
    const process = parseProcessApiModel(processApiModel);
    const parsedProcess = getProcessWithParsedStatus(process);
    return parsedProcess;
}
export const parseProcessStatusToEventType = (
    processStatus: ProcessStatus,
): EventType | undefined => {
    return processStatusToEventTypeMap[processStatus];
};
