import Loader from '@/components/lotties/Loader';
import { CalendarIcon } from '@/components/svgs';
import { ScrollOnHoverVertical, ScrollableFillerDiv } from '@/components/ui';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName, Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as homepageStrings } from '@/strings';

import UpcomingEventBlock from './UpcomingEventBlock';
import useUpcomingEvents from './useUpcomingEvents';

const strings = homepageStrings.upcomingEvents;

function UpcomingEvents() {
    const { totalCount, isEmpty, initiated, events, showLoader, onEventBlockClick } =
        useUpcomingEvents();
    const { openDialog: openCandidateDetailsDialog } = useOpenDialog(
        DialogName.CandidateDetails,
    );

    const openCandidateDetails = (process: Process) => {
        trackEvent(MixpanelEvent.HomepageClickCandidateName, {
            via: 'upcoming_event',
            candidate_id: process.candidate.id,
            candidate_name: process.candidate.name,
        });
        openCandidateDetailsDialog({ processId: process.id });
    };

    return (
        <ScrollableFillerDiv
            className={cn('overflow-hidden')}
            rootDivProps={{ className: cn('relative h-full') }}
            growDivProps={{ className: cn('mt-5 w-full') }}
            contentWrapperProps={{
                className: cn('relative rounded-2xl py-4 pl-2 pr-[2px] bg-accent300'),
            }}
            fixedDiv={
                <p className={cn('text-15 font-bold')}>
                    {initiated && !isEmpty && strings.title.func(totalCount)}
                    {initiated && isEmpty && strings.noEventsTitle}
                </p>
            }
        >
            <ScrollOnHoverVertical>
                <div className={cn('h-full pl-2 pr-[2px]')}>
                    {initiated &&
                        !isEmpty &&
                        events.map((e) => (
                            <UpcomingEventBlock
                                event={e}
                                key={e.id}
                                openCandidateDetails={openCandidateDetails}
                                onClick={onEventBlockClick}
                            />
                        ))}
                    {initiated && isEmpty && (
                        <div
                            className={cn(
                                'relative flex flex-col items-center justify-center w-full h-full',
                            )}
                        >
                            <CalendarIcon className={cn('opacity-10 h-14 w-14')} />
                            <p className={cn('text-14 font-medium mt-5 text-black/20')}>
                                {strings.noEventsMsg}
                            </p>
                        </div>
                    )}
                    <div
                        className={cn(
                            'relative flex items-center justify-center w-full h-full',
                            { 'h-0 overflow-hidden': !showLoader },
                        )}
                    >
                        <Loader variant='primary' sizing='lg' />
                    </div>
                </div>
            </ScrollOnHoverVertical>
        </ScrollableFillerDiv>
    );
}

export default UpcomingEvents;
