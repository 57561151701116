import Logger from '@/_helpers/logger';
import { DownloadIcon, EyeIcon, FalafelIcon } from '@/components/svgs';
import { CustomPopover, ScrollableFillerDiv } from '@/components/ui';
import { Dialog } from '@/components/ui/dialog';
import useHover from '@/hooks/useHover';
import { cn, handlerNoPropagation } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candStrings } from '@/strings';
import { useRef, useState } from 'react';
import { Document, type DocumentProps, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import PDFDialogContent from './PdfDialogContent';
import { PDF_PAGE_FIT_TO_CONTAINER_CLS } from './consts';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

// Set workerSrc
pdfjs.GlobalWorkerOptions.workerSrc = '/scripts/pdf.worker.js';

export default function PDFViewer({
    filePath,
    candidateName,
    candidateId,
    onRetry,
    thumbnailWidth,
    noBottomRow = false,
}: {
    filePath?: string | null;
    candidateName: string;
    candidateId: string;
    onRetry?: () => void;
    thumbnailWidth?: number;
    noBottomRow?: boolean;
}) {
    const hoverRef = useRef<HTMLDivElement>(null);
    const hovering = useHover(hoverRef);
    const [open, setOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState<number>(0);
    const [downloadLink, setDownloadLink] = useState<string>('');
    const failuresRef = useRef(0);

    const filename = strings.resumeFilename.func(candidateName);
    const onDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = async (pdf) => {
        const data = await pdf.getData();
        const blob = new Blob([data], { type: 'application/pdf' });
        setDownloadLink(URL.createObjectURL(blob));
        setNumPages(pdf.numPages);
        setLoading(false);
    };
    const onDocumentLoadError = (error: Error) => {
        if (failuresRef.current >= 1) {
            setLoading(false);
            Logger.warn(`error open document: ${error}`);
            return;
        }
        failuresRef.current += 1;
        onRetry && onRetry();
    };

    const onOpen = (via: string) => {
        if (disabled) {
            return;
        }
        trackEvent(MixpanelEvent.ViewResume, {
            via,
            candidate_id: candidateId,
            candidate_name: candidateName,
        });
        setOpen(true);
    };

    const hasData = numPages > 0 && !loading;
    const disabled = !hasData;

    return (
        <div
            className={cn(
                'rounded-2xl overflow-hidden cursor-default w-full h-full transition-colors duration-200 ease-out',
                { 'cursor-pointer hover:bg-accent300': !disabled },
            )}
            ref={hoverRef}
            onClick={() => onOpen('thumbnail')}
        >
            <ScrollableFillerDiv
                fixedDiv={
                    <>
                        {noBottomRow && <div />}
                        {!noBottomRow && (
                            <div className={cn('p-5 lg:p-3 lg:px-4 bg-accent500')}>
                                <p className={cn('text-13-23 font-semibold')}>
                                    {filename}
                                </p>
                            </div>
                        )}
                    </>
                }
                rootDivProps={{ className: cn('flex-col-reverse') }}
                className={cn('overflow-hidden w-full relative')}
            >
                {!hasData && (
                    <div
                        className={cn(
                            'h-full w-full flex justify-center items-center [&_*]:cursor-default [&_*]:select-none',
                            { 'bg-neutral-500': loading },
                            { 'bg-neutral-100': !loading },
                        )}
                    >
                        <div>
                            {loading
                                ? strings.resumeLoading
                                : strings.resumeFailedToLoadd}
                        </div>
                    </div>
                )}
                <div
                    className={cn(
                        'absolute z-10 top-0 left-0 w-full h-full bg-black opacity-20 transition-opacity duration-200 ease-out',
                        { 'opacity-30': hovering && !disabled },
                    )}
                />
                <div className={cn('absolute z-20 h-full w-full')}>
                    <div className={cn('relative w-full h-full')}>
                        <div className={cn('absolute top-4 right-4')}>
                            <CustomPopover
                                trigger={
                                    <div
                                        className={cn(
                                            'relative w-10 h-8',
                                            'flex items-center justify-center',
                                            'bg-white rounded-md cursor-pointer',
                                            'transition-opacity duration-200 opacity-0',
                                            {
                                                'opacity-100':
                                                    popoverOpen ||
                                                    (hovering && !disabled),
                                            },
                                        )}
                                        onClick={handlerNoPropagation(() =>
                                            setPopoverOpen(!popoverOpen),
                                        )}
                                    >
                                        <div>
                                            <FalafelIcon />
                                        </div>
                                    </div>
                                }
                                triggerProps={{ asChild: true }}
                                open={popoverOpen}
                                setOpen={setPopoverOpen}
                                contentProps={{
                                    className: cn('rounded-2xl'),
                                    variant: 'noPointer',
                                    align: 'end',
                                    alignOffset: 0,
                                    sideOffset: 6,
                                }}
                            >
                                <div
                                    className={cn(
                                        'bg-white hover:bg-accent300 py-8 pl-6 pr-14 h-[64px] flex items-center cursor-pointer',
                                    )}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onOpen('popover_menu');
                                    }}
                                >
                                    <EyeIcon width={16} height={16} />
                                    <p
                                        className={cn(
                                            'ml-4 text-13 font-semibold text-black',
                                        )}
                                    >
                                        {strings.resumeMenu.view}
                                    </p>
                                </div>
                                {downloadLink && (
                                    <div>
                                        <a
                                            href={downloadLink}
                                            download={filename}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                trackEvent(
                                                    MixpanelEvent.DownloadResume,
                                                    {
                                                        via: 'popover_menu',
                                                        candidate_id: candidateId,
                                                        candidate_name: candidateName,
                                                    },
                                                );
                                            }}
                                            className={cn(
                                                'relative bg-white hover:bg-accent300 py-8 pl-6 pr-14 h-[64px] flex items-center cursor-pointer',
                                            )}
                                        >
                                            <DownloadIcon width={16} height={16} />
                                            <p
                                                className={cn(
                                                    'ml-4 text-13 font-semibold text-black',
                                                )}
                                            >
                                                {strings.resumeMenu.download}
                                            </p>
                                        </a>
                                    </div>
                                )}
                            </CustomPopover>
                        </div>
                    </div>
                </div>
                {filePath && (
                    <Document
                        file={filePath}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onDocumentLoadError}
                        className={cn({ hidden: !hasData })}
                        loading={''}
                    >
                        <Page
                            width={thumbnailWidth}
                            pageNumber={1}
                            className={cn(
                                PDF_PAGE_FIT_TO_CONTAINER_CLS,
                                'cursor-pointer [&_*]:!cursor-pointer [&_*]:select-none',
                            )}
                        />
                        <Dialog
                            open={open}
                            setOpen={setOpen}
                            contentProps={{
                                className: cn(
                                    'h-[calc(100%-36px)] w-[90%] xs:h-full xs:max-h-fit xs:w-full',
                                ),
                            }}
                            headerProps={{ className: cn('py-8 bg-white border-b-0') }}
                            headerAnchorProps={{ className: cn('bg-white') }}
                            contentWrapperProps={{
                                className: cn(
                                    'flex flex-col h-full w-full bg-accent200',
                                ),
                            }}
                            titleProps={{ className: cn('flex-grow') }}
                            title={
                                <div
                                    className={cn(
                                        'flex justify-between items-center w-full',
                                    )}
                                >
                                    <p className={cn('text-14 font-bold')}>{filename}</p>
                                    {downloadLink && (
                                        <a
                                            href={downloadLink}
                                            download={filename}
                                            className={cn('pr-2')}
                                            onClick={() => {
                                                trackEvent(
                                                    MixpanelEvent.DownloadResume,
                                                    {
                                                        via: 'resume_header_icon',
                                                        candidate_id: candidateId,
                                                        candidate_name: candidateName,
                                                    },
                                                );
                                            }}
                                        >
                                            <DownloadIcon width={20} height={20} />
                                        </a>
                                    )}
                                </div>
                            }
                        >
                            <PDFDialogContent numPages={numPages} />
                        </Dialog>
                    </Document>
                )}
            </ScrollableFillerDiv>
        </div>
    );
}
