import {
    DialogNameWithData,
    openDialogDismissOthers,
    setDialogOpen,
} from '@/_reducers/dialogs';
import { IsWeeklyAvMissingDialogOpen } from '@/_selectors';
import { DialogName } from '@/lib/types';
import { connect, useSelector } from 'react-redux';

import { useAvailabilityDialogData } from '../hooks';
import WeeklyAvMissingDialog from './WeeklyAvMissingDialog';

function WeeklyAvMissingDialogProvider({
    setDialogOpenAction,
    openDialogDismissOthers,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
}) {
    const isOpen = useSelector(IsWeeklyAvMissingDialogOpen);
    const { setData: setAvDialogData } = useAvailabilityDialogData();

    const setOpen = (open: boolean) => {
        if (!open) {
            setAvDialogData({});
        }
        setDialogOpenAction(DialogName.WeeklyAvailabilityMissing, open);
    };
    const openRecruiterAvailabilityDialog = () => {
        openDialogDismissOthers(DialogName.RecruiterAvailability);
    };
    const handleClose = () => setOpen(false);

    return (
        <WeeklyAvMissingDialog
            isOpen={isOpen}
            setOpen={setOpen}
            handleClose={handleClose}
            openRecruiterAvailabilityDialog={openRecruiterAvailabilityDialog}
        />
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    openDialogDismissOthers,
};

export default connect(null, mapDispathToProps)(WeeklyAvMissingDialogProvider);
