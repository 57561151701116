import { VIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

function HistoryFlowItemVIcon({ done }: { done: boolean }) {
    return (
        <div
            className={cn(
                'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                'flex items-center justify-center',
                'w-6 h-6 bg-primary rounded-full',
                { 'border-2 border-primary bg-white': !done },
            )}
        >
            {done && <VIcon className={cn('stroke-white fill-white')} />}
        </div>
    );
}

interface Props {
    isTopItem?: boolean;
    isBottomItem?: boolean;
    isActionItem?: boolean;
    content?: ReactNode;
}

export function ProcessHistoryRow({
    isTopItem = false,
    isBottomItem = false,
    isActionItem = false,
    content,
}: Props) {
    const isEdgeItem = isTopItem || isBottomItem;
    const isTheOnlyItem = isTopItem && isBottomItem;
    const isGapRow = !content;
    return (
        <div
            className={cn('relative w-full flex flex-nowrap items-stretch', {
                'h-14': isGapRow,
            })}
        >
            <div
                className={cn(
                    'relative w-[20px] min-w-[20px] flex-col flex items-center',
                    {
                        'justify-start': isBottomItem,
                        'group-first-of-type:justify-end': isTopItem,
                    },
                )}
            >
                <div
                    className={cn('w-[1px] bg-primary h-full', {
                        'h-1/2': isEdgeItem,
                        'h-0': isTheOnlyItem,
                    })}
                />
                {!isGapRow && <HistoryFlowItemVIcon done={!isActionItem} />}
            </div>
            <div className={cn('ml-5 flex-grow')}>{content}</div>
        </div>
    );
}
export default ProcessHistoryRow;
