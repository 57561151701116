export enum DialogName {
    RecruiterAvailability = 'RecruiterAvailability',
    RejectCandidateConfirm = 'RejectCandidateConfirm',
    WeeklyAvailabilityMissing = 'WeeklyAvailabilityMissing',
    CandidateDetails = 'CandidateDetails',
    InterviewDidntHappen = 'PhoneInterviewDidntHappen',
    RescheduleEventConfirm = 'RescheduleEventConfirm',
    TerminateProcess = 'TerminateProcess',
    MoveProcessForward = 'MoveProcessForward',
    EventDetails = 'EventDetails',
}

export type DialogCallback = () => unknown;

export interface DialogCallbackConfig {
    callback: DialogCallback;
    dialog: DialogName;
}
