import CandidateDetails from '@/candidateDetails/CandidateDetails';
import { Process } from '@/lib/types';

function ExpandedCandidate({
    process: p,
    expanded,
}: {
    process: Process;
    expanded: boolean;
}) {
    return <CandidateDetails process={p} hidden={!expanded} />;
}

export default ExpandedCandidate;
