import { LoaderLottie } from '@/components/lotties';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { serializeProcessHistoryCollection } from '@/lib/utils/processHistory';

import CurrentActionItemRow from './CurrentActionItemRow';
import ProcessHistoryItemRow from './ProcessHistoryItemRow';
import useProcessHistory from './useProcessHistory';
import { hasCurrentActionItem } from './utils';

interface Props {
    process: Process;
}

export function ProcessHistoryFlow({ process }: Props) {
    const { history, fetcher } = useProcessHistory(process.id);

    const historyToShow = serializeProcessHistoryCollection(history).reverse();
    const historyRowsCount = historyToShow.length;

    const hasActionItem = hasCurrentActionItem(process);
    return (
        <div className={cn('relative h-full pl-8 pr-5')}>
            <div className={cn('relative py-8 w-full')}>
                {hasActionItem && fetcher.initiated && (
                    <CurrentActionItemRow process={process} />
                )}
                {historyToShow.map((historyItem, index) => (
                    <ProcessHistoryItemRow
                        key={historyItem.id}
                        historyItem={historyItem}
                        isTopItem={index === 0 && !hasActionItem}
                        isBottomItem={index === historyRowsCount - 1}
                    />
                ))}
                {fetcher.isLoading && !fetcher.initiated && (
                    <div
                        className={cn(
                            'relative flex items-center justify-center w-full h-full',
                        )}
                    >
                        <LoaderLottie variant='primary' sizing='lg' delay={700} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProcessHistoryFlow;
