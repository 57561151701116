import { ProfessionAvatar } from '@/components/ui';
import { Process, Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.common;

export function CandidateTitle({
    process,
    subtitle,
}: {
    process: Process;
    subtitle?: string;
}) {
    const { candidate } = process;
    const { profession, name } = candidate;
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    return (
        <div className={cn('flex items-center')}>
            <ProfessionAvatar
                profession={profession as Profession}
                className={cn('w-14 h-14')}
            />
            <div className={cn('pl-4')}>
                <p className={cn('text-15 font-semibold')}>{name}</p>
                <p className={cn('text-11')}>
                    {subtitle
                        ? subtitle
                        : strings.candidateDescription.func(
                              profession as Profession,
                              facilityName,
                              facilitiesCount,
                          )}
                </p>
            </div>
        </div>
    );
}

export default CandidateTitle;
