import { genericToasts, terminationReasonsToStrings } from '../common';

const strings = {
    title: 'Reject this candidate',
    subtitle: {
        func: (candidateName: string) =>
            `This will permanently remove ${candidateName} from your batch.`,
        example: ['[CANDIDATE_FULL_NAME]'],
    },
    selectReasonPlaceholder: 'Select rejection reason',
    freeTextPlaceholder:
        'Feel free to elaborate and add anything we should know (optional)',
    terminationReasons: {
        ...terminationReasonsToStrings,
    },
    cancelButton: 'No, cancel',
    rejectButton: 'Yes, reject',
    submit: {
        successTitle: 'Candidate rejected',
        successSubtitle: {
            func: (name: string) =>
                `${name} will no longer appear in the list of candidates.`,
            example: ['[CANDIDATE_FULL_NAME]'],
        },
        ...genericToasts.failure,
    },
};
export default strings;
