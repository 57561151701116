import { UserRole } from '@/lib/types';
import { createSelector } from 'reselect';

import { AuthState } from './base';

export const CurrentAuthUser = createSelector(AuthState, (s) => s?.user);

export const ImpersonatedUser = createSelector(AuthState, (s) => s?.impersonatedUser);

export const IsImpersonating = createSelector(ImpersonatedUser, (u) => !!u);

export const CurrentUser = createSelector(
    CurrentAuthUser,
    ImpersonatedUser,
    (authUser, impersonatedUser) => {
        if (impersonatedUser) {
            return { ...impersonatedUser };
        }
        if (authUser) {
            return { ...authUser };
        }
        return undefined;
    },
);

export const IsOperator = createSelector(
    CurrentUser,
    (user) => !!(user && user.role === UserRole.Operator),
);

export const CurrentUserDisplayName = createSelector(
    CurrentUser,
    (user): { fullName: string; firstName: string } => {
        if (!user || !user.name) {
            return {
                fullName: '',
                firstName: '',
            };
        }
        return {
            fullName: user.name,
            firstName: user.name.split(' ')[0],
        };
    },
);

export const CurrentUserShortLetters = createSelector(CurrentUser, (user) => {
    if (!user || !user.name) {
        return null;
    }
    if (user.name) {
        return `${user.name
            .split(' ')
            .slice(0, 2)
            .map((w) => w[0])
            .join('')}`.toUpperCase();
    }
});
