export enum FetchModelStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'succeeded',
    FAILURE = 'failed',
}

export interface FetcherState {
    initiated: boolean;
    status: FetchModelStatus;
    error?: string;
}

export interface FetcherStateData {
    initiated: boolean;
    isError: boolean;
    isLoading: boolean;
    status: FetchModelStatus;
    error?: string;
}
