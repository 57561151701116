import { clearImpersonatedUser, setImpersonatedUser } from '@/_reducers/auth';
import { ImpersonationControllerSelector } from '@/_selectors';
import config from '@/config';
import useResetRecruiterData from '@/hooks/useResetRecruiterData';
import { AuthUser } from '@/lib/types';
import { cn, getLocalStorageGracefully } from '@/lib/utils';
import { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';

import ImpersonationDialog from './ImpersonationDialog';
import ImpersonationIndication from './ImpersonationIndication';

interface Props {
    children: ReactNode;
    clearImpersonatedUser: () => void;
    setImpersonatedUser: (user: AuthUser) => void;
    impersonatedUser: AuthUser | null;
    isOperator: boolean;
}

function ImpersonationController({
    children,
    clearImpersonatedUser,
    setImpersonatedUser,
    isOperator,
    impersonatedUser,
}: Props) {
    const { reset: resetRecruiterData } = useResetRecruiterData();

    useEffect(() => {
        if (!isOperator) {
            return;
        }
        const localStorage = getLocalStorageGracefully();
        if (localStorage && localStorage['oparetorData_impersonatedUser']) {
            setImpersonatedUser(
                JSON.parse(localStorage['oparetorData_impersonatedUser']),
            );
        }
    }, [setImpersonatedUser, isOperator]);
    const mustImpersonate = isOperator && !impersonatedUser;

    useEffect(() => {
        if (mustImpersonate) {
            resetRecruiterData();
        }
    }, [mustImpersonate, resetRecruiterData]);

    return (
        <>
            {!mustImpersonate && (
                <div className={cn('flex flex-col-reverse h-full')}>
                    <div className={cn('flex-grow relative overflow-hidden')}>
                        <div className={cn('relative w-full h-full')}>{children}</div>
                    </div>
                    {impersonatedUser && (
                        <ImpersonationIndication
                            impersonatedUser={impersonatedUser}
                            exitImpersonation={clearImpersonatedUser}
                            env={config.ENV}
                        />
                    )}
                </div>
            )}
            {mustImpersonate && <ImpersonationDialog open={!impersonatedUser} />}
        </>
    );
}

const mapDispatchToProps = {
    clearImpersonatedUser,
    setImpersonatedUser,
};

export default connect(
    ImpersonationControllerSelector,
    mapDispatchToProps,
)(ImpersonationController);
