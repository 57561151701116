import { setDialogOpen } from '@/_reducers/dialogs';
import { RejectCandidateParams, rejectCandidate } from '@/_reducers/processes';
import {
    IsRejectCandidateConfirmDialogOpen,
    RejectCandidateConfirmDialogData,
} from '@/_selectors';
import { DialogName } from '@/lib/types';
import { connect, useSelector } from 'react-redux';

import RejectCandidateDialog from './RejectCandidateDialog';

function RejectCandidateDialogProvider({
    rejectCandidate,
    setDialogOpenAction,
}: {
    rejectCandidate: (params: RejectCandidateParams) => Promise<void>;
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
}) {
    const isOpen = useSelector(IsRejectCandidateConfirmDialogOpen);
    const { process } = useSelector(RejectCandidateConfirmDialogData);
    const hasData = !!process;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.RejectCandidateConfirm, open);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {hasData && (
                <RejectCandidateDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    process={process}
                    handleClose={handleClose}
                    rejectCandidate={rejectCandidate}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    rejectCandidate,
    setDialogOpenAction: setDialogOpen,
};

export default connect(null, mapDispathToProps)(RejectCandidateDialogProvider);
