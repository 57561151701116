import { cn, handlerNoPropagation } from '@/lib/utils';
import { composeEventHandlers } from '@radix-ui/primitive';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';

const DialogBase = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
        handleClose?: () => void;
    }
>(({ className, handleClose, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn('fixed inset-0 z-50 bg-black/50  dark:bg-slate-950/80', className)}
        {...props}
        {...(handleClose
            ? {
                  onClick: composeEventHandlers(
                      props?.onClick,
                      handlerNoPropagation(handleClose),
                  ),
              }
            : { onClick: (e) => e.stopPropagation() })}
    />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
        noX?: boolean;
    }
>(({ className, children, ...props }, ref) => (
    <DialogPrimitive.Content
        ref={ref}
        className={cn(
            'w-max max-w-[90%] h-auto max-h-[95%] p-0 overflow-hidden overflow-y-auto',
            'fixed z-50 grid',
            'gap-4 rounded-2xl border border-slate-200 bg-white shadow-lg',
            'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
            'rounded-2xl dark:border-slate-800 dark:bg-slate-950',
            className,
        )}
        {...props}
    >
        {children}
    </DialogPrimitive.Content>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            'relative flex justify-between items-stretch p-10 xs:p-8 w-full',
            'border-b border-accent-500',
            className,
        )}
        {...props}
    />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
            className,
        )}
        {...props}
    />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn('text-lg font-semibold leading-none tracking-tight', className)}
        {...props}
    />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn('text-sm text-slate-500 dark:text-slate-400', className)}
        {...props}
    />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export type DialogTriggerProps = DialogPrimitive.DialogTriggerProps;
export type DialogProps = DialogPrimitive.DialogProps;
export type DialogPortalProps = DialogPrimitive.DialogPortalProps;
export type DialogOverlayProps = DialogPrimitive.DialogOverlayProps;
export type DialogCloseProps = DialogPrimitive.DialogCloseProps;
export type DialogContentProps = DialogPrimitive.DialogContentProps;
export type DialogTitleProps = DialogPrimitive.DialogTitleProps;
export type DialogDescriptionProps = DialogPrimitive.DialogDescriptionProps;
export type DialogHeaderProps = React.HTMLAttributes<HTMLDivElement>;

export {
    DialogBase,
    DialogPortal,
    DialogOverlay,
    DialogClose,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
};
