import { Button, LoadingButton, useToast } from '@/components/ui';
import { RecruiterTerminationReason } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import { TerminateProcessDialogFooterProps } from './types';

const strings = dialogsStrings.terminateProcess;

export function TerminateProcessDialogFooter({
    handleClose,
    terminate,
    reason,
    freeText,
}: TerminateProcessDialogFooterProps) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const submit = async () => {
        if (!reason) return;
        setIsLoading(true);
        await terminate({
            reason,
            additionalText: freeText,
        })
            .catch((error) => {
                toast({
                    title: strings.terminate.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const isFreeTextRequired = reason === RecruiterTerminationReason.Other;
    const disabled = !reason || (isFreeTextRequired && !freeText);

    return (
        <div className={cn('w-full flex gap-6')}>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <Button
                    className={cn('w-full')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {strings.cancelButton}
                </Button>
            </div>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <LoadingButton
                    className={cn('w-full')}
                    variant={'danger'}
                    size={'lg'}
                    onClick={submit}
                    disabled={disabled}
                >
                    {strings.terminateButton}
                </LoadingButton>
            </div>
        </div>
    );
}

export default TerminateProcessDialogFooter;
