import { ArrowDown, LogoWithText } from '@/components/svgs';
import { CustomPopover } from '@/components/ui';
import { cn } from '@/lib/utils';
import { useHover } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { useState } from 'react';

import UserProfileCircle from './UserProfileCircle';
import NavItemsMenu from './navItemsMenu';
import { SidebarProps } from './types';
import UserMenu from './userMenu';

const DRAWER_OFF = false;

export default function Sidebar({ className }: SidebarProps) {
    const [hoverRef, hovering] = useHover();
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const isOnHover = hovering || userMenuOpen;

    const dynamicWidth = isOnHover && !DRAWER_OFF ? 240 : 90;

    const showOnHoverClass = DRAWER_OFF
        ? ''
        : cn('duration-300', isOnHover ? 'opacity-1' : 'opacity-0');
    return (
        <motion.div
            transition={{ type: 'spring', stiffness: 400, damping: 30, mass: 1 }}
            animate={{ width: dynamicWidth }}
            className={cn(
                'absolute z-50 top-0 left-0 w-[90px] h-full bg-white border-r border-neutral100',
                { 'shadow-elevation-300': isOnHover },
                className,
            )}
            ref={DRAWER_OFF ? null : hoverRef}
        >
            <div
                className={cn(
                    'w-full h-full flex flex-col space-between overflow-hidden',
                )}
            >
                <div>
                    <div className={cn('relative px-9 py-8')}>
                        <CustomPopover
                            trigger={
                                <div
                                    className={cn(
                                        'relative inline-flex items-center cursor-pointer',
                                    )}
                                >
                                    <UserProfileCircle />
                                    {isOnHover && (
                                        <ArrowDown
                                            className={cn(
                                                'ml-3 w-2.5 stroke-black fill-black',
                                                showOnHoverClass,
                                            )}
                                        />
                                    )}
                                </div>
                            }
                            contentProps={{ className: cn('rounded-xl') }}
                            open={userMenuOpen}
                            setOpen={setUserMenuOpen}
                        >
                            <UserMenu />
                        </CustomPopover>
                    </div>
                    <div className={cn('mt-10')}>
                        <NavItemsMenu onHoverClass={showOnHoverClass} />
                    </div>
                </div>
                <div className={cn('mt-auto')}>
                    <div className={cn('relative pl-11 py-10')}>
                        <LogoWithText className={cn('h-6 w-auto ml-[1px]')} />
                        <div
                            className={cn('h-full absolute top-0 right-0 w-11 bg-white')}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
