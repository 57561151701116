import { JwtPayload } from 'jwt-decode';

export interface AuthUser {
    email: string;
    id: string;
    name?: string;
    role?: UserRole;
}

export enum UserRole {
    Operator = 'operator',
    Recruiter = 'recruiter',
}

export interface IdTokenJwtPayload extends JwtPayload {
    role: UserRole;
}
