import { cn } from '@/lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export const buttonVariants = cva(
    cn(
        'inline-flex items-center justify-center rounded-xl whitespace-nowrap text-sm font-semibold',
        'border border-primary transition-colors focus-visible:outline-none',
        'disabled:pointer-events-none disabled:opacity-30',
        'ease-in-out	transition-all duration-100',
    ),
    {
        variants: {
            variant: {
                default: cn(
                    'bg-accent600 text-accent100 hover:bg-accent700 hover:border-accent700',
                ),
                neutral: cn(
                    'bg-accent300 border-accent300 text-black hover:bg-accent400 hover:border-accent400',
                ),
                destructive: cn(
                    'border-primary bg-transparent text-black hover:bg-accent400',
                ),
                danger: cn(
                    'text-white bg-red200 border-red200 hover:bg-red100 hover:border-red100',
                ),
                secondary: cn('border-primary bg-white text-black hover:bg-accent400'),
                outlinedAction: cn(
                    'border-primary bg-white text-black hover:bg-primary hover:text-white',
                ),
            },
            size: {
                default: cn('h-14 px-8 text-12'),
                smThin: cn('h-12 px-5 text-12'),
                sm: cn('h-12 px-8 text-11'),
                tiny: cn('h-10 px-5 text-11'),
                lg: cn('h-16 px-8 text-13'),
                xl: cn('h-20 px-15 text-14'),
                square: cn('p-0 h-12 w-12'),
                icon: cn('h-10 w-10'),
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;
