import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import { getStatusBadgeColorsCls, getStatusBadgeText } from './utils';

function ProcessStatusBadge({ process }: { process: Process }) {
    const text = getStatusBadgeText(process);
    const colorCls = getStatusBadgeColorsCls(process);

    return (
        <div
            className={cn(
                'flex items-center w-fit whitespace-nowrap',
                'rounded-lg px-3 h-[26px] text-11 font-semibold',
                colorCls,
            )}
        >
            {text}
        </div>
    );
}

export default ProcessStatusBadge;
