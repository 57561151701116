import React, { useContext } from 'react';

const DialogContext = React.createContext({
    handleClose: () => {},
    handleOpen: () => {},
    open: false,
    isExpandedToTop: false,
    setIsExpandedToTop: (() => {}) as (val: boolean) => void,
    draggedDownPosition: 0,
    setDraggedDownPosition: (() => {}) as (val: number) => void,
    dialogContentRef: { current: null } as React.RefObject<HTMLDivElement>,
    scrollRef: { current: null } as React.RefObject<HTMLDivElement>,
    withVerticalScroll: false,
});

export const useDialogContext = () => useContext(DialogContext);
export default DialogContext;
