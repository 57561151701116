import { AvailableDays } from '@/_selectors';
import { ArrowDown } from '@/components/svgs';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';
import { useSelector } from 'react-redux';

const strings = candidatesStrings.newRequests.recruiterAvailability;

function YourAvButton({ onClick }: { onClick: () => void }) {
    const isMobileScreen = useIsMobileScreen();
    const availableDays = useSelector(AvailableDays);
    const noAv = availableDays.length === 0;
    return (
        <div
            className={cn(
                'relative max-w-full ml-auto flex items-center cursor-pointer xs:flex-col xs:items-start',
            )}
            onClick={onClick}
        >
            {isMobileScreen ? (
                <>
                    <p
                        className={cn(
                            'text-13 font-semibold overflow-hidden whitespace-nowrap text-ellipsis max-w-full',
                        )}
                    >
                        <span>{strings.labelPart1Mobile}</span>
                    </p>
                    <div className={cn('max-w-full flex items-center')}>
                        <span
                            className={cn(
                                'text-blue text-13 font-semibold overflow-hidden whitespace-nowrap text-ellipsis',
                            )}
                        >
                            {noAv
                                ? strings.labelPart2NoValue
                                : strings.labelPart2Value.func(availableDays)}
                        </span>
                        <ArrowDown
                            className={cn('stroke-blue ml-2', { 'stroke-blue': noAv })}
                        />
                    </div>
                </>
            ) : (
                <>
                    <p
                        className={cn(
                            'text-13 font-semibold overflow-hidden whitespace-nowrap text-ellipsis max-w-full',
                        )}
                    >
                        <span>{strings.labelPart1} </span>
                        <span className={cn('text-blue')}>
                            {noAv
                                ? strings.labelPart2NoValue
                                : strings.labelPart2Value.func(availableDays)}
                        </span>
                    </p>
                    <ArrowDown
                        className={cn('stroke-blue ml-2', { 'stroke-blue': noAv })}
                    />
                </>
            )}
        </div>
    );
}

export default YourAvButton;
