import { setDialogOpen } from '@/_reducers/dialogs';
import { PostTerminateProcess, terminateProcess } from '@/_reducers/processes';
import { IsTerminateProcessDialogOpen, TerminateProcessDialogData } from '@/_selectors';
import { DialogName } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import TerminateProcessDialog from './TerminateProcessDialog';
import { TerminateFunc } from './types';

function TerminateProcessDialogProvider({
    setDialogOpenAction,
    terminateAction,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    terminateAction: (params: PostTerminateProcess) => Promise<void>;
}) {
    const shouldBeOpen = useSelector(IsTerminateProcessDialogOpen);
    const { process, defaultSelection } = useSelector(TerminateProcessDialogData);
    const hasData = !!process;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.TerminateProcess, open);
    };
    const handleClose = () => setOpen(false);

    const terminate: TerminateFunc = async (params) => {
        trackEvent(MixpanelEvent.TerminateCandidate);
        return await terminateAction({ ...params, processId: process!.id });
    };

    return (
        <>
            {hasData && (
                <TerminateProcessDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    defaultSelection={defaultSelection}
                    terminate={terminate}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    terminateAction: terminateProcess,
};

export default connect(null, mapDispathToProps)(TerminateProcessDialogProvider);
