export enum Step {
    Initial = 'Initial',
    SignIn = 'SignIn',
    EmailSent = 'EmailSent',
}

export type SignInSectionProps = {
    email: string;
    setEmail: (err: string) => void;
    error: string;
    setError: (err: string) => void;
    onEmailSent: () => void;
    signInFromEmailLink: (email: string) => Promise<void>;
};

export type UseSignInProps = {
    email: string;
    setError: (err: string) => void;
    onEmailSent: () => void;
    signInFromEmailLink: (email: string) => Promise<void>;
};

export type LoginPageLayoutProps = Omit<SignInSectionProps, 'onEmailSent'> & {
    step: Step;
    setStep: (step: Step) => void;
};
