import { Dialog } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

import AvailabilityFormProvider from './AvailabilityFormProvider';
import { AvailabilityDialogProviderProps } from './types';
import { avRecordToInputSchema, generateEmptyRecruiterAvailability } from './utils';

const strings = candidatesStrings.newRequests.recruiterAvailability;

function AvailabilityFormDialog({
    isOpen,
    setOpen,
    actualState,
    ...formProps
}: AvailabilityDialogProviderProps) {
    const initialState = actualState
        ? avRecordToInputSchema(actualState)
        : generateEmptyRecruiterAvailability();

    return (
        <Dialog
            contentProps={{
                className: cn('w-[90%] max-w-[650px] xs:w-full h-[80%] xs:h-full'),
            }}
            contentWrapperProps={{
                className: cn('absolute w-full h-full flex flex-col'),
            }}
            open={isOpen}
            setOpen={setOpen}
            title={
                <div>
                    <p className={cn('text-18 font-semibold leading-8')}>
                        {strings.dialogTitle}
                    </p>
                    <p className={cn('mt-2 text-14')}>{strings.dialogSubtitle}</p>
                </div>
            }
            withVerticalScroll
        >
            <AvailabilityFormProvider initialState={initialState} {...formProps} />
        </Dialog>
    );
}

export default AvailabilityFormDialog;
