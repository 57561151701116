import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';

import { useDialogContext } from './context';
import { ContentWithFooterProps } from './types';

function DialogContentWithFooter({
    bodyProps,
    footer,
    footerProps,
    children,
}: ContentWithFooterProps) {
    const isMobileScreen = useIsMobileScreen();
    const { scrollRef, withVerticalScroll } = useDialogContext();
    return (
        <>
            <div
                {...bodyProps}
                ref={isMobileScreen ? scrollRef : undefined}
                className={cn(
                    {
                        'flex-grow w-full h-full overflow-auto p-8':
                            isMobileScreen || withVerticalScroll,
                    },
                    bodyProps?.className,
                )}
            >
                {children}
            </div>
            {footer && (
                <div
                    {...footerProps}
                    className={cn(
                        'relative w-full max-w-full border-t border-neutral100',
                        'py-8 px-10',
                        footerProps?.className,
                    )}
                >
                    {footer}
                </div>
            )}
        </>
    );
}

export default DialogContentWithFooter;
