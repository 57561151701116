import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { ButtonProps } from './types';
import { buttonVariants } from './variants';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            onClick,
            allowPropagation = false,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : 'button';
        const noPropagationOnClick = (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ) => {
            event.stopPropagation();
            return onClick && onClick(event);
        };
        return (
            <Comp
                className={cn('relative', buttonVariants({ variant, size, className }))}
                ref={ref}
                onClick={
                    onClick
                        ? allowPropagation
                            ? onClick
                            : noPropagationOnClick
                        : undefined
                }
                {...props}
            />
        );
    },
);
Button.displayName = 'Button';

export { Button };
