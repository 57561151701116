import { AppDispatch } from '@/_helpers/store';
import dialogsSlice, {
    DialogData,
    SetDialogDataPayload,
    setDialogOpen,
} from '@/_reducers/dialogs';
import { DialogName } from '@/lib/types';
import { useDispatch } from 'react-redux';

function useOpenDialog<T extends DialogName>(dialog: T) {
    const dispatch = useDispatch<AppDispatch>();
    const openDialog = (data: DialogData<T>) => {
        if (data) {
            dispatch(
                dialogsSlice.actions.setDialogData({
                    dialog,
                    data,
                } as SetDialogDataPayload),
            );
        }
        dispatch(setDialogOpen(dialog, true));
    };
    return {
        openDialog,
    };
}

export default useOpenDialog;
