import { RejectCandidateParams } from '@/_reducers/processes';
import { Dialog } from '@/components/ui';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import CandidateTitle from '../common/CandidateTitle';
import { BaseDialogPropsCommon } from '../types';
import RejectCandidateDialogContent from './dialogContent';

interface Props extends BaseDialogPropsCommon {
    rejectCandidate: (params: RejectCandidateParams) => Promise<void>;
    process: Process;
}

function RejectCandidateDialog({
    rejectCandidate,
    process,
    isOpen,
    setOpen,
    handleClose,
}: Props) {
    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            title={<CandidateTitle process={process} />}
            contentProps={{ className: cn('w-[650px]') }}
            headerProps={{
                className: cn('py-8'),
            }}
        >
            <RejectCandidateDialogContent
                process={process}
                handleClose={handleClose}
                terminate={(params) =>
                    rejectCandidate({ processId: process.id, ...params })
                }
            />
        </Dialog>
    );
}

export default RejectCandidateDialog;
