import { AppDispatch, GetRootState } from '@/_helpers/store';
import { IsMobileScreen } from '@/_selectors';
import { DialogName } from '@/lib/types';

import { DIALOG_ANIMATION_LENGTH, MOBILE_DIALOG_ANIMATION_LENGTH } from './consts';
import dialogsSlice from './dialogsSlice';
import { DialogNameWithData, SetDialogDataPayload } from './types';

export const openDialog = (dialog: DialogName) => setDialogOpen(dialog, true);
export const closeDialog = (dialog: DialogName) => setDialogOpen(dialog, false);

export const openDialogDismissOthers =
    (...[dialog, data]: DialogNameWithData) =>
    (dispatch: AppDispatch, getState: GetRootState) => {
        const isMobile = IsMobileScreen(getState());
        dispatch(closeAllDialogs());
        setTimeout(
            () => {
                if (data) {
                    dispatch(
                        dialogsSlice.actions.setDialogData({
                            dialog,
                            data,
                        } as SetDialogDataPayload),
                    );
                }
                dispatch(setDialogOpen(dialog, true));
            },
            isMobile ? MOBILE_DIALOG_ANIMATION_LENGTH : DIALOG_ANIMATION_LENGTH,
        );
    };

export const reAnimateDialog =
    (dialog: DialogName, callback?: () => void) =>
    (dispatch: AppDispatch, getState: GetRootState) => {
        const isMobile = IsMobileScreen(getState());
        dispatch(closeDialog(dialog));
        setTimeout(
            () => {
                dispatch(openDialog(dialog));
                callback && callback();
            },
            isMobile ? MOBILE_DIALOG_ANIMATION_LENGTH : DIALOG_ANIMATION_LENGTH,
        );
    };

export const closeAllDialogs = () => (dispatch: AppDispatch) => {
    dispatch(dialogsSlice.actions.closeAll());
};
export const setDialogOpen = (dialog: DialogName, open: boolean) => {
    return (dispatch: AppDispatch) => {
        dispatch(dialogsSlice.actions.setOpen({ dialog, open }));
    };
};

export const openRejectCandidateDialog =
    (processId: string) => (dispatch: AppDispatch) => {
        dispatch(
            dialogsSlice.actions.setDialogData({
                dialog: DialogName.RejectCandidateConfirm,
                data: { processId },
            }),
        );
        dispatch(openDialog(DialogName.RejectCandidateConfirm));
    };

export const openCandidateDetailsDialog =
    (processId: string) => (dispatch: AppDispatch) => {
        dispatch(
            dialogsSlice.actions.setDialogData({
                dialog: DialogName.CandidateDetails,
                data: { processId },
            }),
        );
        dispatch(openDialog(DialogName.CandidateDetails));
    };
