import newRequests from './newRequests';
import tasks from './tasks';
import upcomingEvents from './upcomingEvents';

export default {
    header: {
        func: (firstName: string) => `Hey ${firstName}`,
        example: ['[USER_FIRST_NAME]'],
    },
    mobile: {
        summary: {
            header: {
                func: (firstName: string) => `Hey ${firstName}, you have:`,
                example: ['Rick'],
            },
            tasksLabel: 'Decisions to make',
            newCandidatesLabel: 'New candidates',
            upcomingEventsLabel: 'Upcoming events',
        },
    },
    tasks,
    newRequests,
    upcomingEvents,
};
