import useLocationHash from '@/hooks/useLocationHash';
import useSetHash from '@/hooks/useSetHash';
import { HomepageSectionAnchor } from '@/lib/types/queryParams';
import { cn } from '@/lib/utils';
import { useEffect, useRef } from 'react';

import { HompageLayoutProps } from '../types';
import useNewCandidates from '../useNewCandidates';
import useUpcomingEvents from '../useUpcomingEvents';
import HomepageHeader from './HomepageHeader';
import NewCandidatesSection from './NewCandidatesSection';
import PendingTasksSection from './PendingTasksSection';
import SummarySection from './SummarySection';
import UpcomingEventsSection from './UpcomingEventsSection';

const useOnSectionClick = (
    anchor: string,
    { preventScroll = false }: { preventScroll?: boolean } | undefined = {},
) => {
    const sectionAnchor = useLocationHash();
    const isAnchorActive = sectionAnchor === anchor;

    const sectionRef = useRef<HTMLDivElement>(null);
    const setHash = useSetHash();
    const scrollTo = () => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const onTriggerClick = () => {
        if (isAnchorActive) {
            scrollTo();
        } else {
            setHash(anchor);
        }
    };
    useEffect(() => {
        if (preventScroll) {
            return;
        }
        if (isAnchorActive) {
            scrollTo();
        }
    }, [isAnchorActive, preventScroll]);

    return {
        onTriggerClick,
        sectionRef,
    };
};

function HomePageMobileLayout({
    pendingTasksProcesses,
    initiatedTasks,
    isLoadingTasks,
}: HompageLayoutProps) {
    const {
        totalCount: newCandidatesCount,
        newCandidates,
        isLoading: isLoadingCandidates,
        initiated: initiatedCandidates,
        goToCandidates,
    } = useNewCandidates();
    const {
        totalCount: upcomingEventsCount,
        initiated: initiatedUpcomingEvents,
        events,
        showLoader: isLoadingUpcomingEvents,
        onEventBlockClick,
    } = useUpcomingEvents();
    const tasksCount = pendingTasksProcesses.length;

    const isLoadingAny =
        isLoadingTasks || isLoadingCandidates || isLoadingUpcomingEvents;
    const isInitiatedAny =
        initiatedTasks || initiatedCandidates || initiatedUpcomingEvents;
    const isIdle = !isInitiatedAny && !isLoadingAny;

    const { sectionRef: pendingTasksRef, onTriggerClick: goToPendingTasks } =
        useOnSectionClick(HomepageSectionAnchor.PendingTasks, { preventScroll: isIdle });
    const { sectionRef: newCandidatesRef, onTriggerClick: goToNewCandidates } =
        useOnSectionClick(HomepageSectionAnchor.NewCandidates, {
            preventScroll: isIdle,
        });
    const { sectionRef: upcomingEventsRef, onTriggerClick: goToUpcomingEvents } =
        useOnSectionClick(HomepageSectionAnchor.UpcomingEvents, {
            preventScroll: isIdle,
        });

    return (
        <div className={cn('relative h-full w-full flex flex-col items-stretch')}>
            <HomepageHeader />
            <div
                className={cn(
                    'relative bg-accent300 px-6 xs:mx-4 flex-grow overflow-auto xs:hidden-scrollbar',
                )}
            >
                <div className={cn('w-full max-w-[500px] mx-auto')}>
                    <SummarySection
                        {...{
                            newCandidatesCount,
                            upcomingEventsCount,
                            tasksCount,
                            goToPendingTasks,
                            goToNewCandidates,
                            goToUpcomingEvents,
                            isLoadingTasks,
                            isLoadingCandidates,
                            isLoadingUpcomingEvents,
                            initiatedTasks,
                            initiatedCandidates,
                            initiatedUpcomingEvents,
                        }}
                    />
                    <PendingTasksSection
                        {...{
                            pendingTasksProcesses,
                            initiatedTasks,
                            isLoadingTasks,
                            anchorRef: pendingTasksRef,
                        }}
                    />
                    <NewCandidatesSection
                        {...{
                            newProcesses: newCandidates,
                            isLoading: isLoadingCandidates,
                            initiated: initiatedCandidates,
                            goToCandidates,
                            anchorRef: newCandidatesRef,
                        }}
                    />
                    <UpcomingEventsSection
                        {...{
                            events,
                            initiated: initiatedUpcomingEvents,
                            isLoading: isLoadingUpcomingEvents,
                            onEventBlockClick,
                            anchorRef: upcomingEventsRef,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default HomePageMobileLayout;
