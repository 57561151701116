import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';

import {
    SelectBase,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from './base';
import SelectMobile from './selectMobile';
import { SelectProps } from './types';

function SelectDesktop<T extends string>({
    options,
    value,
    onChange,
    placeholder,
    triggerProps,
    ...baseProps
}: SelectProps<T>) {
    const { className: triggerCls, ...restTriggerProps } = triggerProps ?? {};
    return (
        <SelectBase value={value} onValueChange={onChange} {...baseProps}>
            <SelectTrigger className={cn('w-[400px]', triggerCls)} {...restTriggerProps}>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options.map((opt) => (
                        <SelectItem key={opt.id} value={opt.value}>
                            {opt.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </SelectBase>
    );
}

function Select<T extends string>(props: SelectProps<T>) {
    const isMobileScreen = useIsMobileScreen();
    return isMobileScreen ? <SelectMobile {...props} /> : <SelectDesktop {...props} />;
}

export default Select;
