export enum QueryParam {
    PROCESS_ID = 'candidate-process',
}

export enum CandidatesTab {
    New = 'new',
    InProcess = 'in-process',
}

export enum HomepageSectionAnchor {
    PendingTasks = 'pending-tasks',
    NewCandidates = 'new-candidates',
    UpcomingEvents = 'upcoming-events',
}
