import { DialogName } from '@/lib/types';
import { useContext } from 'react';

import { DialogsContext } from '../dialogsContext';
import { DialogsData } from '../types';

export function useAvailabilityDialogData() {
    const { getContextForDialog, setContextForDialog } = useContext(DialogsContext);
    const data = getContextForDialog(DialogName.RecruiterAvailability);
    const setData = (data: DialogsData[DialogName.RecruiterAvailability]) =>
        setContextForDialog(DialogName.RecruiterAvailability, data);

    return {
        data,
        setData,
    };
}

export default useAvailabilityDialogData;
