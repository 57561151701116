import { EventType } from '@/lib/api/v1';
import { format } from 'date-fns';

import { eventTypeToName, genericToasts, professionToName } from '../common';

const strings = {
    title: 'Confirm request for event reschedule',
    description: {
        func: (firstName: string, eventType: EventType, startAt: Date) =>
            `You are about to cancel the ${eventTypeToName[eventType].toLowerCase()} with ${firstName} on ${format(startAt, 'MMM dd')}.\n` +
            `This will cancel the event and we will reach out to ${firstName} to reschedule it.\n` +
            'Should we proceed?',
        example: ['[David]', EventType.PhoneInterview, new Date('2024-02-03T10:00:00Z')],
    },
    cancelButton: 'No, keep event as is',
    rescheduleButton: 'Yes, reschedule event',
    professionToName,
    successStep: {
        title: "Great, we're on it!",
        description: {
            func: (firstName: string) =>
                `We'll reach out to ${firstName} to reschedule this event.`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
    },
    reschedule: {
        ...genericToasts.failure,
    },
};

export default strings;
