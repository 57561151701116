import NewRequestsLayout from './NewRequestsLayout';
import useNewCandidates from './useNewCandidates';

function NewRequests() {
    const {
        totalCount,
        isEmpty,
        initiated,
        isLoading,
        openCandidateDetails,
        newCandidates,
        goToCandidates,
    } = useNewCandidates();

    return (
        <NewRequestsLayout
            {...{
                totalCount,
                isEmpty,
                initiated,
                isLoading,
                openCandidateDetails,
                newCandidates,
                goToCandidates,
            }}
        />
    );
}

export default NewRequests;
