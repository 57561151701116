import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const isMobileSlice = createSlice({
    name: 'isMobile',
    initialState: false,
    reducers: {
        set: (_, action: PayloadAction<boolean>) => action.payload,
    },
});

export default isMobileSlice;
