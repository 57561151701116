import { MailIcon, PhoneIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.common.candidateTitle;

export function CandidateContactInfo({ process }: { process: Process }) {
    const { candidate } = process;
    return (
        <div className={cn('flex items-center flex-wrap gap-4')}>
            <div className={cn('flex items-center gap-2 whitespace-nowrap   ')}>
                <MailIcon className={cn('w-4')} />
                <p className={cn('text-13 font-semibold')}>
                    {candidate.email ?? strings.noEmail}
                </p>
            </div>
            <div className={cn('w-1 h-1 bg-black rounded-full')} />
            <div className={cn('flex items-center gap-2 whitespace-nowrap')}>
                <PhoneIcon className={cn('w-4')} />
                <p className={cn('text-13 font-semibold')}>
                    {candidate.phone ? strings.phoneNumber.func(candidate.phone) : null}
                </p>
            </div>
        </div>
    );
}

export default CandidateContactInfo;
