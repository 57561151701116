import { DialogContentWithFooter, Select } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import { RecruiterTerminationReason, recruiterTerminationReason } from '@/lib/api/v1';
import { sortEnumList } from '@/lib/base';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import TerminateProcessDialogFooter from './footer';
import { SelectedTerminationReason, TerminateFunc } from './types';

const strings = dialogsStrings.terminateProcess;

const terminationReasonsByOrder = [
    RecruiterTerminationReason.Ghosting,
    RecruiterTerminationReason.Commute,
    RecruiterTerminationReason.PayRate,
    RecruiterTerminationReason.LackOfQualifications,
    RecruiterTerminationReason.NoOpenRoles,
    RecruiterTerminationReason.Other,
];

export function TerminateProcessDialogContent({
    handleClose,
    terminate,
    defaultReason,
}: {
    process: Process;
    handleClose: () => void;
    terminate: TerminateFunc;
    defaultReason?: RecruiterTerminationReason;
}) {
    const [reason, setReason] = useState<SelectedTerminationReason>(defaultReason ?? '');
    const [freeText, setFreeText] = useState('');

    const isFreeTextRequired = reason === RecruiterTerminationReason.Other;
    return (
        <DialogContentWithFooter
            footer={
                <TerminateProcessDialogFooter
                    handleClose={handleClose}
                    terminate={terminate}
                    reason={reason}
                    freeText={freeText}
                />
            }
        >
            <div className={cn('relative w-[650px] max-w-full')}>
                <div className={cn('p-12 xs:p-0 xs:pb-4 text-center xs:text-left')}>
                    <p className={cn('text-18 leading-10 font-semibold')}>
                        {strings.title}
                    </p>
                    <p className={cn('text-14 font')}>{strings.description}</p>

                    <Select
                        triggerProps={{
                            className: cn('w-full mt-12 xs:mt-6'),
                        }}
                        value={reason}
                        onChange={(v) => {
                            setReason(v);
                        }}
                        placeholder={strings.selectReasonPlaceholder}
                        options={sortEnumList(
                            recruiterTerminationReason,
                            terminationReasonsByOrder,
                        ).map((r) => ({
                            value: r,
                            label: strings.terminationReasons[r],
                            id: r,
                        }))}
                    />
                    <Textarea
                        className={cn('mt-6 h-[250px] xs:h-[150px] resize-none')}
                        value={freeText}
                        onChange={(e) => setFreeText(e.target.value)}
                        placeholder={
                            isFreeTextRequired
                                ? strings.freeTextPlaceholder.required
                                : strings.freeTextPlaceholder.optional
                        }
                        tabIndex={-1}
                    />
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default TerminateProcessDialogContent;
