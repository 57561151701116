import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import CandidateAdditionalAttributes from './CandidateAdditionalAttributes';
import CandidateJobMatchesProvider from './CandidateJobMatchesProvider';
import CandidateMainDetails from './CandidateMainDetails';
import CandidateResumeTile from './CandidateResumeTile';

const cardHeaderVariant = 'small';

function CandidateDetails({
    process: p,
    hidden = false,
}: {
    process: Process;
    hidden?: boolean;
}) {
    if (!p.candidate) {
        return null;
    }

    return (
        <div>
            <CandidateMainDetails process={p} />
            <div className={cn('grid grid-cols-3 mt-4')}>
                <CandidateJobMatchesProvider
                    process={p}
                    cardProps={{
                        headerVariant: cardHeaderVariant,
                    }}
                />
                <CandidateAdditionalAttributes
                    process={p}
                    cardProps={{
                        className: cn('ml-4'),
                        headerVariant: cardHeaderVariant,
                    }}
                    attributeVariant={'big'}
                />
                <CandidateResumeTile
                    process={p}
                    hidden={hidden}
                    pdfContainerProps={{
                        className: cn('min-h-[200px] lg:min-h-[140px]'),
                    }}
                    cardProps={{
                        className: cn('ml-4'),
                        headerVariant: cardHeaderVariant,
                    }}
                />
            </div>
        </div>
    );
}

export default CandidateDetails;
