import { combineReducers } from 'redux';

import authSlice from './auth';
import dialogsSlice from './dialogs';
import eventsSlice from './events';
import historySlice from './history';
import isMobileSlice from './isMobile';
import processesSlice from './processes';
import recruiterAvailabilitySlice from './recruiterAvailability';
import sessionSettings from './sessionSettings';

const rootReducer = combineReducers({
    recruiterAvailability: recruiterAvailabilitySlice.reducer,
    auth: authSlice.reducer,
    processes: processesSlice.reducer,
    dialogs: dialogsSlice.reducer,
    events: eventsSlice.reducer,
    sessionSettings: sessionSettings.reducer,
    processHistory: historySlice.reducer,
    isMobileScreen: isMobileSlice.reducer,
});

export default rootReducer;
