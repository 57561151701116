import MainDetailData from '@/candidateDetails/MainDetailData';
import { MainDetailProps } from '@/candidateDetails/types';
import { cn } from '@/lib/utils';

function MainDetailMobile({ text, title, icon }: MainDetailProps) {
    return (
        <>
            <div className={cn('w-auto')}>
                <MainDetailData icon={icon} text={text} />
                <div
                    className={cn(
                        'mt-1 font-medium text-neutral300 text-12 whitespace-nowrap',
                    )}
                >
                    {title}
                </div>
            </div>
        </>
    );
}

export default MainDetailMobile;
