import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useRemoveFirebaseParams = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const removeFirebaseParams = useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        const firebaseParams = ['mode', 'oobCode', 'apiKey', 'lang'];

        let paramsChanged = false;
        firebaseParams.forEach((param) => {
            if (queryParams.has(param)) {
                queryParams.delete(param);
                paramsChanged = true;
            }
        });

        if (paramsChanged) {
            const newPath = location.pathname + '?' + queryParams.toString();
            navigate(newPath);
        }
    }, [location, navigate]);

    return {
        removeFirebaseParams,
    };
};

export default useRemoveFirebaseParams;
