import { DialogName } from '@/lib/types';

import CandidateDetailsDialogProvider from './candidateDetails/CandidateDetailsDialogProvider';
import DialogsContextProvider from './dialogsContext';
import EventDetailsDialogProvider from './eventDetails/EventDetailsDialogProvider';
import InterviewDidntHappenDialogProvider from './interviewDidntHappen/InterviewDidntHappenDialogProvider';
import MoveProcessForwardDialogProvider from './moveProcessForward/MoveProcessForwardDialogProvider';
import AvailabilityFormDialogProvider from './recruiterAvailability/AvailabilityFormDialogProvider';
import RejectCandidateDialogProvider from './rejectCandidate/RejectCandidateDialogProvider';
import RescheduleEventConfirmDialogProvider from './reschduleEventConfirm/RescheduleEventConfirmDialogProvider';
import TerminateProcessDialogProvider from './terminateProcess/TerminateProcessDialogProvider';
import WeeklyAvMissingDialogProvider from './weeklyAvailabilityMissing/WeeklyAvMissingDialogProvider';

const dialogsOrder = [
    DialogName.RecruiterAvailability,
    DialogName.CandidateDetails,
    DialogName.WeeklyAvailabilityMissing,
    DialogName.InterviewDidntHappen,
    DialogName.RescheduleEventConfirm,
    DialogName.RejectCandidateConfirm,
    DialogName.TerminateProcess,
    DialogName.MoveProcessForward,
    DialogName.EventDetails,
];

function DialogsProvider({ children }: { children: React.ReactNode }) {
    return (
        <DialogsContextProvider>
            {dialogsOrder.map((dialog) => (
                <div key={dialog}>
                    {dialog === DialogName.RecruiterAvailability && (
                        <AvailabilityFormDialogProvider />
                    )}
                    {dialog === DialogName.RejectCandidateConfirm && (
                        <RejectCandidateDialogProvider />
                    )}
                    {dialog === DialogName.WeeklyAvailabilityMissing && (
                        <WeeklyAvMissingDialogProvider />
                    )}
                    {dialog === DialogName.InterviewDidntHappen && (
                        <InterviewDidntHappenDialogProvider />
                    )}
                    {dialog === DialogName.RescheduleEventConfirm && (
                        <RescheduleEventConfirmDialogProvider />
                    )}
                    {dialog === DialogName.TerminateProcess && (
                        <TerminateProcessDialogProvider />
                    )}
                    {dialog === DialogName.MoveProcessForward && (
                        <MoveProcessForwardDialogProvider />
                    )}
                    {dialog === DialogName.EventDetails && (
                        <EventDetailsDialogProvider />
                    )}
                    {dialog === DialogName.CandidateDetails && (
                        <CandidateDetailsDialogProvider />
                    )}
                </div>
            ))}
            {children}
        </DialogsContextProvider>
    );
}

export default DialogsProvider;
