import { ArrowDown } from '@/components/svgs';
import { cn } from '@/lib/utils';

import { Button } from './base';
import { SelectionButtonProps } from './types';

function SelectionButton({
    label,
    open,
    className,
    ...buttonProps
}: SelectionButtonProps) {
    return (
        <Button
            className={cn(
                'group/selected-button',
                {
                    'pointer-events-none bg-primary': open,
                },
                className,
            )}
            variant={'outlinedAction'}
            size={'smThin'}
            allowPropagation
            {...buttonProps}
        >
            <div
                className={cn('flex items-center h-[18px]', {
                    'text-white': open,
                })}
            >
                <div>{label}</div>
                <ArrowDown
                    className={cn(
                        'fill-black stroke-black h-2 ml-4',
                        'group-hover/selected-button:fill-white group-hover/selected-button:stroke-white',
                        { 'fill-white stroke-white': open },
                    )}
                />
            </div>
        </Button>
    );
}

export default SelectionButton;
