import { setDialogOpen } from '@/_reducers/dialogs';
import { requestRescheduleEvent } from '@/_reducers/events';
import { fetchProcessesByIds } from '@/_reducers/processes';
import {
    IsRescheduleEventConfirmDialogOpen,
    RescheduleEventConfirmDialogData,
} from '@/_selectors';
import { DialogName } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import RescheduleEventConfirmDialog from './RescheduleEventConfirmDialog';

function RescheduleEventConfirmDialogProvider({
    setDialogOpenAction,
    requestRescheduleEvent,
    fetchProcessesByIds,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    requestRescheduleEvent: (eventId: string) => Promise<void>;
    fetchProcessesByIds: (ids: string[]) => void;
}) {
    const shouldBeOpen = useSelector(IsRescheduleEventConfirmDialogOpen);
    const { process, event } = useSelector(RescheduleEventConfirmDialogData);
    const hasData = !!process && !!event;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.RescheduleEventConfirm, open);
    };

    const handleClose = () => setOpen(false);

    const reschedule = async () => {
        trackEvent(MixpanelEvent.ConfirmRescheduleEvent);
        if (!event) return;
        await requestRescheduleEvent(event.id).then(() => {
            fetchProcessesByIds([event.processId]);
        });
    };

    return (
        <>
            {hasData && (
                <RescheduleEventConfirmDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    event={event}
                    reschedule={reschedule}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    requestRescheduleEvent,
    fetchProcessesByIds,
};

export default connect(null, mapDispathToProps)(RescheduleEventConfirmDialogProvider);
