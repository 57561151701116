import { useAppDispatch } from '@/_helpers/store';
import { FetcherName, fetchEventsById } from '@/_reducers/events';
import { EventIdsToFetch, MissingForProcessesEventsFetcherData } from '@/_selectors';
import { FetchModelStatus } from '@/lib/types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useMarkMissingEventIds from './useMarkMissingEventIds';
import useMemoFlatObject from './useMemoFlatObject';

function useMissingEventsFetcher() {
    useMarkMissingEventIds();
    const { status, isLoading } = useSelector(MissingForProcessesEventsFetcherData);
    const idsToFetch = useSelector(EventIdsToFetch);
    const dispatch = useAppDispatch();

    const memoizedIdsToFetch = useMemoFlatObject(idsToFetch);

    useEffect(() => {
        if (memoizedIdsToFetch.length > 0) {
            dispatch(
                fetchEventsById(memoizedIdsToFetch, FetcherName.MissingForProcesses),
            );
        }
    }, [memoizedIdsToFetch, dispatch]);
    return {
        isLoading,
        isMissingEvents: idsToFetch.length > 0,
        didInitialFetch: ![FetchModelStatus.IDLE, FetchModelStatus.LOADING].includes(
            status,
        ),
    };
}

export default useMissingEventsFetcher;
