import { VIcon, XIcon } from '@/components/svgs';
import { cn, keysOf } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';
import { Fragment } from 'react';

import DetailsCard from './DetailsCard';
import NoData from './NoData';
import { AdditionalAttributesTileProps } from './types';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

enum AdditionalAttribute {
    Car = 'hasCar',
    DrivingLicense = 'hasDrivingLicense',
}

function Attribute({ type, value }: { type: AdditionalAttribute; value: boolean }) {
    return (
        <div className={cn('flex items-center justify-evenly')}>
            <div className={cn('h-auto flex items-center')}>
                {value ? (
                    <VIcon className={cn('w-5 h-5')} />
                ) : (
                    <XIcon className={cn('w-4 h-4')} />
                )}
                <p className={cn('ml-3 font-semibold text-13')}>
                    {strings.smallAdditionalAttributes[type][value ? 'yes' : 'no']}
                </p>
            </div>
        </div>
    );
}

function CandidateAdditionalAttributesSmall({
    process: p,
    cardProps,
}: AdditionalAttributesTileProps) {
    const { hasCar, hasDrivingLicense } = p.candidate;
    const isEmpty = hasCar === null && hasDrivingLicense === null;

    const attrs = {
        ...(hasCar != null ? { [AdditionalAttribute.Car]: hasCar } : {}),
        ...(hasDrivingLicense != null
            ? { [AdditionalAttribute.DrivingLicense]: hasDrivingLicense }
            : {}),
    };
    return (
        <DetailsCard {...cardProps} title={strings.additionalAttributesTitle}>
            <div className={cn('flex items-center justify-evenly gap-3 p-6 lg:p-4')}>
                {isEmpty && <NoData />}
                {keysOf(attrs).map((attrType, index) => (
                    <Fragment key={attrType}>
                        {index !== 0 && (
                            <div className={cn('w-0 h-6 border-r border-neutral200')} />
                        )}
                        <Attribute type={attrType} value={!!attrs[attrType]} />
                    </Fragment>
                ))}
            </div>
        </DetailsCard>
    );
}

export default CandidateAdditionalAttributesSmall;
