import { oneLineFacilities, oneLineShifts, professionWithExp } from '@/strings/common';

export default {
    newRequestBlock: {
        professionWithExp,
        shifts: oneLineShifts,
        facilities: oneLineFacilities,
    },
    mobile: {
        goToCandidates: 'View All',
    },
    goToCandidates: 'View details',
    title: {
        func: (count: number | null) =>
            `${count !== null ? count + ' ' : ''}New candidates`,
        example: [11],
    },
    noCandidatesTitle: 'New candidates',
    noCandidatesMsg: 'No new candidates',
};
