import { CandidateEvent, Process, Profession } from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import ConfirmationDialog from '../confirmation/ConfirmationDialog';
import { BaseDialogPropsCommon } from '../types';

const strings = dialogsStrings.rescheduleEventConfirm;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    event: CandidateEvent;
    reschedule: () => Promise<void>;
}

function RescheduleEventConfirmDialog({
    process,
    event,
    reschedule,
    ...dialogProps
}: Props) {
    const { candidate } = process;
    const { profession } = candidate;

    return (
        <ConfirmationDialog
            {...dialogProps}
            header={
                <CandidateTitle
                    process={process}
                    subtitle={strings.professionToName[profession as Profession]}
                />
            }
            content={{
                title: strings.title,
                description: strings.description.func(
                    getFirstName(candidate),
                    event.eventType,
                    event.startAt,
                ),
                cancel: {
                    label: strings.cancelButton,
                    action: dialogProps.handleClose,
                },
                confirm: {
                    label: strings.rescheduleButton,
                    action: reschedule,
                    failureTitle: strings.reschedule.failureTitle,
                },
            }}
            successContent={{
                title: strings.successStep.title,
                description: strings.successStep.description.func(
                    getFirstName(candidate),
                ),
            }}
        />
    );
}

export default RescheduleEventConfirmDialog;
