import { DialogName } from '@/lib/types/dialogs';
import { createSelector } from 'reselect';

import { DialogsDataState } from '../generation_1';
import { EventsWithProcesses } from '../generation_2';

export const EventDetailsDialogData = createSelector(
    DialogsDataState,
    EventsWithProcesses,
    (dataState, eventsById) => {
        const { eventId } = dataState[DialogName.EventDetails] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        return { event };
    },
);
