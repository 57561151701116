export function isFirefox() {
    return (
        typeof window !== 'undefined' &&
        /Firefox\/([0-9.]+)/.test(window.navigator.userAgent)
    );
}

export function isSafari() {
    return (
        typeof window !== 'undefined' &&
        /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
    );
}
