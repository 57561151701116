import { cn } from '@/lib/utils';
import { useCallback, useState } from 'react';

import { Table, TableBody, TableHead, TableHeader, TableRow } from './base';
import ExpandableRow from './expandableRow';
import Row from './tableRow';
import { Model, TableProps } from './types';

const CustomTable = ({
    rows,
    cells,
    cellClass,
    getExpandContent,
    className,
    onExpand,
}: TableProps<Model>) => {
    const isExpandableRows = !!getExpandContent;
    const [selectedRow, setSelectedRow] = useState<string | null>();
    const onRowClick = useCallback(
        (rowId: string, isExpanded: boolean) => {
            if (isExpanded) {
                setSelectedRow('');
            } else {
                onExpand && onExpand(rowId);
                setSelectedRow(rowId);
            }
        },
        [setSelectedRow, onExpand],
    );

    return (
        <Table className={cn('bg-white', className)}>
            <TableHeader>
                <TableRow>
                    {cells.map((cell) => (
                        <TableHead key={cell.id} className={cn(cell.classes?.header)}>
                            <div className='text-12'>{cell.header}</div>
                        </TableHead>
                    ))}
                    {isExpandableRows && <TableHead className={cn('w-4')} />}
                </TableRow>
            </TableHeader>
            <TableBody>
                {rows.map((row) =>
                    isExpandableRows ? (
                        <ExpandableRow
                            key={row.id}
                            row={row}
                            cells={cells}
                            expanded={selectedRow === row.id}
                            onClick={onRowClick}
                            getExpandContent={getExpandContent}
                            cellClass={cellClass}
                        />
                    ) : (
                        <Row
                            key={row.id}
                            row={row}
                            cells={cells}
                            cellClass={cellClass}
                        />
                    ),
                )}
            </TableBody>
        </Table>
    );
};

export default CustomTable;
