import CandidatesTopRow from '../TopRow';
import { InProcessTopRowProps } from '../types';
import InProcessFilterView from './InProcessFilterView';

function InProcessTopRow({
    filter,
    setFilter,
    models,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
}: InProcessTopRowProps) {
    return (
        <CandidatesTopRow
            models={models}
            filterView={
                <InProcessFilterView
                    filter={filter}
                    setFilter={setFilter}
                    models={models}
                />
            }
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
        />
    );
}

export default InProcessTopRow;
