import { TerminationLottie } from '@/components/lotties';
import { Process } from '@/lib/types';
import { cn, getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.terminateProcess.successStep;

export function TerminationSuccessContent({ process }: { process: Process }) {
    return (
        <div
            className={cn(
                'relative flex flex-col items-center text-center pt-6 pb-[90px] px-12 xs:p-10 xs:pt-8 xs:min-h-[390px]',
            )}
        >
            <TerminationLottie className={cn('h-[270px] w-fit xs:w-[200px] xs:h-fit')} />
            <div className={cn('max-w-[450px] whitespace-pre-line')}>
                <p className={cn('mt-10 text-20 font-bold')}>
                    {strings.title.func(getFirstName(process.candidate))}
                </p>
                <p className={cn('mt-4 text-14 font-medium xs:text-15')}>
                    {strings.description}
                </p>
            </div>
        </div>
    );
}

export default TerminationSuccessContent;
