import { getCurrentPageId } from '@/lib/utils';
import { useLocation } from 'react-router-dom';

function useCurrentPageId() {
    const location = useLocation();
    const pageId = getCurrentPageId(location.pathname);
    return pageId;
}

export default useCurrentPageId;
