import { EventType, RecruiterTerminationReason } from '@/lib/api/v1';

const eventTypeToName: Record<EventType, string> = {
    [EventType.PhoneInterview]: 'Phone interview',
    [EventType.Orientation]: 'Orientation',
    [EventType.InPersonInterview]: 'In-person interview',
};

const terminationReasonsToStrings: Record<RecruiterTerminationReason, string> = {
    [RecruiterTerminationReason.Ghosting]: 'Unresponsive',
    [RecruiterTerminationReason.Commute]: 'Commute',
    [RecruiterTerminationReason.PayRate]: 'Pay rate',
    [RecruiterTerminationReason.LackOfQualifications]:
        'Lacks professional qualifications',
    [RecruiterTerminationReason.NoOpenRoles]: 'No suitable open roles',
    [RecruiterTerminationReason.Other]: 'Other',
};

export { eventTypeToName, terminationReasonsToStrings };
