import { format } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { genericToasts, professionToName } from '../common';

function formatPhoneNumberWithCode(phoneNumberString: string) {
    const phoneNumber = parsePhoneNumberFromString(phoneNumberString);
    if (!phoneNumber) return null;
    return `+${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
}

const strings = {
    timeLabel: 'Date & Time',
    dateAndDuration: {
        func: (startTime: Date, endTime: Date) =>
            `${format(startTime, 'eee MMM d, hh:mm a')} - ${format(endTime, 'hh:mm a')}`,
        example: [new Date('2022-01-01T12:00:00Z'), new Date('2022-01-01T13:00:00Z')],
    },
    phoneLabel: {
        func: (firstName: string) => `${firstName}'s Phone`,
        example: ['[David]'],
    },
    phoneNumber: {
        func: (phone: string) => formatPhoneNumberWithCode(phone) ?? phone,
        example: ['+15082395954'],
    },
    facilityLabel: 'Facility',
    phone_interview: {
        title: 'Phone Interview',
        actions: {
            reschedule: 'Reschedule interview',
            cancel: 'Cancel interview',
        },
    },
    in_person_interview: {
        title: 'In-Person Interview',
        actions: {
            reschedule: 'Reschedule interview',
            cancel: 'Cancel interview',
        },
    },
    orientation: {
        title: 'Orientation',
        actions: {
            reschedule: 'Reschedule orientation',
            cancel: 'Cancel orientation',
        },
    },
    cancelEvent: {
        phone_interview: {
            title: {
                func: (firstName: string) => `Cancel phone interview with ${firstName}`,
                example: ['[David]'],
            },
            description: 'What would you like to do next?',
            terminateButton: 'Cancel & reject candidate',
            rescheduleButton: 'Reschedule interview',
        },
        in_person_interview: {
            title: {
                func: (firstName: string) =>
                    `Cancel in-person interview with ${firstName}`,
                example: ['[David]'],
            },
            description: 'What would you like to do next?',
            terminateButton: 'Cancel & reject candidate',
            rescheduleButton: 'Reschedule interview',
        },
        orientation: {
            title: {
                func: (firstName: string) => `Cancel orientation with ${firstName}`,
                example: ['[David]'],
            },
            description: 'What would you like to do next?',
            terminateButton: 'Cancel & reject candidate',
            rescheduleButton: 'Reschedule orientation',
        },
    },
    reschedule: {
        successStep: {
            title: "Great, we're on it!",
            description: {
                func: (firstName: string) =>
                    `We'll reach out to ${firstName} to reschedule this event.`,
                example: ['[David]'],
            },
        },
        ...genericToasts.failure,
    },
    candidateSubtitle: {
        ...professionToName,
    },
};

export default strings;
