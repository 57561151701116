import { Logo } from '@/components/svgs';
import { CustomPopover } from '@/components/ui';
import { useIsMediumScreen, useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';
import UserProfileCircle from '@/sidebar/UserProfileCircle';
import UserMenu from '@/sidebar/userMenu';

function HomepageHeader() {
    const isMobile = useIsMobileScreen();
    const isMediumScreen = useIsMediumScreen();
    return (
        <div
            className={cn(
                'relative px-8 py-4 bg-white w-full flex items-center justify-between gap-4',
                'border-b border-neutral200',
                { 'min-h-20 justify-start gap-8': isMediumScreen },
            )}
        >
            <Logo className={cn({ hidden: isMediumScreen })} />
            {isMobile && (
                <CustomPopover
                    trigger={<UserProfileCircle />}
                    contentProps={{ className: cn('rounded-xl') }}
                >
                    <UserMenu />
                </CustomPopover>
            )}
        </div>
    );
}

export default HomepageHeader;
