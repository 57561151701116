import { ReactNode } from 'react';

import { BaseDialogPropsCommon } from '../types';

export enum Step {
    Init = 'init',
    Success = 'success',
}

export type CancelButtonProps = {
    action: () => void;
    label: string;
};

export type AwaitableConfirmButtonProps = {
    action: () => Promise<void>;
    label: string;
    failureTitle: string;
    isImmediate?: false;
};

export type ImmediateConfirmButtonProps = {
    action: () => void;
    label: string;
    isImmediate: true;
};

export type ConfirmButtonProps =
    | AwaitableConfirmButtonProps
    | ImmediateConfirmButtonProps;

export type ConfirmationContentPropsBase = {
    title: string;
    description: string;
    cancel: CancelButtonProps;
    confirm: ConfirmButtonProps;
};
export type ConfirmationDialogContentProps = ConfirmationContentPropsBase & {
    confirmCallback?: () => void;
};

export type SuccessContentProps = {
    title: string;
    description: string;
};

export type ConfirmationDialogProps = BaseDialogPropsCommon & {
    content: ConfirmationContentPropsBase;
    successContent?: SuccessContentProps;
    header: ReactNode;
};
