import { useEffect } from 'react';

function useMutationObserver(
    ref: React.RefObject<HTMLElement>,
    callback: MutationCallback,
    options: MutationObserverInit = {
        characterData: true,
        childList: true,
        subtree: true,
        attributes: true,
        attributeOldValue: true,
        characterDataOldValue: true,
    },
) {
    useEffect(() => {
        if (ref.current && ref.current.firstChild) {
            const observer = new MutationObserver(callback);
            observer.observe(ref.current, options);
            return () => observer.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export default useMutationObserver;
