import { AlertIcon, VIcon, XIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Viewport>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Viewport
        ref={ref}
        className={cn(
            'fixed z-[100] top-0 right-0 bottom-auto flex flex-col-reverse space-y-reverse space-y-4 max-h-screen',
            'pl-1 pb-1 pt-10 pr-10',
            className,
        )}
        {...props}
    />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
    cn(
        'group pointer-events-auto relative flex items-start overflow-hidden',
        'rounded-md border border-slate-200 transition-all dark:border-slate-800',
        'data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out',
        'data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full',
        'shadow-elevation-300 p-8 bg-white rounded-4xl',
    ),
    {
        variants: {
            variant: {
                default: cn('bg-white'),
                destructive: 'bg-white',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

const Toast = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Root>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
        VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
    return (
        <ToastPrimitives.Root
            ref={ref}
            className={cn(toastVariants({ variant }), className)}
            {...props}
        />
    );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Action>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Action
        ref={ref}
        className={cn(
            'inline-flex shrink-0 items-center justify-center rounded-2xl mr-7 text-sm font-medium transition-colors hover:bg-slate-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-slate-100/40 group-[.destructive]:hover:border-red-500/30 group-[.destructive]:hover:bg-red-500 group-[.destructive]:hover:text-slate-50 dark:border-slate-800 dark:hover:bg-slate-800 dark:group-[.destructive]:border-slate-800/40 dark:group-[.destructive]:hover:border-red-900/30 dark:group-[.destructive]:hover:bg-red-900 dark:group-[.destructive]:hover:text-slate-50',
            className,
        )}
        {...props}
    />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Close>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Close
        ref={ref}
        className={cn('p-1 m-4', className)}
        toast-close=''
        {...props}
    >
        <XIcon />
    </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Title>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Title
        ref={ref}
        className={cn('text-15 font-bold text-black', className)}
        {...props}
    />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastThumbnail = ({
    className,
    icon,
    variant = 'default',
    ...props
}: React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof toastVariants> & {
        icon?: React.ReactNode;
    }) => {
    return (
        <div
            className={cn(
                'h-[50px] w-[50px] inline-flex items-center justify-center rounded-2xl mr-7',
                {
                    'bg-gray50': variant === 'default',
                    'bg-red50': variant === 'destructive',
                },
                className,
            )}
            {...props}
        >
            {icon}
            {!icon && (
                <>
                    {variant === 'default' && <VIcon className={cn('h-6 w-10')} />}
                    {variant === 'destructive' && <AlertIcon />}
                </>
            )}
        </div>
    );
};
ToastThumbnail.displayName = 'ToastThumbnail';

const ToastDescription = React.forwardRef<
    React.ElementRef<typeof ToastPrimitives.Description>,
    React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
    <ToastPrimitives.Description
        ref={ref}
        className={cn('text-12 font-medium mt-1 text-black whitespace-pre', className)}
        {...props}
    />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export type ToastThumbnailProps = React.ComponentProps<typeof ToastThumbnail>;
export type ToastDescriptionProps = React.ComponentProps<typeof ToastDescription>;
export type ToastTitleProps = React.ComponentProps<typeof ToastTitle>;
export type ToastCloseProps = React.ComponentProps<typeof ToastClose>;

export {
    type ToastProps,
    type ToastActionElement,
    ToastThumbnail,
    ToastProvider,
    ToastViewport,
    Toast,
    ToastTitle,
    ToastDescription,
    ToastClose,
    ToastAction,
};
