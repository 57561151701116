import { Table } from '@/components/ui';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as strings } from '@/strings';
import { keyBy } from 'lodash';

import ProcessActionsView from './ActionsView';
import ExpandedCandidate from './ExpandedCandidate';
import FacilitiesCell from './FacilitiesCell';
import NameAndRoleCell from './NameAndRoleCell';
import NewRequestsHeader from './NewRequestsHeader';

const tableStrings = strings.newRequests.candidatesTable;

function NewCandidatesTable({
    models,
    totalNewCandidates,
}: {
    models: Process[];
    totalNewCandidates: number;
}) {
    const modelsById = keyBy(models, 'id');
    const cells = [
        {
            id: 'new',
            header: <NewRequestsHeader count={totalNewCandidates} />,
            getter: (p: Process, expanded: boolean) => (
                <NameAndRoleCell candidate={p.candidate} overflownVisible={expanded} />
            ),
            classes: {
                header: cn('min-w-[352px] w-[352px]', 'lg:min-w-[242px] lg:w-[242px]'),
                cell: cn('max-w-[352px]', 'lg:max-w-[242px] lg:w-[242px]'),
            },
        },
        {
            id: 'Role',
            header: tableStrings.headerRole,
            getter: (p: Process) =>
                tableStrings.cellRole.func(
                    p.candidate.profession,
                    p.candidate.yearsOfExperience,
                ),
            hideOnExpand: true,
            classes: {
                header: cn('min-w-[180px] w-[180px]', 'lg:min-w-[150px] lg:w-[150px]'),
            },
        },
        {
            id: 'Shifts',
            header: tableStrings.headerShifts,
            getter: (p: Process) => tableStrings.cellShifts.func(p.candidate.shifts),
            hideOnExpand: true,
            classes: {
                header: cn('min-w-[190px] w-[190px]', 'lg:min-w-[150px] lg:w-[150px]'),
            },
        },
        {
            id: 'Salary',
            header: tableStrings.headerSalary,
            getter: (p: Process) => (
                <div className={cn('w-30')}>
                    {p.candidate.hourlySalaryFlexible
                        ? tableStrings.flexibleSalary
                        : p.candidate.hourlySalary
                          ? tableStrings.cellSalary.func(p.candidate.hourlySalary)
                          : null}
                </div>
            ),
            hideOnExpand: true,
            classes: {
                header: cn('min-w-[150px] w-[150px]', 'lg:min-w-[150px] lg:w-[150px]'),
            },
        },
        {
            id: 'Facilities',
            header: (
                <div className={cn('absolute top-1/2 -translate-y-1/2')}>
                    {tableStrings.headerFacilities}
                </div>
            ),
            getter: (p: Process) => <FacilitiesCell process={p} />,
            hideOnExpand: true,
            classes: {
                header: cn('min-w-[60px] max-w-[220px] w-fill'),
                cell: cn('max-w-[220px]', 'lg:max-w-[60px]'),
            },
        },
        {
            id: 'Actions',
            header: '',
            classes: { header: cn('p-0 w-0') },
            getter: (p: Process, expanded: boolean, hovering: boolean) => (
                <ProcessActionsView
                    process={p}
                    rowExpanded={expanded}
                    rowOnHover={hovering}
                />
            ),
        },
    ];
    const onExpand = (modelId: string) => {
        const process = modelsById[modelId];
        trackEvent(MixpanelEvent.ExpandNewCandidate, {
            candidate_id: process?.candidate.id,
            candidate_name: process?.candidate.name,
            via: 'new_candidates_table',
        });
    };

    return (
        <Table
            rows={models}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cells={cells as any}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getExpandContent={(p: any, expanded: boolean) => (
                <ExpandedCandidate process={p} expanded={expanded} />
            )}
            onExpand={onExpand}
            className={cn('max-h-full h-full')}
            cellClass={cn('lg:pt-8 lg:pb-2')}
        />
    );
}

export default NewCandidatesTable;
