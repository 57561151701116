export type RecordKey = number | string | symbol;
export type EnumRecord<EnumT extends RecordKey, ValueT> = Partial<Record<EnumT, ValueT>>;

export type AnyFunc<M> = (...args: M[]) => M;

export type EmptyFunc = () => void;

export enum Env {
    Prod = 'production',
    Staging = 'staging',
    Dev = 'development',
}
