import store from '@/_helpers/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';

import App from './App.tsx';
import './index.css';
import SentryController from './sentryController';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <SentryController>
            <StoreProvider store={store}>
                <App />
            </StoreProvider>
        </SentryController>
    </React.StrictMode>,
);
