import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { TimeInterval } from '@/lib/types';
import { generateNextTimeInterval, sortIntervals } from '@/lib/utils';
import { maxBy } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import DayAvailabilityView from './DayAvailabilityView';
import DayAvailabilityViewMobile from './DayAvailabilityViewMobile';
import { AvailabilityFormSchema, DayAvailabilityProps } from './types';
import { dayOfWeekToKey } from './utils';

function DayAvailability({ day }: DayAvailabilityProps) {
    const isMobileScreen = useIsMobileScreen();
    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext<AvailabilityFormSchema>();
    const dayKey = dayOfWeekToKey(day);
    const availability = useWatch({ control, name: dayKey });
    const relevantErrors = errors[dayKey];
    const isEmpty = availability.length === 0;

    const onIntervalChange = (index: number, value: TimeInterval) => {
        const newAv = [
            ...availability.slice(0, index),
            value,
            ...availability.slice(index + 1),
        ];
        setValue(dayKey, newAv.sort(sortIntervals), { shouldValidate: true });
    };
    const onIntervalDelete = (index: number) => {
        const newAv = [
            ...availability.slice(0, index),
            ...availability.slice(index + 1),
        ];
        setValue(dayKey, newAv, { shouldValidate: true });
    };

    const onIntervalAdd = () => {
        const latestEndTime = maxBy(availability, 'end')?.end;
        const newInterval = generateNextTimeInterval(latestEndTime);
        setValue(dayKey, [...availability, newInterval].sort(sortIntervals), {
            shouldValidate: true,
        });
    };

    return isMobileScreen ? (
        <DayAvailabilityViewMobile
            {...{
                day,
                onIntervalAdd,
                onIntervalChange,
                onIntervalDelete,
                isEmpty,
                relevantErrors,
                control,
            }}
        />
    ) : (
        <DayAvailabilityView
            {...{
                day,
                onIntervalAdd,
                onIntervalChange,
                onIntervalDelete,
                isEmpty,
                relevantErrors,
                control,
            }}
        />
    );
}

export default DayAvailability;
