import AuthController from '@/auth/AuthController';
import DialogsProvider from '@/dialogs/dialogsProvider';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import MixpanelController from '@/mixpanel/MixpanelController';
import MixpanelPageTracker from '@/mixpanel/MixpanelPageTracker';
import { BrowserRouter as Router } from 'react-router-dom';

import AppLayout from './AppLayout';
import AppLayoutMobile from './AppLayoutMobile';

function App() {
    const isMobileScreen = useIsMobileScreen();
    return (
        <Router>
            <AuthController>
                <MixpanelController>
                    <MixpanelPageTracker />
                    <DialogsProvider>
                        {isMobileScreen && <AppLayoutMobile />}
                        {!isMobileScreen && <AppLayout />}
                    </DialogsProvider>
                </MixpanelController>
            </AuthController>
        </Router>
    );
}
export default App;
