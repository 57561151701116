import { DayOfWeek } from '@/lib/api/v1';

import { TimeInterval } from './time';

export type AvailabilityRecord = TimeInterval[];

export type AvailabilityRecordByDay = Record<DayOfWeek, AvailabilityRecord>;

export enum DayAvError {
    MaxMinIssue = 'maxMinIssue',
    Overlapping = 'overlapping',
}
