import { approveCandidate } from '@/_reducers/processes';
import { initRecruiterAvailability } from '@/_reducers/recruiterAvailability';
import { useAvailabilityDialogData } from '@/dialogs/hooks';
import useFetchers from '@/hooks/useFetchers';
import useOpenDialog from '@/hooks/useOpenDialog';
import { ProcessAction } from '@/lib/api/v1';
import { PatchProcessAction } from '@/lib/types';
import { DialogName } from '@/lib/types/dialogs';
import { connect } from 'react-redux';

import NewRequestActionsView from './NewRequestActionsView';
import { NewRequestsActionViewAdditionalProps } from './types';

function NewRequestActionsViewProvider({
    approveCandidate,
    process,
    ...viewProps
}: {
    approveCandidate: PatchProcessAction;
} & NewRequestsActionViewAdditionalProps) {
    useFetchers(initRecruiterAvailability);
    const { openDialog: openWeeklyAvailabilityMissingDialog } = useOpenDialog(
        DialogName.WeeklyAvailabilityMissing,
    );
    const { openDialog: openRejectConfirmationDialog } = useOpenDialog(
        DialogName.RejectCandidateConfirm,
    );
    const { setData: setAvailabilityDialogData } = useAvailabilityDialogData();
    const canApprove = process.actions.includes(ProcessAction.RecruiterApproval);
    const canReject = process.actions.includes(ProcessAction.TerminateProcess);
    return (
        <NewRequestActionsView
            {...viewProps}
            process={process}
            approveButtonProps={{
                ...viewProps?.approveButtonProps,
                show: canApprove,
            }}
            rejectButtonProps={{
                ...viewProps?.rejectButtonProps,
                show: canReject,
            }}
            openWeeklyAvailabilityMissingDialog={() =>
                openWeeklyAvailabilityMissingDialog(undefined)
            }
            openRejectConfirmationDialog={(processId: string) =>
                openRejectConfirmationDialog({ processId })
            }
            setAvailabilityDialogCallback={(callback) =>
                setAvailabilityDialogData({ callback })
            }
            approveCandidate={approveCandidate}
        />
    );
}

const mapDispathToProps = {
    approveCandidate,
};

export default connect(null, mapDispathToProps)(NewRequestActionsViewProvider);
