import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import NewCandidateBlockMobile from './NewCandidateBlockMobile';

function NewCandidatesMobile({ models }: { models: Process[] }) {
    return (
        <div
            className={cn('w-full h-full relative flex flex-wrap justify-center gap-6')}
        >
            {models.map((model) => (
                <NewCandidateBlockMobile key={model.id} process={model} />
            ))}
        </div>
    );
}

export default NewCandidatesMobile;
