import { FetchModelStatus, FetcherState, FetcherStateData } from '@/lib/types';

export function serializeFetcherState(state?: FetcherState): FetcherStateData {
    state = state ?? generateEmptyFetcherState();
    const { status, initiated, error } = state;
    return {
        isLoading: status === FetchModelStatus.LOADING,
        initiated,
        status,
        isError: !!error,
        error,
    };
}

export function generateEmptyFetcherState(): FetcherState {
    return {
        initiated: false,
        status: FetchModelStatus.IDLE,
    };
}
