import { Dialog, useDialogContext } from '@/components/ui/dialog';
import { DialogHeaderAnchor } from '@/components/ui/dialog/';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';

import { SelectBase, SelectTrigger, SelectValue } from './base';
import { MobileSelectContentProps, SelectProps } from './types';

function MobileSelectContent<T extends string>({
    options,
    onChange,
    handleClose,
}: MobileSelectContentProps<T>) {
    const { isExpandedToTop } = useDialogContext();
    return (
        <div className={cn({ 'overflow-auto': isExpandedToTop })}>
            {options.map((opt) => (
                <div
                    key={opt.id}
                    onClick={() => {
                        onChange(opt.value);
                        handleClose();
                    }}
                    className={cn(
                        'py-7 px-6 w-full text-13 font-semibold hover:bg-accent300',
                    )}
                >
                    {opt.label}
                </div>
            ))}
        </div>
    );
}

function SelectMobile<T extends string>({
    options,
    value,
    onChange,
    placeholder,
    triggerProps,
    ...baseProps
}: SelectProps<T>) {
    const { handleOpen, handleClose, open, setOpen } = useSetOpen();
    const selectedOption = options.find((opt) => opt.value === value);
    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            triggerProps={{ asChild: true }}
            customHeader={<DialogHeaderAnchor />}
            trigger={
                <div>
                    <SelectBase value={value} onValueChange={onChange} {...baseProps}>
                        <SelectTrigger
                            {...triggerProps}
                            className={cn(
                                'w-[400px] max-w-full',
                                triggerProps?.className,
                            )}
                            onClick={handleOpen}
                        >
                            {selectedOption ? (
                                <div>{selectedOption.label}</div>
                            ) : (
                                <SelectValue placeholder={placeholder} />
                            )}
                        </SelectTrigger>
                    </SelectBase>
                </div>
            }
        >
            <MobileSelectContent
                options={options}
                onChange={onChange}
                handleClose={handleClose}
            />
        </Dialog>
    );
}

export default SelectMobile;
