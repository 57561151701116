import { EventType, ProcessStatus } from '@/lib/api/v1';
import { processStatusToEventTypeMap } from '@/lib/types';

const eventTypeToString: Record<EventType, string> = {
    [EventType.PhoneInterview]: 'phone interview',
    [EventType.InPersonInterview]: 'in-person interview',
    [EventType.Orientation]: 'orientation',
};

const processStatusToEventString = (status: ProcessStatus) => {
    const eventType = processStatusToEventTypeMap[status];
    return eventType ? eventTypeToString[eventType] : undefined;
};

export { eventTypeToString, processStatusToEventString };
