import { AppDispatch, GetRootState, RootState } from '@/_helpers/store';
import { CurrentUser } from '@/_selectors';
import { AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type NoArgAsyncThunk = AsyncThunk<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    undefined,
    { state: RootState; dispatch: AppDispatch }
>;
type NoArgCustomAsyncThunk = () => (
    dispatch: AppDispatch,
    getState: GetRootState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Promise<PayloadAction<any>> | void;

type Fetcher = NoArgCustomAsyncThunk | NoArgAsyncThunk;

function useFetchers(...fetchers: Fetcher[]) {
    const dispatch = useDispatch<AppDispatch>();
    const currentUserId = useSelector(CurrentUser)?.id;

    useEffect(() => {
        if (!currentUserId) {
            return;
        }
        for (const fetcher of fetchers) {
            dispatch(fetcher());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId]);
}

export default useFetchers;
