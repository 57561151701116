import { setDialogOpen } from '@/_reducers/dialogs';
import { putRecruiterAvailability } from '@/_reducers/recruiterAvailability';
import { AvailabilityByDay, IsRecruiterAvailabilityDialogOpen } from '@/_selectors';
import { DialogName, TimeIntervalWithDay } from '@/lib/types';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import AvailabilityFormDialog from './AvailabilityFormDialog';
import useAvailabilityDialogData from './hooks';

function AvailabilityFormDialogProvider({
    putRecruiterAvailability: updateAvailability,
    setDialogOpenAction,
}: {
    putRecruiterAvailability: (intervals: TimeIntervalWithDay[]) => Promise<void>;
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
}) {
    const isOpen = useSelector(IsRecruiterAvailabilityDialogOpen);
    const actualState = useSelector(AvailabilityByDay);
    const { data = {}, setData } = useAvailabilityDialogData();
    const { callback } = data;
    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.RecruiterAvailability, open);
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (!isOpen && callback) {
            setData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <AvailabilityFormDialog
            updateAvailability={updateAvailability}
            actualState={actualState}
            handleClose={handleClose}
            callback={callback}
            isOpen={isOpen}
            setOpen={setOpen}
        />
    );
}

const mapDispathToProps = {
    putRecruiterAvailability,
    setDialogOpenAction: setDialogOpen,
};

export default connect(null, mapDispathToProps)(AvailabilityFormDialogProvider);
