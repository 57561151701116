import { sendProcessNote } from '@/_reducers/processes';
import { Button, LoadingButton, useToast } from '@/components/ui';
import { ProcessSubstatus } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import { DialogFooterProps } from './types';

const strings = dialogsStrings.moveProcessForward;

export function MoveProcessForwardDialogFooter({
    process,
    handleClose,
    updateProcess,
    submitCallback,
    nextStep,
    freeText,
    cancelButtonString,
    submitButtonString,
}: DialogFooterProps) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const submit = async () => {
        trackEvent(MixpanelEvent.MoveForwardCandidate, {
            next_step: nextStep,
        });
        setIsLoading(true);
        let processUpdateFailed = false;
        await updateProcess({
            status: nextStep,
            subStatus: ProcessSubstatus.Pending,
        })
            .then(submitCallback)
            .catch((error) => {
                toast({
                    title: strings.failureToast.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
                setIsLoading(false);
                processUpdateFailed = true;
            });
        if (freeText && !processUpdateFailed) {
            await sendProcessNote({ processId: process.id, text: freeText }).catch(
                (error) => {
                    toast({
                        title: strings.failureToast.failureTitle,
                        description: error.message || error,
                        variant: 'destructive',
                    });
                },
            );
        }
        setIsLoading(false);
    };

    return (
        <div className={cn('w-full flex xs:flex-wrap-reverse gap-6')}>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <Button
                    className={cn('w-full')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {cancelButtonString}
                </Button>
            </div>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <LoadingButton className={cn('w-full')} size={'lg'} onClick={submit}>
                    {submitButtonString}
                </LoadingButton>
            </div>
        </div>
    );
}

export default MoveProcessForwardDialogFooter;
