import { Process } from '@/lib/types';
import { cn, getFacilityNameAndCount } from '@/lib/utils';
import { candidates as strings } from '@/strings';

const tableStrings = strings.newRequests.candidatesTable;

function FacilitiesCell({ process }: { process: Process }) {
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    if (!facilityName) {
        return null;
    }
    return (
        <div
            className={cn(
                'whitespace-nowrap text-ellipsis overflow-hidden w-max max-w-full',
            )}
        >
            {tableStrings.cellFacilities.func(facilityName, facilitiesCount)}
        </div>
    );
}

export default FacilitiesCell;
