import { dayOfWeekOptions } from '@/lib/api/v1';
import { Time } from '@/lib/base';
import {
    AvailabilityRecord,
    AvailabilityRecordByDay,
    DayAvError,
    TimeInterval,
    TimeIntervalWithDay,
} from '@/lib/types';
import { zipObject } from 'lodash';

import { sortIntervals } from './time';
import { keysOfAsNumber } from './typescript';

export const MIN_AVAILABILITY_HOUR = 7;
export const MAX_AVAILABILITY_HOUR = 19;
export const AVAILABILITY_BLOCK_LENGTH = 30; // in minutes
export const DEFAULT_NEW_INTERVAL_LENGTH = 2 * AVAILABILITY_BLOCK_LENGTH;
export const DEFAULT_AVAILABILITY_START = new Time(9);
export const DEFAULT_AVAILABILITY_END = new Time(17);
export const DEFAULT_AVAILABILITY_DAYS = [0, 1, 2, 3, 4];

export function generateDefaultDayAvailability(): AvailabilityRecord {
    return [{ start: DEFAULT_AVAILABILITY_START, end: DEFAULT_AVAILABILITY_END }];
}

export function getErrorsForAvailability(
    availability: AvailabilityRecord,
): Record<number, DayAvError> {
    const sortedIntervals = [...availability].sort(sortIntervals);
    const res = {} as Record<number, DayAvError>;
    let indexOfLatestEndTime = -1;
    for (const index of keysOfAsNumber(sortedIntervals)) {
        const interval = sortedIntervals[index];
        if (!(interval.start < interval.end)) {
            res[index] = DayAvError.MaxMinIssue;
            continue;
        }
        if (
            indexOfLatestEndTime >= 0 &&
            sortedIntervals[indexOfLatestEndTime].end > interval.start
        ) {
            res[index] = DayAvError.Overlapping;
            res[indexOfLatestEndTime] = DayAvError.Overlapping;
        }
        if (
            indexOfLatestEndTime < 0 ||
            sortedIntervals[indexOfLatestEndTime].end < interval.end
        ) {
            indexOfLatestEndTime = index;
        }
    }
    return res;
}

export function generateNextTimeInterval(lastEndTime?: Time): TimeInterval {
    if (!lastEndTime) {
        return generateDefaultDayAvailability()[0];
    }
    const maxAllowedEndTime = new Time(MAX_AVAILABILITY_HOUR);
    if (lastEndTime < maxAllowedEndTime.withMinutesAdd(-DEFAULT_NEW_INTERVAL_LENGTH)) {
        const newStart = lastEndTime.withMinutesAdd(AVAILABILITY_BLOCK_LENGTH);
        return {
            start: newStart,
            end: newStart.withMinutesAdd(DEFAULT_NEW_INTERVAL_LENGTH),
        };
    }
    return {
        start: maxAllowedEndTime.withMinutesAdd(-AVAILABILITY_BLOCK_LENGTH),
        end: maxAllowedEndTime,
    };
}

export function eachTimeBlockOfInterval(start: Time, end: Time): Time[] {
    const blocks = [];
    const startTime =
        Math.ceil(Number(start) / AVAILABILITY_BLOCK_LENGTH) * AVAILABILITY_BLOCK_LENGTH;
    for (
        let time = Time.createFromValue(startTime);
        time <= end;
        time = time.withMinutesAdd(AVAILABILITY_BLOCK_LENGTH)
    ) {
        blocks.push(time);
    }
    return blocks;
}

export function timeIntervalsToAvailabilityRecords(
    ranges: TimeIntervalWithDay[],
): AvailabilityRecordByDay {
    const res = zipObject(
        dayOfWeekOptions,
        dayOfWeekOptions.map(() => [] as TimeInterval[]),
    ) as AvailabilityRecordByDay;
    for (const { start, end, day } of ranges) {
        res[day].push({ start, end });
    }
    return res;
}

export function createIntervalsFromAvailability(
    availabilityByDay: AvailabilityRecordByDay,
): TimeIntervalWithDay[] {
    const res: TimeIntervalWithDay[] = [];
    for (const day of keysOfAsNumber(availabilityByDay)) {
        const intervals = availabilityByDay[day];
        for (const interval of intervals) {
            res.push({ day, ...interval });
        }
    }
    return res;
}
