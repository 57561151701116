import { ProfessionAvatar } from '@/components/ui';
import { Candidate, Profession } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';

function NameLabel({
    name,
    isShown,
    ...props
}: React.HTMLAttributes<HTMLParagraphElement> & { name: string; isShown: boolean }) {
    return (
        <p
            {...props}
            className={cn(
                'text-15 font-semibold max-w-full whitespace-nowrap text-ellipsis opacity-100 transition-opacity duration-300',
                { 'opacity-0': !isShown },
                props.className,
            )}
        >
            {name}
        </p>
    );
}

function ExpandableNameLabel({ name, expaneded }: { name: string; expaneded: boolean }) {
    return (
        <div className='relative'>
            <NameLabel
                name={name}
                isShown={!expaneded}
                className={cn('overflow-hidden', { 'text-ellipsis': !expaneded })}
            />
            <NameLabel
                name={name}
                isShown={expaneded}
                className={cn('overflow-visible absolute left-0 top-0')}
            />
        </div>
    );
}

export function NameAndRoleCell({
    candidate,
    overflownVisible = false,
}: {
    candidate: Candidate;
    overflownVisible?: boolean;
}) {
    const { profession, name } = candidate;
    return (
        <div className={cn('flex items-center w-full')}>
            <ProfessionAvatar
                profession={profession as Profession}
                className={cn('w-[54px] h-[54px] lg:w-14 lg:h-14')}
            />
            <div className={cn('pl-4 w-[calc(100%-54px)]')}>
                <ExpandableNameLabel name={name} expaneded={overflownVisible} />
                <p className={cn('text-12')}>
                    {
                        candidatesStrings.newRequests.professionToName[
                            profession as Profession
                        ]
                    }
                </p>
            </div>
        </div>
    );
}

export default NameAndRoleCell;
