import { PlusIcon } from '@/components/svgs';
import { Checkbox } from '@/components/ui';
import { cn, generateDefaultDayAvailability } from '@/lib/utils';
import { candidates as candidatesStrings } from '@/strings';
import { Controller } from 'react-hook-form';

import ClickableIcon from './ClickableIcon';
import IntervalInput from './IntervalInput';
import UnavailabileIndication from './UnavailableDayIndication';
import { TIME_INTERVAL_ROW_HEIGHT_CLASS } from './common';
import { DayAvailabilityViewProps } from './types';
import { dayOfWeekToKey } from './utils';

const strings = candidatesStrings.newRequests.recruiterAvailability;

function DayAvailabilityViewMobile({
    day,
    control,
    isEmpty,
    onIntervalChange,
    onIntervalDelete,
    onIntervalAdd,
    relevantErrors,
}: DayAvailabilityViewProps) {
    const dayKey = dayOfWeekToKey(day);

    return (
        <div
            className={cn(
                'w-full border-b border-neutral100 px-10 xs:px-6 py-8',
                'flex flex-col items-start gap-6',
                '[&:last-of-type]:border-b-0',
            )}
        >
            <div className={cn('flex items-center h-12')}>
                <Controller
                    name={dayKey}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        const emptyDay = value.length === 0;
                        const onVChange = () => {
                            if (emptyDay) {
                                onChange(generateDefaultDayAvailability());
                            } else {
                                onChange([]);
                            }
                        };
                        return (
                            <Checkbox
                                className={cn('p-[1px]')}
                                onCheckedChange={onVChange}
                                checked={!emptyDay}
                            />
                        );
                    }}
                />
                <p className={cn('font-bold text-15 ml-4')}>
                    {strings.dayLabel.func(day)}
                </p>
            </div>
            <div className={cn('flex items-start gap-8 w-full')}>
                {isEmpty && <UnavailabileIndication />}

                <div className={cn('flex flex-col flex-grow max-w-[300px]')}>
                    <Controller
                        name={dayKey}
                        control={control}
                        render={({ field: { value: availability } }) => (
                            <>
                                {availability.map((value, index) => (
                                    <IntervalInput
                                        key={index}
                                        value={value}
                                        onChange={(value) =>
                                            onIntervalChange(index, value)
                                        }
                                        onDelete={() => onIntervalDelete(index)}
                                        error={
                                            relevantErrors
                                                ? relevantErrors[index]?.message
                                                : undefined
                                        }
                                    />
                                ))}
                            </>
                        )}
                    />
                </div>
                <div
                    className={cn(
                        TIME_INTERVAL_ROW_HEIGHT_CLASS,
                        'flex items-center gap-8',
                        { hidden: isEmpty },
                    )}
                >
                    <ClickableIcon className={cn('')} onClick={onIntervalAdd}>
                        <PlusIcon className={cn('w-4 h-4')} />
                    </ClickableIcon>
                </div>
            </div>
        </div>
    );
}

export default DayAvailabilityViewMobile;
