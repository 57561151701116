import { CalendarIcon, TerminateIcon } from '@/components/svgs';
import { CustomPopover, FalafelButton, PopoverMenu } from '@/components/ui';
import { dialogs as dialogsStrings } from '@/strings';

import { ImplementedEventType } from './types';

const strings = dialogsStrings.eventDetails;

export function EventActions({
    eventType,
    reschedule,
    cancelEvent,
}: {
    eventType: ImplementedEventType;
    reschedule: () => Promise<void>;
    cancelEvent: () => void;
}) {
    return (
        <CustomPopover
            trigger={<FalafelButton />}
            contentProps={{
                variant: 'noPointer',
                align: 'end',
                alignOffset: 0,
                sideOffset: 6,
            }}
        >
            <PopoverMenu
                items={[
                    {
                        id: 'reschedule',
                        content: {
                            label: strings[eventType].actions.reschedule,
                            Icon: CalendarIcon,
                        },
                        onClick: reschedule,
                    },
                    {
                        id: 'cancel',
                        content: {
                            label: strings[eventType].actions.cancel,
                            Icon: TerminateIcon,
                        },
                        onClick: cancelEvent,
                    },
                ]}
            />
        </CustomPopover>
    );
}

export default EventActions;
