import { CarIcon, DrivingLicenseIcon, VIcon, XIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';

import AttributeRow from './AttributeRow';
import DetailsCard from './DetailsCard';
import NoData from './NoData';
import { AdditionalAttributesTileProps } from './types';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function CandidateAdditionalAttributes({
    process: p,
    cardProps,
    attributeVariant,
}: AdditionalAttributesTileProps) {
    const candidateAttributes = [
        {
            attribute: p.candidate.hasDrivingLicense,
            label: strings.hasDrivingLicenseLabel,
            icon: <DrivingLicenseIcon />,
            yesAnswer: strings.simpleAttributionAnswerYes,
            noAnswer: strings.simpleAttributionAnswerNo,
        },
        {
            attribute: p.candidate.hasCar,
            label: strings.ownsCarLabel,
            icon: <CarIcon />,
            yesAnswer: strings.simpleAttributionAnswerYes,
            noAnswer: strings.simpleAttributionAnswerNo,
        },
    ].filter((attr) => attr.attribute != null && attr.attribute !== undefined);

    return (
        <DetailsCard title={strings.additionalAttributesTitle} {...cardProps}>
            <div className={cn('py-2 xs:p-0')}>
                {candidateAttributes.length > 0 ? (
                    candidateAttributes.map((attr) => (
                        <AttributeRow
                            key={attr.label}
                            label={{
                                text: attr.label,
                                icon: attr.icon,
                            }}
                            value={{
                                text: attr.attribute ? attr.yesAnswer : attr.noAnswer,
                                icon: attr.attribute ? (
                                    <VIcon className={cn('ml-1')} />
                                ) : (
                                    <XIcon className={cn('ml-1 w-[8px]')} />
                                ),
                            }}
                            variant={attributeVariant}
                        />
                    ))
                ) : (
                    <NoData className='py-6 px-8' />
                )}
            </div>
        </DetailsCard>
    );
}

export default CandidateAdditionalAttributes;
