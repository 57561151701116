import { Toaster } from '@/components/ui';
import ImpersonationController from '@/impersonation/ImpersonationController';
import { cn } from '@/lib/utils';
import MainRoutes from '@/routes/mainRoutes';
import FooterMenu from '@/sidebar/footerMenu';

function AppLayoutMobile() {
    return (
        <>
            <div
                className={cn(
                    'relative w-full h-full pb-[66px] overflow-hidden bg-accent300',
                )}
            >
                <ImpersonationController>
                    <MainRoutes />
                </ImpersonationController>
            </div>
            <FooterMenu />
            <Toaster />
        </>
    );
}
export default AppLayoutMobile;
