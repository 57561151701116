import { cn } from '@/lib/utils';
import * as React from 'react';

export interface TextareaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, ...props }, ref) => {
        return (
            <textarea
                className={cn(
                    'flex min-h-[80px] w-full rounded-xl border border-neutral300',
                    'text-black text-14 xs:text-16',
                    'bg-white p-6 placeholder:text-neutral300',
                    'focus-visible:outline-none',
                    'disabled:cursor-not-allowed disabled:opacity-50 ',
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Textarea.displayName = 'Textarea';

export { Textarea };
