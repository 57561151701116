import { cn } from '@/lib/utils';
import * as React from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={cn(
                    'flex h-10 w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:placeholder:text-slate-400',
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Input.displayName = 'Input';

export enum TextInputClearErrorsPlan {
    ChangeOnly = 'ChangeOnly',
    All = 'All',
}

export interface TextInputProps extends InputProps {
    value: string;
    setValue: (val: string) => void;
    error?: string;
    clearError?: () => void;
    label?: string;
    clearErrorsPlan?: TextInputClearErrorsPlan;
}

function TextInput({
    error,
    clearError,
    value,
    setValue,
    label,
    clearErrorsPlan = TextInputClearErrorsPlan.ChangeOnly,
    ...props
}: TextInputProps) {
    const indicateError = !!error;

    const resetError = () => {
        if (indicateError && clearError) {
            clearError();
        }
    };
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setValue(ev.target.value);
        resetError();
    };
    return (
        <div className={cn('w-full')}>
            {label && <h4 className={cn('font-semibold text-14')}>{label}</h4>}
            <Input
                className={cn(
                    'mt-3',
                    'h-20 p-6 bg-white border border-neutral300 rounded-xl font-medium text-14 xs:text-16',
                    'placeholder:font-medium placeholder:text-neutral300',
                    {
                        'border-red200 text-red200': indicateError,
                    },
                )}
                value={value}
                onChange={handleChange}
                onClick={
                    clearErrorsPlan === TextInputClearErrorsPlan.All
                        ? resetError
                        : undefined
                }
                onFocus={
                    clearErrorsPlan === TextInputClearErrorsPlan.All
                        ? resetError
                        : undefined
                }
                {...props}
            />
            {indicateError && (
                <p className={cn('mt-3 font-medium text-red200 text-13')}>{error}</p>
            )}
        </div>
    );
}

export { TextInput };
