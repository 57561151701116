export function getAllEnumKeys<T extends object>(enumObj: T) {
    return Object.keys(enumObj)
        .filter((key) => {
            return isNaN(Number(key));
        })
        .map((key) => key as keyof T);
}

export function getAllEnumValues<T extends object>(enumObj: T): T[keyof T][] {
    return getAllEnumKeys(enumObj).map((key) => enumObj[key]);
}

export const sortEnumList = <T>(list: T[], orderMap: T[]): T[] => {
    return [...list].sort((a, b) => {
        const indexA = orderMap.indexOf(a);
        const indexB = orderMap.indexOf(b);
        return indexA - indexB;
    });
};
