import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

import NoData from './NoData';

function MainDetailData({ icon, text }: { icon: ReactNode; text?: string | null }) {
    return text ? (
        <div className={cn('flex items-center gap-2')}>
            {icon && <div className={cn('whitespace-nowrap')}>{icon}</div>}
            <div
                className={cn(
                    'text-black whitespace-nowrap font-bold text-14 xs:whitespace-normal',
                )}
            >
                {text}
            </div>
        </div>
    ) : (
        <NoData />
    );
}

export default MainDetailData;
