import { ProfessionAvatar } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn } from '@/lib/utils';
import ProcessStatusBadge from '@/pages/candidates/inProcess/ProcessStatusBadge';
import { homepage as homepageStrings } from '@/strings';
import {
    dateToDayInMonthShort,
    dateToLongMonthName,
    dateToTimeStringShort12,
} from '@/strings/common';

import { UpcomingEventBlockBaseProps } from '../types';

const strings = homepageStrings.upcomingEvents;

function UpcomingEventBlockMobile({ event, onClick }: UpcomingEventBlockBaseProps) {
    const { startAt, endAt, process } = event;
    const { candidate } = process;
    const { name, profession } = candidate;

    return (
        <div
            className={cn('bg-white rounded-2xl w-full p-6 cursor-pointer')}
            onClick={() => onClick(event.id)}
        >
            <div className={cn('flex items-center gap-3')}>
                <ProfessionAvatar
                    className={cn('h-12 w-12')}
                    profession={profession as Profession}
                />
                <div>
                    <p className={cn('text-13-23 font-bold')}>{name}</p>
                    <div className={cn('text-11 font-medium')}>
                        {strings.eventBlock.professionToName[profession as Profession]}
                    </div>
                </div>
            </div>
            <div className={cn('flex items-center justify-between gap-6 mt-5')}>
                <div>
                    <div className={cn('text-13 font-bold text-nowrap')}>
                        {`${dateToTimeStringShort12(startAt)} - ${dateToTimeStringShort12(endAt)}`}
                    </div>
                    <div className={cn('mt-1')}>
                        <ProcessStatusBadge process={process} />
                    </div>
                </div>
                <div className={cn('flex flex-col items-center')}>
                    <div className={cn('text-[24px] leading-[1] font-medium')}>
                        {dateToDayInMonthShort(startAt)}
                    </div>
                    <div className={cn('text-[10px] leading-[1] font-semibold mt-2')}>
                        {dateToLongMonthName(startAt)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpcomingEventBlockMobile;
