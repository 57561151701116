import { cn } from '@/lib/utils';

import { DetailsCardProps } from './types';
import { detailsCardHeaderVariants } from './variants';

function DetailsCard({
    title,
    children,
    headerProps,
    headerVariant,
    className,
    rootProps,
}: DetailsCardProps) {
    const isSimpleTitle = typeof title === 'string';
    return (
        <div
            {...rootProps}
            className={cn('flex flex-col rounded-2xl bg-white', className)}
        >
            <div
                {...headerProps}
                className={cn(
                    'border-b border-neutral-100',
                    detailsCardHeaderVariants({ headerVariant }),
                    headerProps?.className,
                )}
            >
                {!isSimpleTitle && title}
                {isSimpleTitle && (
                    <p className={cn('font-bold text-14 text-black mx-8')}>{title}</p>
                )}
            </div>
            <div className={cn('flex-grow')}>{children}</div>
        </div>
    );
}

export default DetailsCard;
