import useCurrentPageId from '@/hooks/useCurrentPageId';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMixpanelContext } from './MixpanelController';
import { MixpanelEvent, trackEvent } from './events';

const useTrackPageView = () => {
    const location = useLocation();
    const pageId = useCurrentPageId();
    const { initiated } = useMixpanelContext();

    useEffect(() => {
        if (initiated) {
            trackEvent(MixpanelEvent.PageView, {
                page: pageId ?? '',
                path: location.pathname,
                search: location.search,
            });
        }
    }, [location, initiated, pageId]);
};

export default useTrackPageView;
