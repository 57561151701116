import {
    ProcessAction,
    ProcessHistoryAdditionalAction,
    ProcessStatus,
} from '@/lib/api/v1';
import { Process, processStatusToEventTypeMap } from '@/lib/types';
import { CustomHistoryAction } from '@/lib/types/processHistory';
import { CurrentActionItemType } from '@/processHistory/types';
import { format } from 'date-fns';

import {
    capitalizeFirstLetter,
    eventTypeToString,
    getFirstName,
    processStatusToEventString,
} from '../common';
import { exampleProcess } from '../examples';

const getEventName = (status: ProcessStatus, capital = false) => {
    const text = processStatusToEventString(status) ?? 'event';
    return capital ? capitalizeFirstLetter(text) : text;
};

const processStatusToString: Record<ProcessStatus, string> = {
    [ProcessStatus.PhoneInterview]: 'Phone interview',
    [ProcessStatus.InPersonInterview]: 'In-person interview',
    [ProcessStatus.Orientation]: 'Orientation',
    [ProcessStatus.Paperwork]: 'Application docs & screening',
    [ProcessStatus.Matchmaking]: 'New',
    [ProcessStatus.New]: 'New',
    [ProcessStatus.Hired]: 'Hired',
    [ProcessStatus.Terminated]: 'Rejected',
};

const processHistoryActionToString: Record<
    string,
    | string
    | {
          func: (status: ProcessStatus) => string;
          example: [ProcessStatus];
      }
> = {
    [ProcessAction.Batch]: 'New',
    [ProcessAction.RecruiterApproval]: 'Candidate approved',

    [ProcessAction.CreateEvent]: {
        func: (status) => `${getEventName(status, true)} scheduled`,
        example: [ProcessStatus.PhoneInterview],
    },
    [ProcessAction.CompleteEvent]: {
        func: (status) => `${getEventName(status, true)} completed`,
        example: [ProcessStatus.InPersonInterview],
    },
    [ProcessAction.RequestRescheduleEvent]: {
        func: (status) => `${getEventName(status, true)} canceled`,
        example: [ProcessStatus.InPersonInterview],
    },
    [ProcessAction.CancelEvent]: {
        func: (status) => `${getEventName(status, true)} canceled`,
        example: [ProcessStatus.InPersonInterview],
    },
    [CustomHistoryAction.Revive]: {
        func: (status) => `Revive process (to ${processStatusToString[status]} step)`,
        example: [ProcessStatus.InPersonInterview],
    },
    [ProcessAction.MoveToPhoneInterview]: 'Phone interview requested',
    [ProcessAction.MoveToInPersonInterview]: 'In-person interview requested',
    [ProcessAction.MoveToOrientation]: 'Orientation requested',
    [ProcessAction.MoveToPaperwork]: 'Started application docs & screening',
    [ProcessAction.TerminateProcess]: 'Process terminated',
    [ProcessAction.MarkSuccess]: 'Hired',
    [ProcessAction.UpdateFacility]: 'Facility updated',

    [ProcessHistoryAdditionalAction.MarkEventUnoccurred]: {
        func: (status) => `Feedback ${getEventName(status)} didn't occur`,
        example: [ProcessStatus.InPersonInterview],
    },
};

type ActionItemLeaf =
    | string
    | {
          func: (process: Process) => string;
          example: [Process];
      };

const actionItemToStrings: Record<
    CurrentActionItemType,
    { title: ActionItemLeaf; subtitle: ActionItemLeaf }
> = {
    [CurrentActionItemType.PendingApproval]: {
        title: 'Pending your approval',
        subtitle: 'Approve the candidate to continue the hiring process',
    },
    [CurrentActionItemType.EventScheduled]: {
        title: {
            func: (process) =>
                process.relevantEvent
                    ? `Pending ${eventTypeToString[process.relevantEvent.eventType]}`
                    : '',
            example: [exampleProcess],
        },
        subtitle: {
            func: (process) =>
                process.relevantEvent
                    ? `on ${format(process.relevantEvent.startAt, 'MMM dd, HH:mm a')}`
                    : '',
            example: [exampleProcess],
        },
    },
    [CurrentActionItemType.FeedbackRequired]: {
        title: 'Pending feedback',
        subtitle: {
            func: (process) =>
                process.relevantEvent
                    ? `Let us know how ${getFirstName(process.candidate.name)}'s ${eventTypeToString[process.relevantEvent?.eventType]} went`
                    : '',
            example: [exampleProcess],
        },
    },
    [CurrentActionItemType.PendingPaperwork]: {
        title: 'Pending application docs & background checks',
        subtitle: 'Let us know when you`re ready to proceed to orientation',
    },
    [CurrentActionItemType.EventRequested]: {
        title: 'Carefam is on it',
        subtitle: {
            func: (process) => {
                const eventType = processStatusToEventTypeMap[process.status];
                if (!eventType) return '';
                return `We will reach out to ${getFirstName(process.candidate.name)} to schedule the ${eventTypeToString[eventType]}`;
            },
            example: [exampleProcess],
        },
    },
};

const strings = { processHistoryActionToString, actionItemToStrings };

export default strings;
