import { CustomPopover, PopoverMenu } from '@/components/ui';
import { Time } from '@/lib/base';
import { cn } from '@/lib/utils';
import { timeToStringLong12, timeToStringShort12 } from '@/strings/common';

import { TIME_INTERVAL_ROW_HEIGHT_CLASS } from './common';
import { TimeInputProps } from './types';

function TimeOptions({ time }: { time: Time }) {
    return (
        <p className={cn('text-13 font-semibold')}>
            {timeToStringLong12(time).replace(' ', '').toLowerCase()}
        </p>
    );
}

function TimeInput({ time, onChange, hasError, selectableTimes }: TimeInputProps) {
    return (
        <div className={cn()}>
            <CustomPopover
                trigger={
                    <div
                        className={cn(
                            'flex items-center justify-center',
                            'rounded-[8px] bg-white border border-neutral300',
                            'w-[88px]',
                            TIME_INTERVAL_ROW_HEIGHT_CLASS,
                            { 'border-red200': hasError },
                        )}
                    >
                        <p
                            className={cn('text-14 font-medium text-black', {
                                'text-red200': hasError,
                            })}
                        >
                            {timeToStringShort12(time).replace(' ', '').toLowerCase()}
                        </p>
                    </div>
                }
                contentProps={{
                    variant: 'noPointer',
                    align: 'start',
                    alignOffset: 0,
                    sideOffset: 6,
                }}
            >
                <PopoverMenu
                    rootProps={{
                        className: cn(
                            'max-h-[326px] xs:max-h-[400px] overflow-auto hidden-scrollbar',
                        ),
                    }}
                    items={selectableTimes.map((time) => ({
                        id: time.toJSON(),
                        content: { custom: <TimeOptions time={time} /> },
                        onClick: () => onChange(time),
                        className: cn(
                            'w-[250px] xs:w-full p-6 border-b border-neutral100 last-of-type:border-b-0',
                        ),
                    }))}
                    selectedItemId={time.toJSON()}
                />
            </CustomPopover>
        </div>
    );
}

export default TimeInput;
