import { EventType, ProcessStatus, models } from '@/lib/api/v1';

export type CandidateApiModel = models['CandidateOut__ApiModel'];
export type Candidate = CandidateApiModel; // no relevant Date objects
export type EventCandidate = models['EventCandidateOut'];

export type ProcessApiModel = models['ProcessOut__ApiModel'];
export type Process = Omit<models['ProcessOut'], 'relevantEvent'> & {
    relevantEvent?: FlatCandidateEvent;
};

export type CandidateEventApiModel = models['CandidateEventOut__ApiModel'];
export type CandidateEvent = models['CandidateEventOut'];
export type FlatCandidateEventApiModel = models['FlatCandidateEventOut__ApiModel'];
export type FlatCandidateEvent = models['FlatCandidateEventOut'];

export type JobMatchApiModel = models['JobMatchOut__ApiModel'];
export type JobMatch = JobMatchApiModel;

export type ProcessHistoryApiModel = models['ProcessHistoryOut__ApiModel'];
export type ProcessHistory = models['ProcessHistoryOut'];
export type ProcessHistoryAction = models['ProcessHistoryOut']['action'];

export const processStatusToEventTypeMap: Partial<Record<ProcessStatus, EventType>> = {
    [ProcessStatus.PhoneInterview]: EventType.PhoneInterview,
    [ProcessStatus.InPersonInterview]: EventType.InPersonInterview,
    [ProcessStatus.Orientation]: EventType.Orientation,
};
