import { FalafelButton, ProfessionAvatar } from '@/components/ui';
import InProcessActionsView from '@/inProcessActionsMenu/InProcessActionsView';
import { Process, Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount, handlerNoPropagation } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candidatesStrings } from '@/strings';
import { useRef, useState } from 'react';

import InProcessSubstatus from './InProcessSubstatus';
import ProcessStatusBadge from './ProcessStatusBadge';
import { PROCESS_BLOCK_FLEX_CLASS } from './consts';

const strings = candidatesStrings.inProcess;

function ProcessBlock({ process, onClick }: { process: Process; onClick: () => void }) {
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    const profession = process.candidate.profession as Profession;
    const contatinerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={cn(
                'bg-white rounded-2xl transition-shadow duration-100 ease-out shadow-elevation-100 hover:shadow-elevation-200 mb-8 cursor-pointer',
                'relative group',
                PROCESS_BLOCK_FLEX_CLASS,
                { 'pointer-events-none': isActionsOpen },
                'absolute-popover-container',
            )}
            onClick={onClick}
            ref={contatinerRef}
        >
            <div
                className={cn('absolute top-3 right-3')}
                onClick={handlerNoPropagation(() => {})}
            >
                <InProcessActionsView
                    trigger={
                        <FalafelButton
                            className={cn('pointer-events-auto p-4')}
                            onClick={() => {
                                trackEvent(MixpanelEvent.ProcessCardOpenActionsMenu, {
                                    candidate_id: process.candidate.id,
                                    candidate_name: process.candidate.name,
                                });
                            }}
                        />
                    }
                    contentProps={{ container: contatinerRef.current }}
                    process={process}
                    open={isActionsOpen}
                    setOpen={setIsActionsOpen}
                />
            </div>
            <div
                className={cn(
                    ' h-[186px] flex flex-col px-4 justify-center items-center',
                )}
            >
                <div className={cn('h-8 w-full')} />
                <div className={cn('pb-6 flex flex-col items-center')}>
                    <ProfessionAvatar
                        className={cn('w-20 h-20')}
                        profession={profession}
                    />
                    <div className={cn('mt-4')}>
                        <div className={cn('text-16 font-semibold text-center')}>
                            {process.candidate.name}
                        </div>
                        <div
                            className={cn('mt-1 flex justify-center items-start gap-2')}
                        >
                            <div
                                className={cn(
                                    'text-black font-medium text-12 text-nowrap',
                                )}
                            >
                                {strings.processBlock.professionWithExp.func(
                                    profession,
                                    process.candidate.yearsOfExperience,
                                )}
                            </div>
                            {facilityName && (
                                <>
                                    <div className={cn('flex items-center h-7')}>
                                        <div
                                            className={cn(
                                                'rounded-full h-1 w-1 bg-neutral300',
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={cn(
                                            'text-neutral300 text-12 font-medium',
                                        )}
                                    >
                                        {strings.processBlock.oneLineFacilities.func(
                                            facilityName,
                                            facilitiesCount,
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    'h-[57px] flex px-6 items-center justify-between border-t border-neutral100',
                )}
            >
                <InProcessSubstatus process={process} />
                <ProcessStatusBadge process={process} />
            </div>
        </div>
    );
}

export default ProcessBlock;
