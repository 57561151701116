import { CustomPopover, PopoverMenu } from '@/components/ui';
import { cn } from '@/lib/utils';

import { InProcessActionsMenuProps } from './types';

export function InProcessActionsMenu({
    items,
    rootProps,
    ...popoverProps
}: InProcessActionsMenuProps) {
    return (
        <div
            {...rootProps}
            className={cn(
                'opacity-0 group-hover:opacity-100 xs:opacity-100',
                'transition-opacity ease-out duration-100',
                { 'opacity-100': open },
                rootProps?.className,
            )}
        >
            <CustomPopover
                {...popoverProps}
                contentProps={{
                    variant: 'noPointer',
                    align: 'end',
                    alignOffset: 0,
                    sideOffset: 6,
                    className: cn(''),
                    ...popoverProps.contentProps,
                }}
            >
                <PopoverMenu
                    items={items}
                    itemsProps={{ rootProps: { className: cn('h-14 xs:h-[64px]') } }}
                />
            </CustomPopover>
        </div>
    );
}

export default InProcessActionsMenu;
