import {
    DialogNameWithData,
    openDialogDismissOthers,
    setDialogOpen,
} from '@/_reducers/dialogs';
import { requestRescheduleEvent } from '@/_reducers/events';
import { EventDetailsDialogData, IsEventDetailsDialogOpen } from '@/_selectors';
import { DialogName } from '@/lib/types';
import { connect, useSelector } from 'react-redux';

import EventDetailsDialog from './EventDetailsDialog';

function EventDetailsDialogProvider({
    setDialogOpenAction,
    requestRescheduleEvent,
    openDialogDismissOthers,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
    requestRescheduleEvent: (eventId: string) => Promise<void>;
}) {
    const shouldBeOpen = useSelector(IsEventDetailsDialogOpen);
    const { event } = useSelector(EventDetailsDialogData);
    const hasData = !!event;
    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.EventDetails, open);
    };
    const handleClose = () => setOpen(false);

    const goToTerminate = () => {
        if (!event?.process) return;
        openDialogDismissOthers(DialogName.TerminateProcess, {
            processId: event.process.id,
        });
    };

    const onRescheduleClick = async () => {
        if (!event) return;
        await requestRescheduleEvent(event.id);
    };

    return (
        <>
            {hasData && (
                <EventDetailsDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    event={event}
                    goToTerminate={goToTerminate}
                    onReschedule={onRescheduleClick}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    openDialogDismissOthers,
    requestRescheduleEvent,
};

export default connect(null, mapDispathToProps)(EventDetailsDialogProvider);
