import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rootReducer from '../_reducers';

const store = configureStore({ reducer: rootReducer });

export type GetRootState = typeof store.getState;
export type RootState = ReturnType<GetRootState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
