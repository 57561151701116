import { genericToasts, professionToName, terminationReasonsToStrings } from '../common';

const strings = {
    title: 'Reject this candidate',
    description: 'Select a reason for the rejection.',
    terminateButton: 'Reject candidate',
    cancelButton: 'Cancel',
    selectReasonPlaceholder: 'Select rejection reason',
    freeTextPlaceholder: {
        required: "Explain in your own words why you're terminating this candidate.",
        optional: 'Feel free to elaborate and add anything we should know (optional)',
    },
    terminationReasons: {
        ...terminationReasonsToStrings,
    },
    professionToName,
    successStep: {
        title: {
            func: (firstName: string) => `${firstName} was rejected`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
        description: "We've removed this candidate from your list. 😟",
    },
    terminate: {
        ...genericToasts.failure,
    },
};

export default strings;
