import { ProcessStatus } from '@/lib/api/v1';

import { genericToasts, professionToName } from '../common';

const strings = {
    [ProcessStatus.InPersonInterview]: {
        title: 'Move this candidate to an in-person interview',
        submitButton: 'Schedule in-person interview',
    },
    [ProcessStatus.Paperwork]: {
        title: 'Move this candidate to application & screening',
        submitButton: 'Yes, move forward',
    },
    [ProcessStatus.Orientation]: {
        title: 'Move this candidate to orientation',
        submitButton: 'Schedule orientation',
    },
    [ProcessStatus.PhoneInterview]: {
        title: 'Move this candidate to another phone interview',
        submitButton: 'Schedule phone interview',
    },
    [ProcessStatus.Hired]: {
        title: "The candidate's process with Carefam is complete. Congrats!",
        submitButton: "Yay! We'll take it from here",
    },
    moveToSameStatus: {
        [ProcessStatus.InPersonInterview]: {
            title: 'Move this candidate to another in-person interview',
            successDescription: 'Next up is another in-person interview.',
        },
        [ProcessStatus.Orientation]: {
            title: 'Move this candidate to another orientation',
            successDescription: 'Next up is another orientation.',
        },
    },
    cancelButton: 'Cancel',
    freeTextPlaceholder:
        'Feel free to elaborate and add anything we should know (optional)',
    description: '',
    professionToName,
    successStep: {
        title: {
            func: (firstName: string) => `${firstName}'s process was updated`,
            example: ['[CANDIDATE_FIRST_NAME]'],
        },
        description: {
            [ProcessStatus.InPersonInterview]: 'Next up is an in-person interview.',
            [ProcessStatus.Paperwork]: 'Next up is application & screening.',
            [ProcessStatus.Orientation]: 'Next up is orientation.',
            [ProcessStatus.PhoneInterview]: 'Next up is another phone interview.',
            [ProcessStatus.Hired]: 'A new addition to the team! Exciting!',
        },
    },
    failureToast: {
        ...genericToasts.failure,
    },
};

export default strings;
