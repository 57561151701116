import { Dialog } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { Process, ProcessNextStep } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';

import CandidateTitle from '../common/CandidateTitle';
import { BaseDialogPropsCommon } from '../types';
import { MoveProcessForwardDialogContent } from './content';
import SuccessContent from './successContent';
import { UpdateProcessFunc } from './types';

interface Props extends BaseDialogPropsCommon {
    process: Process;
    updateStatus: UpdateProcessFunc;
    nextStep: ProcessNextStep;
}

enum Step {
    Form = 'form',
    Success = 'success',
}

function MoveProcessForwardDialog({
    process,
    isOpen,
    setOpen,
    updateStatus,
    handleClose,
    nextStep,
}: Props) {
    const [step, setStep] = useState<Step>(Step.Form);
    const dialogAnimationLength = useDialogAnimationLength();

    const submitCallback = () => {
        setStep(Step.Success);
    };

    const noBorderHeader = step === Step.Success;

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => setStep(Step.Form), dialogAnimationLength);
        }
    }, [isOpen, setStep, dialogAnimationLength]);

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            title={<>{step === Step.Form && <CandidateTitle process={process} />}</>}
            contentProps={{ className: cn('w-[650px]') }}
            headerProps={{
                className: cn('py-8', { 'border-0': noBorderHeader }),
            }}
        >
            <>
                {step === Step.Form && (
                    <MoveProcessForwardDialogContent
                        process={process}
                        handleClose={handleClose}
                        updateProcess={updateStatus}
                        submitCallback={submitCallback}
                        nextStep={nextStep}
                    />
                )}
                {step === Step.Success && (
                    <SuccessContent process={process} nextStep={nextStep} />
                )}
            </>
        </Dialog>
    );
}

export default MoveProcessForwardDialog;
