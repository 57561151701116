import { RefObject, useEffect, useState } from 'react';

import { useIsMobileScreen } from './useMediaQueries';

function useHover<T extends HTMLElement>(ref: RefObject<T>): boolean {
    const isMobileScreen = useIsMobileScreen();
    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        if (isMobileScreen) {
            return;
        }
        const handleMouseEnter = () => setHovering(true);
        const handleMouseLeave = () => setHovering(false);
        const node = ref.current;

        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter);
            node.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                node.removeEventListener('mouseenter', handleMouseEnter);
                node.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, [ref, isMobileScreen]);

    if (isMobileScreen) {
        return false;
    }
    return hovering;
}

export default useHover;
