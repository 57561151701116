import { useLocation } from 'react-router-dom';

function useLocationHash() {
    const location = useLocation();
    const hashString = location.hash.replace('#', '');

    return hashString;
}

export default useLocationHash;
