import { ProcessStatus } from '@/lib/api/v1';
import { EventRelatedProcessStatus, ProcessPendingEventFeedback } from '@/lib/types';

import { TaskType } from './types';

const processStatusToTaskType: Record<EventRelatedProcessStatus, TaskType> = {
    [ProcessStatus.PhoneInterview]: TaskType.PhoneInterviewFeedback,
    [ProcessStatus.InPersonInterview]: TaskType.InPersonInterviewFeedback,
    [ProcessStatus.Orientation]: TaskType.OrientationFeedback,
};

export function getTaskType(process: ProcessPendingEventFeedback): TaskType {
    return processStatusToTaskType[process.status];
}
