import {
    Button,
    DialogContentWithFooter,
    LoadingButton,
    useToast,
} from '@/components/ui';
import { cn } from '@/lib/utils';
import { useState } from 'react';

import { ConfirmationDialogContentProps } from './types';

function ConfirmationDialogContent({
    title,
    description,
    confirm,
    cancel,
    confirmCallback,
}: ConfirmationDialogContentProps) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        const isImmediate = !!confirm.isImmediate;
        if (isImmediate) {
            confirm.action();
            confirmCallback?.();
            return;
        }
        setIsLoading(true);
        await confirm
            .action()
            .then(() => {
                confirmCallback?.();
            })
            .catch((error) => {
                toast({
                    title: confirm.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <DialogContentWithFooter
            footer={
                <div className={cn('w-full flex xs:flex-wrap-reverse gap-6')}>
                    <div className={cn('relative basis-1/2 flex-grow')}>
                        <Button
                            className={cn('w-full')}
                            size={'lg'}
                            variant={'secondary'}
                            onClick={cancel.action}
                            disabled={isLoading}
                        >
                            {cancel.label}
                        </Button>
                    </div>
                    <div className={cn('relative basis-1/2 flex-grow')}>
                        <LoadingButton
                            className={cn('w-full')}
                            size={'lg'}
                            onClick={onSubmit}
                        >
                            {confirm.label}
                        </LoadingButton>
                    </div>
                </div>
            }
        >
            <div className={cn('relative w-[650px] max-w-full')}>
                <div className={cn('p-12 xs:p-0 xs:pb-4 text-center xs:text-left')}>
                    <p className={cn('text-18 leading-10 font-semibold')}>{title}</p>
                    <p
                        className={cn(
                            'text-14 whitespace-pre-line xs:whitespace-normal',
                        )}
                    >
                        {description}
                    </p>
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default ConfirmationDialogContent;
