import { Dialog, DialogHeaderAnchor } from '@/components/ui/dialog';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';

import { ToastClose, ToastDescription, ToastThumbnail, ToastTitle } from './toast';
import { ToasterToast } from './types';

export function ToasterMobile({ toast }: { toast: ToasterToast }) {
    const { title, description, variant, icon, thumbnailProps } = toast;
    const { open, setOpen, handleClose } = useSetOpen();
    useEffect(() => {
        setOpen(true);
    }, [setOpen]);
    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            customHeader={
                <>
                    <DialogHeaderAnchor />
                    <div className={cn('h-[90px] p-8 flex justify-end items-center ')}>
                        <ToastClose onClick={handleClose} />
                    </div>
                </>
            }
        >
            <div
                className={cn(
                    'min-h-[270px] p-10 pt-0 flex-col flex items-center gap-10',
                )}
            >
                <ToastThumbnail
                    variant={variant}
                    icon={icon}
                    className={cn('w-24 h-24 m-0')}
                    {...thumbnailProps}
                />
                <div className={cn('flex-col flex items-center gap-4 max-w-full')}>
                    {title && (
                        <ToastTitle className={cn('max-w-full text-20')}>
                            {title}
                        </ToastTitle>
                    )}
                    {description && (
                        <ToastDescription
                            className={cn(
                                'text-center text-15 max-w-full whitespace-normal mt-0',
                            )}
                        >
                            {description}
                        </ToastDescription>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
