import { cn } from '@/lib/utils';

import { NavItemProps } from './types';

function NavItem({ selected, Icon, onHoverClass, label, action }: NavItemProps) {
    return (
        <div
            className={cn(
                'h-[78px] px-12 flex items-center hover:bg-accent100 cursor-pointer',
                { 'bg-accent300': selected, 'pointer-events-none': selected },
            )}
            onClick={action}
        >
            <div className={cn('relative')}>
                <Icon className={cn('stroke-black fill-black h-6 w-6')} />
                <p
                    className={cn(
                        'absolute top-1/2 -translate-y-1/2 left-12 whitespace-nowrap text-black text-13 font-semibold',
                        onHoverClass,
                    )}
                >
                    {label}
                </p>
            </div>
        </div>
    );
}

export default NavItem;
