import { useIsMediumOrLessScreen } from '@/hooks/useMediaQueries';

import HomePageLayout from './HomePageLayout';
import HomePageMobileLayout from './mobile/HomePageMobileLayout';
import usePendingTasks from './usePendingTasks';

function HomePage() {
    const showMobileView = useIsMediumOrLessScreen();
    const { isLoadingTasks, initiatedTasks, processes } = usePendingTasks();

    return showMobileView ? (
        <HomePageMobileLayout
            {...{
                isLoadingTasks,
                initiatedTasks,
                pendingTasksProcesses: processes,
            }}
        />
    ) : (
        <HomePageLayout
            {...{
                isLoadingTasks,
                initiatedTasks,
                pendingTasksProcesses: processes,
            }}
        />
    );
}

export default HomePage;
