import { useAppDispatch } from '@/_helpers/store';
import {
    FetcherName as ProcessesFetcherName,
    fetchProcessesByIds,
} from '@/_reducers/processes';
import { MissingForEventsProcessesFetcherData, ProcessesIdsToFetch } from '@/_selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function useMissingProcessesFetcher() {
    const idsToFetch = useSelector(ProcessesIdsToFetch);
    const { isLoading } = useSelector(MissingForEventsProcessesFetcherData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (idsToFetch.length > 0 && !isLoading) {
            dispatch(
                fetchProcessesByIds(idsToFetch, ProcessesFetcherName.MissingForEvents),
            );
        }
    }, [idsToFetch, dispatch, isLoading]);

    return {
        isLoading,
    };
}

export default useMissingProcessesFetcher;
