import { QueryParam } from '@/lib/types/queryParams';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useSetQueryParam = (param: QueryParam) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { search, hash } = location;

    const setQueryParam = useCallback(
        (value: string | null) => {
            const queryParams = queryString.parse(search);
            if (value !== null) {
                queryParams[param] = value;
            } else {
                delete queryParams[param];
            }
            navigate(
                {
                    search: queryString.stringify(queryParams),
                    hash,
                },
                { replace: true },
            );
        },
        [search, navigate, param, hash],
    );

    return setQueryParam;
};

export default useSetQueryParam;
