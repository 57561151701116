import { Toaster } from '@/components/ui';
import ImpersonationController from '@/impersonation/ImpersonationController';
import { cn } from '@/lib/utils';
import MainRoutes from '@/routes/mainRoutes';
import { Sidebar } from '@/sidebar';

function AppLayout() {
    return (
        <>
            <div className={cn('relative pl-[90px] h-full overflow-auto')}>
                <ImpersonationController>
                    <MainRoutes />
                </ImpersonationController>
            </div>
            <Toaster />
            <Sidebar />
        </>
    );
}
export default AppLayout;
