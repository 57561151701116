import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import { DialogStep, EventDialogTitleProps, ImplementedEventType } from './types';

const strings = dialogsStrings.eventDetails;

function EventDialogTitle({ event, step }: EventDialogTitleProps) {
    return (
        <>
            {step === DialogStep.EventDetails && (
                <div className={cn('font-bold text-16')}>
                    {strings[event.eventType as ImplementedEventType].title}
                </div>
            )}
            {step === DialogStep.CancelEvent && (
                <CandidateTitle process={event.process} />
            )}
        </>
    );
}

export default EventDialogTitle;
