import { cn } from '@/lib/utils';

import EmailSent from './EmailSent';
import LoginBackgroundImage from './LoginBackgroundImage';
import SignInSection from './SignInSection';
import { LoginPageLayoutProps, Step } from './types';

function LoginPageLayout({
    step,
    setStep,
    email,
    setEmail,
    error,
    setError,
    signInFromEmailLink,
}: LoginPageLayoutProps) {
    return (
        <div className={cn('flex h-screen w-screen bg-white')}>
            <div className={cn('flex-shrink h-full basis-2/5 overflow-hidden relative')}>
                <LoginBackgroundImage />
            </div>
            <div
                className={cn(
                    'relative h-full flex flex-grow flex-shrink justify-center items-center p-8 min-w-fit w-max overflow-hidden',
                )}
            >
                <div className={cn('p-30')}>
                    {step === Step.SignIn && (
                        <SignInSection
                            error={error}
                            setError={setError}
                            email={email}
                            setEmail={setEmail}
                            signInFromEmailLink={signInFromEmailLink}
                            onEmailSent={() => {
                                setStep(Step.EmailSent);
                            }}
                        />
                    )}
                    {step === Step.EmailSent && (
                        <EmailSent
                            email={email}
                            goBack={() => {
                                setStep(Step.SignIn);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default LoginPageLayout;
