import { PageId } from '@/lib/types';
import { routeToPageId } from '@/lib/utils';
import { Candidates, HomePage } from '@/pages';
import { Navigate, Route, Routes } from 'react-router-dom';

const pageIdToView = {
    [PageId.Home]: <HomePage />,
    [PageId.Candidates]: <Candidates />,
    [PageId.Calendar]: null,
    [PageId.Settings]: null,
};

function MainRoutes() {
    return (
        <Routes>
            {Object.entries(routeToPageId).map(([path, pageId]) => (
                <Route key={path} path={path} element={pageIdToView[pageId]} />
            ))}
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
}

export default MainRoutes;
