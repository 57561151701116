import { EventStatus, EventType } from '@/lib/api/v1';
import { CandidateEventApiModel, FetcherState } from '@/lib/types';

export interface FetchProcessEvents {
    ids?: string[];
    processes?: string[];
    eventType?: EventType | EventType[];
    status?: EventStatus;
    orderBy?: string;
}

export type FetchProcessEventsFunc = () => Promise<CandidateEventApiModel[]>;

export enum FetcherName {
    MissingForProcesses = 'missingForProcesses',
    UpcomingEvents = 'upcomingEvents',
}

export interface EventsState {
    data: Record<string, CandidateEventApiModel | undefined>;
    fetchers: Record<FetcherName, FetcherState>;
}
