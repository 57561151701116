import { cn } from '@/lib/utils';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const popoverVariants = cva('', {
    variants: {
        variant: {
            topPointer: cn('popover-top-pointer'),
            noPointer: '',
        },
    },
    defaultVariants: {
        variant: 'topPointer',
    },
});

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
        VariantProps<typeof popoverVariants> & {
            container?: PopoverPrimitive.PortalProps['container'];
        }
>(
    (
        { className, align = 'center', variant, container, sideOffset = 4, ...props },
        ref,
    ) => (
        <PopoverPrimitive.Portal container={container}>
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                sideOffset={sideOffset}
                className={cn(
                    'z-50 w-72 rounded-md border border-slate-200 bg-white p-4  shadow-elevation-200 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 ',
                    popoverVariants({ variant }),
                    className,
                )}
                {...props}
            />
        </PopoverPrimitive.Portal>
    ),
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export type PopoverProps = PopoverPrimitive.PopoverProps;
export type PopoverPortalProps = PopoverPrimitive.PortalProps;
export type PopoverContentProps = React.ComponentProps<typeof PopoverContent>;
export type PopoverTriggerProps = PopoverPrimitive.PopoverTriggerProps;

export { Popover, PopoverTrigger, PopoverContent };
