import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { AuthUser, Env } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useState } from 'react';

import ImpersonationIndicationContent from './ImpersonationIndicationContent';
import ImpersonationIndicationContentMobile from './ImpersonationIndicationContentMobile';

interface Props {
    exitImpersonation: () => void;
    impersonatedUser: AuthUser;
    env: Env;
}

function ImpersonationIndication({ exitImpersonation, impersonatedUser, env }: Props) {
    const [pinned, setPinned] = useState(true);
    const isProd = env === Env.Prod;
    const collapsed = !pinned && !isProd;
    const overlapping = !pinned && isProd;
    const isMobileScreen = useIsMobileScreen();
    return (
        <div className={cn('relative w-full')}>
            <div
                className={cn('relative w-full py-2 px-8 bg-sky-300', {
                    'bg-red-600': isProd,
                    'fixed z-[100] left-0 top-0 w-fit px-4': collapsed,
                    'px-2 py-1': isMobileScreen,
                    'absolute z-[100] opacity-50': overlapping,
                })}
            >
                {isMobileScreen && (
                    <ImpersonationIndicationContentMobile
                        pinned={pinned}
                        setPinned={setPinned}
                        exitImpersonation={exitImpersonation}
                        impersonatedUser={impersonatedUser}
                        env={env}
                    />
                )}
                {!isMobileScreen && (
                    <ImpersonationIndicationContent
                        pinned={pinned}
                        setPinned={setPinned}
                        exitImpersonation={exitImpersonation}
                        impersonatedUser={impersonatedUser}
                        env={env}
                    />
                )}
            </div>
        </div>
    );
}

export default ImpersonationIndication;
