import { SuccessLottie } from '@/components/lotties';
import { cn } from '@/lib/utils';

import { SuccessContentProps } from './types';

export function SuccessContent({ description, title }: SuccessContentProps) {
    return (
        <div
            className={cn(
                'relative flex flex-col items-center text-center pt-6 pb-[90px] px-12 xs:p-10 xs:pt-8 xs:min-h-[390px]',
            )}
        >
            <SuccessLottie className={cn('h-[270px] w-fit xs:w-[200px] xs:h-fit')} />
            <p className={cn('mt-10 text-20 font-bold')}>{title}</p>
            <p className={cn('mt-4 text-14 font-medium xs:text-15')}>{description}</p>
        </div>
    );
}

export default SuccessContent;
