import { ExpandIcon } from '@/components/svgs';
import { FalafelButton, ProfessionAvatar } from '@/components/ui';
import InProcessActionsView from '@/inProcessActionsMenu/InProcessActionsView';
import { Process, Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount, handlerNoPropagation } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candidatesStrings } from '@/strings';

import InProcessSubstatus from './InProcessSubstatus';
import ProcessStatusBadge from './ProcessStatusBadge';

const strings = candidatesStrings.inProcess;

function ProcessBlockMobile({
    process,
    onClick,
}: {
    process: Process;
    onClick: () => void;
}) {
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    const profession = process.candidate.profession as Profession;

    return (
        <div
            className={cn(
                'relative bg-white rounded-2xl cursor-pointer',
                'max-w-full w-[450px]',
                'transition-shadow duration-100 ease-out shadow-elevation-100 hover:shadow-elevation-200',
            )}
            onClick={onClick}
        >
            <div
                className={cn('absolute top-6 left-6')}
                onClick={handlerNoPropagation(() => {})}
            >
                <InProcessActionsView
                    trigger={
                        <FalafelButton
                            className={cn('h-8 w-10 xs:bg-neutral100')}
                            onClick={() => {
                                trackEvent(MixpanelEvent.ProcessCardOpenActionsMenu, {
                                    candidate_id: process.candidate.id,
                                    candidate_name: process.candidate.name,
                                });
                            }}
                        />
                    }
                    process={process}
                />
            </div>
            <div className={cn('absolute top-6 right-6')}>
                <div
                    className={cn(
                        'bg-accent300 rounded-full h-12 w-12',
                        'flex justify-center items-center',
                    )}
                >
                    <ExpandIcon />
                </div>
            </div>
            <div
                className={cn(
                    'flex flex-col px-8 pt-12 pb-4 mx-4 justify-center items-center',
                )}
            >
                <ProfessionAvatar className={cn('w-16 h-16')} profession={profession} />
                <div className={cn('pt-4')}>
                    <div className={cn('text-16 font-bold text-center')}>
                        {process.candidate.name}
                    </div>
                    <div className={cn('mt-1 flex justify-center items-start gap-2')}>
                        <div
                            className={cn('text-black font-medium text-12 text-nowrap')}
                        >
                            {strings.processBlock.professionWithExp.func(
                                profession,
                                process.candidate.yearsOfExperience,
                            )}
                        </div>
                        {facilityName && (
                            <>
                                <div className={cn('flex items-center h-7')}>
                                    <div
                                        className={cn(
                                            'rounded-full h-1 w-1 bg-neutral300',
                                        )}
                                    />
                                </div>
                                <div
                                    className={cn('text-neutral300 text-12 font-medium')}
                                >
                                    {strings.processBlock.oneLineFacilities.func(
                                        facilityName,
                                        facilitiesCount,
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    'flex p-4 items-center justify-between border-t border-neutral100',
                )}
            >
                <InProcessSubstatus process={process} />
                <ProcessStatusBadge process={process} />
            </div>
        </div>
    );
}

export default ProcessBlockMobile;
