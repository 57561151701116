import { AvatarCNA, AvatarHHA, AvatarLPN, AvatarNP, AvatarRN } from '@/components/svgs';
import { Profession } from '@/lib/types';

interface Props {
    profession: Profession;
    className?: string;
}

const ProfessionAvatar = ({ profession, className }: Props) => {
    return (
        <>
            {profession === Profession.NP && <AvatarNP className={className} />}
            {profession === Profession.LPN && <AvatarLPN className={className} />}
            {profession === Profession.RN && <AvatarRN className={className} />}
            {profession === Profession.HHA && <AvatarHHA className={className} />}
            {profession === Profession.CNA && <AvatarCNA className={className} />}
        </>
    );
};
export default ProfessionAvatar;
