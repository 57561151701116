import { Env } from '@/lib/types';
import { cn } from '@/lib/utils';

import { EnvIndicationProps } from './types';

function EnvIndication({ pinned, env }: EnvIndicationProps) {
    const isProd = env === Env.Prod;
    const collapsed = !pinned && !isProd;
    return (
        <div
            className={cn(
                'mr-12 text-white text-12 bg-black/30 py-1 px-4 rounded-2xl select-none xs:whitespace-nowrap',
                { hidden: collapsed },
                { 'text-gray-200': !isProd },
            )}
        >
            {'env: '}
            <span
                className={cn('font-semibold', {
                    'font-bold text-14': isProd,
                })}
            >
                {env}
                {isProd ? '!!!' : ''}
            </span>
        </div>
    );
}

export default EnvIndication;
