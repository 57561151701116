import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

function VerticalScrollWrapper({ children }: { children: ReactNode }) {
    return (
        <div className={cn('relative w-full h-full')}>
            <div className={cn('absolute w-full h-full')}>
                <div className={cn('relative flex flex-col w-full h-full')}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default VerticalScrollWrapper;
