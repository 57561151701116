import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import YourAvButton from './YourAvButtonShinable';

function CandidatesTopRow({
    models,
    filterView,
    initiatedRecruiterAv,
    openSetAvailabilityDialog,
    openSetAvailabilityDialogDismissOthers,
}: {
    models: Process[];
    filterView: React.ReactNode;
    initiatedRecruiterAv: boolean;
    openSetAvailabilityDialog: () => void;
    openSetAvailabilityDialogDismissOthers: () => void;
}) {
    const hasVisibleData = models.length > 0;
    return (
        <div>
            <div
                className={cn(
                    'h-fit min-h-20 px-10 py-2 flex items-center justify-between bg-white border-t border-neutral-100 xs:px-8',
                )}
            >
                <div className={cn('min-h-fit')}>
                    {hasVisibleData ? filterView : <div className={cn('h-[32px]')} />}
                </div>
                <div className={cn('pl-8 flex justify-end xs:pl-4 xs:overflow-hidden')}>
                    <div className={cn('relative max-w-full ml-auto')}>
                        {initiatedRecruiterAv && (
                            <div
                                className={cn(
                                    'relative max-w-full min-w-[100px] xs:min-w-24',
                                )}
                            >
                                <YourAvButton
                                    openDialog={openSetAvailabilityDialog}
                                    dismissOthersAndOpenDialog={
                                        openSetAvailabilityDialogDismissOthers
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidatesTopRow;
