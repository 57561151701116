import { AppDispatch, GetRootState, RootState } from '@/_helpers/store';
import { CurrentUser, RecruiterAvailabilityFetcherData } from '@/_selectors';
import apiClient from '@/lib/api';
import { DayOfWeek, models } from '@/lib/api/v1';
import { FetchModelStatus, FetcherState, TimeIntervalWithDay } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const initRecruiterAvailability =
    () => (dispatch: AppDispatch, getState: GetRootState) => {
        const { initiated, isLoading } = RecruiterAvailabilityFetcherData(getState());
        if (!initiated && !isLoading) {
            dispatch(fetchRecruiterAvailability());
        }
    };

export const fetchRecruiterAvailability = createAsyncThunk<
    models['RecruiterAvailabilityOut__ApiModel'][],
    undefined,
    { state: RootState }
>('recruiterAvailability/fetchAvailability', async (_, { getState }) => {
    const recruiterId = CurrentUser(getState())!.id;
    const response = await apiClient.GET(
        '/api/v1/recruiters/{recruiter_id}/availability',
        { params: { path: { recruiter_id: recruiterId } } },
    );
    return response.data!.items;
});

export const resetRecruiterAvailability = () => (dispatch: AppDispatch) => {
    dispatch(recruiterAvailabilitySlice.actions.reset());
};

export const putRecruiterAvailability =
    (intervals: TimeIntervalWithDay[]) =>
    async (dispatch: AppDispatch, getState: GetRootState) => {
        const body = intervals.map(({ day, start, end }) => ({
            dayOfWeek: day as DayOfWeek,
            startTime: start.toJSON(),
            endTime: end.toJSON(),
        }));
        const recruiterId = CurrentUser(getState())!.id;
        const response = await apiClient.PUT(
            '/api/v1/recruiters/{recruiter_id}/availability',
            { params: { path: { recruiter_id: recruiterId } }, body },
        );

        dispatch(recruiterAvailabilitySlice.actions.updatedAvailability(response.data));
    };

type RecruiterAvailabilityRange = models['RecruiterAvailabilityOut__ApiModel'];

interface RecruiterAvailabilityState {
    data: RecruiterAvailabilityRange[];
    fetcher: FetcherState;
}

const initialState = {
    data: [],
    fetcher: generateEmptyFetcherState(),
} as RecruiterAvailabilityState;

const recruiterAvailabilitySlice = createSlice({
    name: 'recruiterAvailability',
    initialState,
    reducers: {
        updatedAvailability: (
            state,
            action: PayloadAction<RecruiterAvailabilityRange[] | undefined>,
        ) => {
            if (action.payload) {
                state.data = action.payload;
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRecruiterAvailability.pending, (state) => {
                state.fetcher.status = FetchModelStatus.LOADING;
            })
            .addCase(fetchRecruiterAvailability.fulfilled, (state, action) => {
                state.fetcher.status = FetchModelStatus.SUCCESS;
                state.fetcher.initiated = true;
                if (action.payload) {
                    state.data = action.payload;
                }
            })
            .addCase(fetchRecruiterAvailability.rejected, (state, action) => {
                state.fetcher.status = FetchModelStatus.FAILURE;
                state.fetcher.error = action.error.message;
            });
    },
});

export default recruiterAvailabilitySlice;
