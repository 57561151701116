import { isOverflown } from '@/lib/utils';
import { useDrag } from '@use-gesture/react';
import { useCallback, useEffect } from 'react';

import { useDialogContext } from './context';

const DEFAULT_DRAG_TO_CLOSE = 120;
const DEFAULT_DRAG_TO_TOP = 15;

export function useDragToCloseDialogListener() {
    const {
        open,
        isExpandedToTop,
        setIsExpandedToTop,
        draggedDownPosition,
        setDraggedDownPosition,
        dialogContentRef,
    } = useDialogContext();

    useEffect(() => {
        if (open) {
            setDraggedDownPosition(0);
        } else {
            setIsExpandedToTop(false);
        }
    }, [open, setDraggedDownPosition, setIsExpandedToTop]);

    useEffect(() => {
        if (
            dialogContentRef.current?.getBoundingClientRect().top === 0 &&
            !isExpandedToTop
        ) {
            setIsExpandedToTop(true);
        }
    }, [draggedDownPosition, isExpandedToTop, setIsExpandedToTop, dialogContentRef]);

    return {
        draggedDownPosition: Math.floor(draggedDownPosition),
        dialogContentRef,
        isExpandedToTop,
    };
}

export function useDragToCloseDialogDispatcher(allowWhenScrollable = false) {
    const {
        handleClose,
        isExpandedToTop,
        setIsExpandedToTop,
        draggedDownPosition,
        setDraggedDownPosition,
        dialogContentRef,
        scrollRef,
    } = useDialogContext();

    const handleDrag = useCallback(
        (y: number, onGoing: boolean) => {
            const hasOverflow = isOverflown(scrollRef);
            if (y < 0 && !hasOverflow) {
                setIsExpandedToTop(true);
                return;
            }
            if (isExpandedToTop && hasOverflow && !allowWhenScrollable) {
                return;
            }
            if (onGoing) {
                setDraggedDownPosition(y);
            } else {
                if (draggedDownPosition < -DEFAULT_DRAG_TO_TOP) {
                    setIsExpandedToTop(true);
                }
                const dialogHeight = dialogContentRef.current?.offsetHeight || 0;
                if (y > DEFAULT_DRAG_TO_CLOSE || y > dialogHeight / 3) {
                    handleClose();
                } else {
                    setDraggedDownPosition(0);
                }
            }
        },
        [
            draggedDownPosition,
            handleClose,
            setIsExpandedToTop,
            setDraggedDownPosition,
            scrollRef,
            allowWhenScrollable,
            isExpandedToTop,
            dialogContentRef,
        ],
    );

    const bindDragProps = useDrag(
        ({ down, movement: [, y] }) => {
            handleDrag(y, down);
        },
        { bounds: { top: 0 }, rubberband: true },
    );

    useEffect(() => {
        const scrollElement = scrollRef.current;

        const preventWheelScroll = (event: WheelEvent) => {
            if (!isExpandedToTop) {
                const y = Math.ceil(draggedDownPosition - event.deltaY);
                if (y < 0) {
                    handleDrag(y, true);
                }
                event.preventDefault();
            }
        };
        const expandNoScroll = (event: TouchEvent) => {
            if (!isExpandedToTop) {
                event.preventDefault();
            }
        };

        if (scrollElement) {
            scrollElement.addEventListener('touchmove', expandNoScroll, {
                passive: false,
            });
            scrollElement.addEventListener('wheel', preventWheelScroll, {
                passive: false,
            });
            return () => {
                scrollElement.removeEventListener('touchmove', expandNoScroll);
                scrollElement.removeEventListener('wheel', preventWheelScroll);
            };
        }
    }, [isExpandedToTop, scrollRef, handleDrag, draggedDownPosition]);

    return {
        bindDragProps,
    };
}
