import useHover from '@/hooks/useHover';
import useMutationObserver from '@/hooks/useMutationObserver';
import useResizeObserver from '@/hooks/useResizeObserver';
import useScrollbarWidth from '@/hooks/useScrollbarWidth';
import { cn } from '@/lib/utils';
import { debounce } from 'lodash';
import { HTMLAttributes, useMemo, useRef, useState } from 'react';

function doesHaveVerticalScrollbar(container: HTMLDivElement | null) {
    if (!container) return false;
    return Math.abs(container.scrollHeight - container.clientHeight) > 2;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    childContainerProps?: HTMLAttributes<HTMLDivElement>;
}

function ScrollOnHoverVerticalSafari({
    children,
    className,
    childContainerProps,
    ...props
}: Props) {
    const scrollbarWidth = useScrollbarWidth();
    const containerRef = useRef<HTMLDivElement>(null);
    const hovering = useHover(containerRef);
    const [hasOverflow, setHasOverflow] = useState(false);
    const checkHasOverflow = useMemo(
        () =>
            debounce(
                () => {
                    setHasOverflow(doesHaveVerticalScrollbar(containerRef.current));
                },
                50,
                { trailing: true },
            ),
        [],
    );
    useMutationObserver(containerRef, checkHasOverflow);
    useResizeObserver(containerRef, checkHasOverflow);
    return (
        <div
            ref={containerRef}
            className={cn(
                'relative h-full overflow-y-auto',
                '[&:not(:hover)]:hidden-scrollbar',
                className,
            )}
            style={{
                paddingRight: hovering && hasOverflow ? 0 : scrollbarWidth,
            }}
            {...props}
        >
            <div
                {...childContainerProps}
                className={cn('relative h-full', childContainerProps?.className)}
            >
                {children}
            </div>
        </div>
    );
}

export default ScrollOnHoverVerticalSafari;
