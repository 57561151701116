import { Button } from '@/components/ui';
import { Env } from '@/lib/types';
import { cn } from '@/lib/utils';
import { PinIcon, VenetianMaskIcon } from 'lucide-react';

import EnvIndication from './EnvIndication';
import { ImpersonationContentProps } from './types';

function ImpersonationIndicationContent({
    pinned,
    setPinned,
    exitImpersonation,
    impersonatedUser,
    env,
}: ImpersonationContentProps) {
    const isProd = env === Env.Prod;
    const togglePinned = () => setPinned(!pinned);
    const collapsed = !pinned && !isProd;
    return (
        <div className={cn('flex items-center justify-between w-full')}>
            <div className={cn('flex items-center pr-12', { 'pr-2': collapsed })}>
                <VenetianMaskIcon
                    className={cn('h-10 w-10  stroke-white', {
                        'stroke-gray-700': !isProd,
                    })}
                />
                <p
                    className={cn('ml-4 text-white text-15 font-medium', {
                        hidden: collapsed,
                        'text-gray-700': !isProd,
                    })}
                >
                    {'Impersonating:'}
                    <span
                        className={cn('ml-2 underline')}
                    >{`${impersonatedUser.name}`}</span>
                    <span
                        className={cn('font-bold')}
                    >{` (${impersonatedUser.email})`}</span>
                </p>
            </div>
            <div className={cn('flex items-center')}>
                <div
                    className={cn(
                        'mr-4 p-2 rounded-full cursor-pointer hover:bg-black/10 group',
                        { 'mr-0': collapsed },
                    )}
                    onClick={togglePinned}
                >
                    <PinIcon
                        className={cn('h-6 w-6 group-hover:fill-black/30', {
                            'group-hover:fill-black/10 fill-black/40': pinned,
                        })}
                    />
                </div>
                <EnvIndication pinned={pinned} env={env} />
                <Button
                    size={'sm'}
                    variant={'neutral'}
                    onClick={exitImpersonation}
                    className={cn({ hidden: collapsed })}
                >
                    Exit
                </Button>
            </div>
        </div>
    );
}

export default ImpersonationIndicationContent;
