import { AvailabilityMissing } from '@/components/lotties';
import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { candidates } from '@/strings';

const strings = candidates.newRequests.missingRecruiterAvailabilityDialog;

export function WeeklyAvMissingDialogContent({
    openRecruiterAvailabilityDialog,
}: {
    openRecruiterAvailabilityDialog: () => void;
}) {
    return (
        <div>
            <div className={cn('flex flex-col items-center px-[100px] xs:px-8')}>
                <div
                    className={cn(
                        'flex justify-center items-center w-[230px] h-[230px]',
                    )}
                >
                    <AvailabilityMissing />
                </div>
                <div className={cn('text-center max-w-[450px]')}>
                    <p className={cn('mt-10 text-20 leading-[20px] font-bold')}>
                        {strings.title}
                    </p>
                    <p className={cn('mt-4 text-14 font-medium')}>
                        {strings.description}
                    </p>
                </div>
            </div>
            <div className={cn('flex justify-center mt-10 mb-24')}>
                <Button
                    variant={'default'}
                    size={'lg'}
                    onClick={openRecruiterAvailabilityDialog}
                >
                    {strings.openAvailabilityButton}
                </Button>
            </div>
        </div>
    );
}

export default WeeklyAvMissingDialogContent;
