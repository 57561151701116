import { useAppDispatch } from '@/_helpers/store';
import { fetchUserNameFromDB } from '@/_reducers/auth';
import { CurrentAuthUser } from '@/_selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function useCurrentUserNameFromDB() {
    const dispatch = useAppDispatch();
    const currentUser = useSelector(CurrentAuthUser);
    const { name, id } = currentUser ?? { id: undefined, name: undefined };

    useEffect(() => {
        if (!name && id) {
            dispatch(fetchUserNameFromDB());
        }
    }, [name, id, dispatch]);
}

export default useCurrentUserNameFromDB;
