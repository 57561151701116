import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';

import ProcessBlockMobile from './ProcessBlockMobile';

function InProcessCollectionMobile({
    processes,
    onProcessClick,
}: {
    processes: Process[];
    onProcessClick: (processId: string) => void;
}) {
    return (
        <div
            className={cn('w-full h-full relative flex flex-wrap justify-center gap-6')}
        >
            {processes.map((process) => (
                <ProcessBlockMobile
                    key={process.id}
                    process={process}
                    onClick={() => {
                        trackEvent(MixpanelEvent.ExpandCandidateInProcess, {
                            candidate_id: process.candidate.id,
                            candidate_name: process.candidate.name,
                            via: 'in_process_block',
                        });
                        onProcessClick(process.id);
                    }}
                />
            ))}
        </div>
    );
}

export default InProcessCollectionMobile;
