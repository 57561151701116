import {
    DIALOG_ANIMATION_LENGTH,
    MOBILE_DIALOG_ANIMATION_LENGTH,
} from '@/_reducers/dialogs';

import { useIsMobileScreen } from './useMediaQueries';

function useDialogAnimationLength() {
    const isMobileScreen = useIsMobileScreen();
    return isMobileScreen ? MOBILE_DIALOG_ANIMATION_LENGTH : DIALOG_ANIMATION_LENGTH;
}

export default useDialogAnimationLength;
