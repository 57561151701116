import { useEffect, useState } from 'react';

function useIsRendered() {
    const [isRendered, setIsRendered] = useState(true);

    useEffect(() => {
        setIsRendered(true);
        return () => {
            setIsRendered(false);
        };
    }, []);

    return isRendered;
}

export default useIsRendered;
