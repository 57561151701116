const strings = {
    signInButton: 'Sign in',
    loginTitle: 'Sign in to Carefam',
    emailInput: {
        label: 'Email address',
        placeholder: 'Johndoe@gmail.com',
        errorMsg: 'This email is not registered with Carefam.',
        emailMissingFromCacheErrorMsg: 'Please re-enter your email to log in.',
    },
    emailSent: {
        title: 'Check your email',
        subtitle: {
            func: (email: string) => `We sent you a login link to ${email}`,
            example: ['[john-doe@gmail.com]'],
        },
        goBackButton: 'Back',
    },
};

export default strings;
