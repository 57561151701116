import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useSetHash = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const setHash = useCallback(
        (newHash: string) => {
            navigate(
                {
                    pathname: location.pathname,
                    search: location.search,
                    hash: newHash,
                },
                { replace: true },
            );
        },
        [navigate, location.pathname, location.search],
    );

    return setHash;
};

export default useSetHash;
