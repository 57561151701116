// / <reference types="vite-plugin-svgr/client" />
import { ArrowDown } from '@/components/svgs';
import { cn } from '@/lib/utils';
import { useHover } from '@uidotdev/usehooks';
import { type AnimationProps, motion } from 'framer-motion';
import React, { ReactNode, useState } from 'react';

import { TableCell, TableRow } from './base';
import { ExpandableRowProps, Model } from './types';

interface Props extends Omit<AnimationProps, 'transition'> {
    children: ReactNode;
    className?: string;
}

const Transition = ({ children, className, ...restProps }: Props) => {
    return (
        <motion.div
            {...restProps}
            transition={{ type: 'spring', stiffness: 350, damping: 30, mass: 1 }}
            className={className}
        >
            {children}
        </motion.div>
    );
};

const ExpandableRow = ({
    row,
    cells,
    cellClass,
    expanded,
    onClick,
    getExpandContent,
}: ExpandableRowProps<Model>) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [hoverRefRow, hoveringRow] = useHover();
    const [hoverRefExpandable, hoveringExpandable] = useHover();
    const hovering = hoveringRow || hoveringExpandable;

    const toggleExpand = () => {
        setIsAnimating(true);
        onClick(row.id, expanded);
        setTimeout(() => {
            setIsAnimating(false);
        }, 700);
    };

    return (
        <>
            <TableRow
                ref={hoverRefRow}
                className={cn('cursor-pointer transition-all duration-700 border-b-0', {
                    'duration-0': !isAnimating && !expanded,
                    'bg-accent500': expanded,
                    'bg-accent100': !expanded && hovering,
                })}
                onClick={toggleExpand}
            >
                {cells.map((cell) => (
                    <TableCell
                        className={cn('pb-8', cellClass, cell.classes?.cell)}
                        key={cell.id}
                    >
                        <Transition
                            animate={{ opacity: expanded && cell.hideOnExpand ? 0 : 1 }}
                        >
                            {cell.getter(row, expanded, hovering)}
                        </Transition>
                    </TableCell>
                ))}
                <TableCell
                    className={cn('pb-8 opacity-0', {
                        'opacity-100': expanded || hovering,
                    })}
                >
                    <Transition
                        className={cn('inline-block')}
                        animate={{
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                    >
                        <ArrowDown />
                    </Transition>
                </TableCell>
            </TableRow>
            <TableRow
                ref={hoverRefExpandable}
                onClick={!expanded ? toggleExpand : undefined}
                className={cn('transition-color duration-700', {
                    'duration-0': !isAnimating && !expanded,
                    'cursor-pointer ': !expanded,
                    'bg-accent500 border-b-accent500': expanded,
                    'bg-accent100': !expanded && hovering,
                })}
            >
                <td colSpan={20} className={cn('px-8 py-0')}>
                    <Transition
                        initial={false}
                        animate={{ height: expanded ? 'auto' : 18 }}
                        className={cn('overflow-hidden')}
                    >
                        <div
                            className={cn('transition-all duration-300 pt-6 pb-0', {
                                'pt-0 pb-6': expanded,
                            })}
                        >
                            {getExpandContent(row, expanded)}
                        </div>
                    </Transition>
                </td>
            </TableRow>
        </>
    );
};

export default React.memo(ExpandableRow);
