import { SelectionButton } from '@/components/ui';
import InProcessActionsView from '@/inProcessActionsMenu/InProcessActionsView';
import { Process } from '@/lib/types';
import { cn, isInProcess, isNewProcess } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import NewRequestActionsViewProvider from '@/newRequests/NewRequestActionsViewProvider';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

function NewProcessActions({ process }: { process: Process }) {
    const [loading, setLoading] = useState(false);
    return (
        <NewRequestActionsViewProvider
            process={process}
            loading={loading}
            setLoading={setLoading}
            containerProps={{ className: cn('xs:w-full xs:max-w-[550px]') }}
            rejectButtonProps={{ className: cn('w-[98px] xs:w-full') }}
            approveButtonProps={{ className: cn('xs:w-full') }}
        />
    );
}

function InProcessActions({ process }: { process: Process }) {
    const [open, setOpen] = useState(false);
    return (
        <InProcessActionsView
            rootProps={{ className: cn('xs:w-full xs:max-w-[400px]') }}
            triggerProps={{ asChild: true }}
            trigger={
                <div>
                    <SelectionButton
                        open={open}
                        label={dialogsStrings.candidateDetails.actionsLabel}
                        size={'default'}
                        className={cn('xs:w-full')}
                        onClick={() => {
                            trackEvent(MixpanelEvent.CandidateDialogOpenActionsMenu, {
                                candidate_id: process.candidate.id,
                                candidate_name: process.candidate.name,
                            });
                        }}
                    />
                </div>
            }
            process={process}
            open={open}
            setOpen={setOpen}
        />
    );
}

export function CandidateActions({ process }: { process: Process }) {
    const isNew = isNewProcess(process);
    const showInProcessActions = isInProcess(process);
    return (
        <>
            {isNew && <NewProcessActions process={process} />}
            {showInProcessActions && <InProcessActions process={process} />}
        </>
    );
}

export default CandidateActions;
