import useCurrentPageId from '@/hooks/useCurrentPageId';

import NavItem from './navItem';
import { NavItemsMenuProps } from './types';
import useNavItemsConfig from './useNavItemsConfig';

function NavItemsMenu({ onHoverClass }: NavItemsMenuProps) {
    const items = useNavItemsConfig();
    const selectedPage = useCurrentPageId();

    return (
        <div>
            {items.map((item) => (
                <NavItem
                    key={item.id}
                    label={item.label}
                    action={item.action}
                    selected={selectedPage === item.id}
                    onHoverClass={onHoverClass}
                    Icon={item.Icon}
                />
            ))}
        </div>
    );
}

export default NavItemsMenu;
