import { CurrentUserShortLetters } from '@/_selectors';
import { cn } from '@/lib/utils';
import { useSelector } from 'react-redux';

function UserProfileCircle() {
    const userLetters = useSelector(CurrentUserShortLetters);
    return (
        <div
            className={cn(
                'w-12 h-12 rounded-full bg-primary flex justify-center items-center',
            )}
        >
            <p className={cn('text-14 font-semibold text-white')}>{userLetters}</p>
        </div>
    );
}

export default UserProfileCircle;
