import { TerminateIcon } from '@/components/svgs';
import { Button, LoadingButton, useToast } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { useState } from 'react';

import { RejectCandidateDialogFooterProps } from './types';

const strings = dialogsStrings.rejectCandidate;

export function RejectCandidateDialogFooter({
    process,
    handleClose,
    terminate,
    reason,
    freeText,
}: RejectCandidateDialogFooterProps) {
    const dialogAnimationLength = useDialogAnimationLength();
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const submit = async () => {
        setIsLoading(true);
        trackEvent(MixpanelEvent.NewRequestConfirmTerminateCandidate, {
            candidate_name: process.candidate.name,
            candidate_id: process.candidate.id,
        });
        await terminate({
            reason,
            additionalText: freeText,
        })
            .then(() => {
                handleClose();
                setTimeout(() => {
                    toast({
                        title: strings.submit.successTitle,
                        description: strings.submit.successSubtitle.func(
                            process.candidate.name,
                        ),
                        thumbnailProps: { variant: 'destructive' },
                        icon: <TerminateIcon />,
                    });
                }, dialogAnimationLength);
            })
            .catch((error) => {
                toast({
                    title: strings.submit.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={cn('w-full flex gap-6')}>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <Button
                    className={cn('mr-6 w-full')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {strings.cancelButton}
                </Button>
            </div>
            <div className={cn('relative basis-1/2 flex-grow')}>
                <LoadingButton
                    className={cn('w-full')}
                    variant={'danger'}
                    size={'lg'}
                    onClick={submit}
                >
                    {strings.rejectButton}
                </LoadingButton>
            </div>
        </div>
    );
}

export default RejectCandidateDialogFooter;
