import { HeartLogo } from '@/components/svgs';
import { cn } from '@/lib/utils';

import EmailSentMobile from './EmailSentMobile';
import LoginBackgroundImage from './LoginBackgroundImage';
import SignInSectionMobile from './SignInSectionMobile';
import { LoginPageLayoutProps, Step } from './types';

function LoginPageMobileLayout({
    step,
    setStep,
    email,
    setEmail,
    error,
    setError,
    signInFromEmailLink,
}: LoginPageLayoutProps) {
    return (
        <div className={cn('relative flex flex-col items-center h-screen w-screen')}>
            <div
                className={cn(
                    'flex-grow flex-shrink w-full basis-2/5 overflow-hidden relative',
                )}
            >
                <LoginBackgroundImage withHeart={false} />
            </div>
            <div className={cn('relative w-full bg-white')}>
                <div
                    className={cn(
                        'relative w-full h-full flex flex-col justify-center items-center px-10 py-12 min-w-fit overflow-hidden',
                    )}
                >
                    <div className={cn('w-full')}>
                        {step === Step.SignIn && (
                            <SignInSectionMobile
                                error={error}
                                setError={setError}
                                email={email}
                                setEmail={setEmail}
                                signInFromEmailLink={signInFromEmailLink}
                                onEmailSent={() => {
                                    setStep(Step.EmailSent);
                                }}
                            />
                        )}
                        {step === Step.EmailSent && (
                            <EmailSentMobile
                                email={email}
                                goBack={() => {
                                    setStep(Step.SignIn);
                                }}
                            />
                        )}
                    </div>
                    <div className={cn('pt-20')}>
                        <HeartLogo className={cn('h-7')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPageMobileLayout;
