import { DayOfWeek, dayOfWeekOptions } from '@/lib/api/v1';
import { Time } from '@/lib/base';
import { AvailabilityRecordByDay } from '@/lib/types';
import {
    DEFAULT_AVAILABILITY_DAYS,
    MAX_AVAILABILITY_HOUR,
    MIN_AVAILABILITY_HOUR,
    eachTimeBlockOfInterval,
    generateDefaultDayAvailability,
    keysOf,
    valuesOf,
} from '@/lib/utils';
import { isEmpty, zipObject } from 'lodash';

import { AvailabilityFormSchema, DayOfWeekFormKey } from './types';

export function dayOfWeekToKey(day: DayOfWeek): DayOfWeekFormKey {
    return `day${day}`;
}

export function dayKeyToDayOfWeek(day: DayOfWeekFormKey): DayOfWeek {
    return parseInt(day.replace('day', '')) as DayOfWeek;
}

export function generateEmptyRecruiterAvailability() {
    return zipObject(
        dayOfWeekOptions.map(dayOfWeekToKey),
        dayOfWeekOptions.map((day) =>
            DEFAULT_AVAILABILITY_DAYS.includes(day)
                ? generateDefaultDayAvailability()
                : [],
        ),
    ) as AvailabilityFormSchema;
}

export function avRecordToInputSchema(
    avRecord: AvailabilityRecordByDay,
): AvailabilityFormSchema {
    const res = generateEmptyRecruiterAvailability();
    for (const day of keysOf(res)) {
        res[day] = avRecord[dayKeyToDayOfWeek(day)];
    }
    return res;
}

export function avInputSchemaToAvRecord(
    input: AvailabilityFormSchema,
): AvailabilityRecordByDay {
    return zipObject(
        keysOf(input).map(dayKeyToDayOfWeek),
        valuesOf(input),
    ) as AvailabilityRecordByDay;
}

export function isEmptAvInputSchema(input: AvailabilityFormSchema) {
    for (const value of valuesOf(input)) {
        if (!isEmpty(value)) {
            return false;
        }
    }
    return true;
}

export const SELECTABLE_TIMES = eachTimeBlockOfInterval(
    new Time(MIN_AVAILABILITY_HOUR),
    new Time(MAX_AVAILABILITY_HOUR),
);
