import { cn } from '@/lib/utils';

import { ClickableIconProps } from './types';

function ClickableIcon({ children, onClick, className }: ClickableIconProps) {
    return (
        <div
            className={cn(
                'w-7 h-7 flex items-center justify-center rounded-2xl hover:bg-black/10 cursor-pointer',
                'transtion-colors ease-linear duration-100',
                { 'opacity-0 pointer-events-none': !onClick },
                className,
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

export default ClickableIcon;
