import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

import { Button } from './button';

export interface ButtonOptionConfig<T extends string = string> {
    id: T;
    label: ReactNode;
}

export interface ButtonsGroupProps<T extends string> {
    buttons: ButtonOptionConfig<T>[];
    value: T;
    setValue: (value: T) => void;
}

const ButtonsGroup = <T extends string>({
    buttons,
    value,
    setValue,
}: ButtonsGroupProps<T>) => {
    return (
        <div className={cn('flex items-center flex-wrap gap-3')}>
            {buttons.map((button) => {
                const selected = button.id === value;
                return (
                    <Button
                        key={button.id}
                        variant={selected ? 'default' : 'neutral'}
                        className={cn(
                            'py-[5px] px-[20px] text-12 transition-colors duration-200 h-10',
                        )}
                        size={'sm'}
                        onClick={() => setValue(button.id)}
                    >
                        {button.label}
                    </Button>
                );
            })}
        </div>
    );
};

export { ButtonsGroup };
