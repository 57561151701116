import { createContext, useCallback, useState } from 'react';

import {
    DialogContext,
    DialogContextGetter,
    DialogContextSetter,
    DialogsData,
} from './types';

export const DialogsContext = createContext({} as DialogContext);

function DialogsContextProvider({ children }: { children: React.ReactNode }) {
    const [dialogsContext, setDialogsContext] = useState<DialogsData>({});

    const setContextForDialog = useCallback<DialogContextSetter>(
        (dialogName, data) => {
            setDialogsContext((prev) => ({
                ...prev,
                [dialogName]: data,
            }));
        },
        [setDialogsContext],
    );

    const getContextForDialog = useCallback<DialogContextGetter>(
        (dialogName) => dialogsContext[dialogName],
        [dialogsContext],
    );

    return (
        <DialogsContext.Provider
            value={{
                getContextForDialog,
                setContextForDialog,
            }}
        >
            {children}
        </DialogsContext.Provider>
    );
}

export default DialogsContextProvider;
