import { dayOfWeekOptions } from '@/lib/api/v1';

import DayAvailability from './DayAvailability';

function AvailabilityForm() {
    return (
        <>
            {dayOfWeekOptions.map((day) => (
                <DayAvailability key={day} day={day} />
            ))}
        </>
    );
}

export default AvailabilityForm;
