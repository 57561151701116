import { createSelector } from 'reselect';

import { ImpersonatedUser, IsOperator } from '../generation_1';

export const ImpersonationControllerSelector = createSelector(
    ImpersonatedUser,
    IsOperator,
    (impersonatedUser, isOperator) => ({
        impersonatedUser,
        isOperator,
    }),
);
