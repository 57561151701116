import { Env } from './lib/types';

export default {
    API_BASE_URL: import.meta.env.VITE_API_URL ?? '',
    FIREBASE_API_KEY: import.meta.env.VITE_PUBLIC_FIREBSE_API_KEY,
    FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_PUBLIC_FIREBASE_AUTH_DOMAIN,
    ENV: import.meta.env.VITE_ENV ?? Env.Dev,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    TEST_SENTRY_ON_DEV: import.meta.env.VITE_TEST_SENTRY_ON_DEV,
    MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
    TEST_MIXPANEL_ON_DEV: import.meta.env.VITE_TEST_MIXPANEL_ON_DEV,
    PREVENT_LOG_MIXPANEL_ON_DEV: import.meta.env.VITE_PREVENT_LOG_MIXPANEL_ON_DEV,
    DEFAULT_TOKEN_TTL_DAYS: 365,
    PROD_URL: 'app.carefam.com',
    STAGING_URL: 'app-staging.carefam.com',
};
