export type ConvertDatesToStrings<T> = {
    [K in keyof T]: T[K] extends Date
        ? string
        : T[K] extends Array<infer R>
          ? Array<ConvertDatesToStrings<R>>
          : T[K] extends object
            ? ConvertDatesToStrings<T[K]>
            : T[K];
};

export type DeepPartialTillLeaf<T, L> = {
    [K in keyof T]?: T[K] extends L ? T[K] : DeepPartialTillLeaf<T[K], L>;
};

export type PartialWithRequired<T, K extends keyof T> = Partial<T> &
    Required<Pick<T, K>>;

export type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type DivAttributes = React.HTMLAttributes<HTMLDivElement>;
export type SVGIconComponent = React.FC<React.SVGProps<SVGSVGElement>>;

export enum CookieNames {
    authToken = 'creafamFirebaseToken',
}
