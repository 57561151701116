import { Profession } from '@/lib/types';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { oneLineFacilities, professionToName } from '../common';

const strings = {
    candidateDescription: {
        func: (profession: Profession, firstFacility: string, jobsCount: number) =>
            `${professionToName[profession]} • ${oneLineFacilities.func(firstFacility, jobsCount)}`,
        example: [Profession.CNA, 'Golden Care at Petah Tikva', 3],
    },
    candidateTitle: {
        noEmail: '(no email)',
        phoneNumber: {
            func: (phone: string) =>
                parsePhoneNumberFromString(phone)?.formatNational() ?? phone,
            example: ['+15082395954'],
        },
    },
};

export default strings;
