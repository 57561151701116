import { Dialog, useToast } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { EventType } from '@/lib/api/v1';
import { cn } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';
import { useEffect, useState } from 'react';

import CancelEventStep from './CancelEventStep';
import EventDialogTitle from './EventDialogTitle';
import InterviewEventContent from './InterviewEventContent';
import OrientationDayEventContent from './OrientationDayEventContent';
import RequestRescheduleSuccessContent from './RequestRescheduleSuccessContent';
import { DialogStep, EventDetailsDialogProps } from './types';

const strings = dialogsStrings.eventDetails;

function EventDetailsDialog({
    isOpen,
    setOpen,
    event,
    goToTerminate,
    onReschedule,
}: EventDetailsDialogProps) {
    const [step, setStep] = useState<DialogStep>(DialogStep.EventDetails);
    const { toast } = useToast();
    const dialogAnimationLength = useDialogAnimationLength();

    const reschedule = async () => {
        return await onReschedule()
            .then(() => {
                setStep(DialogStep.RescheduleSuccess);
            })
            .catch((error) => {
                toast({
                    title: strings.reschedule.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            });
    };

    const goToCancelEvent = () => {
        setStep(DialogStep.CancelEvent);
    };

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => setStep(DialogStep.EventDetails), dialogAnimationLength);
        }
    }, [isOpen, setStep, dialogAnimationLength]);

    const colorClassesByEventType = {
        'bg-phoneInterview': event.eventType === EventType.PhoneInterview,
        'bg-inPersonInterview': event.eventType === EventType.InPersonInterview,
        'bg-orientation': event.eventType === EventType.Orientation,
        'bg-white': step !== DialogStep.EventDetails,
    };

    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            contentProps={{ className: cn('w-[650px] p-0') }}
            headerProps={{
                className: cn(colorClassesByEventType, {
                    'p-12 pb-0 border-0 xs:py-10 xs:px-10':
                        step === DialogStep.EventDetails,
                    'py-8': step === DialogStep.CancelEvent,
                    'py-8 border-0': step === DialogStep.RescheduleSuccess,
                }),
            }}
            title={<EventDialogTitle event={event} step={step} />}
            headerAnchorProps={{ className: cn(colorClassesByEventType) }}
        >
            {step === DialogStep.EventDetails && (
                <>
                    {[EventType.PhoneInterview, EventType.InPersonInterview].includes(
                        event.eventType,
                    ) && (
                        <InterviewEventContent
                            event={event}
                            reschedule={reschedule}
                            cacnelEvent={goToCancelEvent}
                        />
                    )}
                    {event.eventType === EventType.Orientation && (
                        <OrientationDayEventContent
                            event={event}
                            reschedule={reschedule}
                            cacnelEvent={goToCancelEvent}
                        />
                    )}
                </>
            )}
            {step === DialogStep.CancelEvent && (
                <CancelEventStep
                    reschedule={reschedule}
                    goToTerminate={goToTerminate}
                    event={event}
                />
            )}
            {step === DialogStep.RescheduleSuccess && (
                <RequestRescheduleSuccessContent candidate={event.process.candidate} />
            )}
        </Dialog>
    );
}

export default EventDetailsDialog;
