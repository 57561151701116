import { TimeIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import { getSubstatusText, shouldShowClockSvg } from './utils';

function InProcessSubstatus({ process }: { process: Process }) {
    const showClockSvg = shouldShowClockSvg(process);
    return (
        <div className={cn('flex items-center')}>
            {showClockSvg && (
                <TimeIcon
                    className={cn('stroke-neutral300 fill-neutral300 h-4 w-4 mr-2')}
                />
            )}
            <div className={cn(' text-neutral300 text-11')}>
                {getSubstatusText(process)}
            </div>
        </div>
    );
}

export default InProcessSubstatus;
