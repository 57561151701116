import { PageId } from '@/lib/types';
import { matchPath } from 'react-router-dom';

export const routeToPageId: Record<string, PageId> = {
    '/': PageId.Home,
    '/candidates': PageId.Candidates,
    // '/calendar': PageId.Calendar,
    // '/settings': PageId.Settings,
};

export function getCurrentPageId(pathname: string) {
    for (const [path, pageId] of Object.entries(routeToPageId)) {
        if (matchPath(pathname, path)) {
            return pageId;
        }
    }
}

export function getRouteForPageId(pageId: PageId) {
    for (const [path, page] of Object.entries(routeToPageId)) {
        if (page === pageId) {
            return path;
        }
    }
    return '';
}
