import { ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { Process, eventableProcessStatuses } from '@/lib/types';
import { processHistory as strings } from '@/strings';
import { getString } from '@/strings/common';

import { CurrentActionItemType } from './types';

export function getActionItemType(process: Process): CurrentActionItemType | null {
    if (eventableProcessStatuses.includes(process.status)) {
        if (process.substatus === ProcessSubstatus.Done) {
            return CurrentActionItemType.FeedbackRequired;
        }
        if (process.substatus === ProcessSubstatus.Scheduled) {
            return CurrentActionItemType.EventScheduled;
        }
        if (process.substatus === ProcessSubstatus.Pending) {
            return CurrentActionItemType.EventRequested;
        }
    }
    if (process.status === ProcessStatus.Paperwork) {
        return CurrentActionItemType.PendingPaperwork;
    } else if (
        process.status === ProcessStatus.Matchmaking &&
        !process.recruiterApproved
    ) {
        return CurrentActionItemType.PendingApproval;
    }
    return null;
}

export function hasCurrentActionItem(process: Process): boolean {
    return !!getActionItemType(process);
}

export function getActionItemStrings(process: Process): {
    title: string;
    subtitle: string;
} {
    const actionItemType = getActionItemType(process);
    if (!actionItemType) {
        return { title: '', subtitle: '' };
    }
    return {
        title: getString(process, strings.actionItemToStrings[actionItemType].title),
        subtitle: getString(
            process,
            strings.actionItemToStrings[actionItemType].subtitle,
        ),
    };
}
