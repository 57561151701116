import { IsWeeklyAvMissingDialogOpen } from '@/_selectors';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import YourAvButton from './YourAvButton';

function YourAvButtonShineable({
    openDialog,
    dismissOthersAndOpenDialog,
}: {
    openDialog: () => void;
    dismissOthersAndOpenDialog: () => void;
}) {
    const isMobileScreen = useIsMobileScreen();
    const isShining = useSelector(IsWeeklyAvMissingDialogOpen);
    const origButtonRef = useRef<HTMLDivElement>(null);
    const shineButtonRef = useRef<HTMLDivElement>(null);

    const adjustPosition = useCallback(() => {
        if (isShining) {
            const origButton = origButtonRef.current;
            const shineButton = shineButtonRef.current;
            if (origButton && shineButton) {
                const origRect = origButton.getBoundingClientRect();
                shineButton.style.top = `${origRect.top - 6}px`;
                shineButton.style.left = `${origRect.left - 12}px`;
            }
        }
    }, [isShining]);

    useLayoutEffect(() => {
        adjustPosition();
    }, [adjustPosition]);

    useEffect(() => {
        window.addEventListener('resize', adjustPosition);
        return () => window.removeEventListener('resize', adjustPosition);
    }, [adjustPosition]);

    return (
        <div className={cn('relative max-w-full')}>
            <div ref={origButtonRef} className={cn({ 'opacity-0': isShining })}>
                <YourAvButton onClick={openDialog} />
            </div>
            {isShining && (
                <div ref={shineButtonRef} className={cn('fixed z-[1000]')}>
                    <div
                        className={cn(
                            'relative animate-bounce cursor-pointer pointer-events-auto',
                        )}
                        onClick={dismissOthersAndOpenDialog}
                    >
                        <div
                            className={cn('bg-white py-2 px-4 rounded-xl', {
                                'shadow-elevation-300 border border-neutral300':
                                    isMobileScreen,
                            })}
                        >
                            <YourAvButton onClick={() => {}} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default YourAvButtonShineable;
