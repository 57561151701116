import { ProcessPendingEventFeedback, ProcessWithRelations } from '@/lib/types';
import { isPendingFeedback } from '@/lib/utils';
import { mapValues, pickBy } from 'lodash';
import { createSelector } from 'reselect';

import { ProcessEvents, Processes } from '../generation_1';

export const ProcessesWithRelations = createSelector(
    Processes,
    ProcessEvents,
    (processes, eventsById) => {
        return mapValues(processes, (process): ProcessWithRelations => {
            const relevantEvent = process.relevantEvent
                ? eventsById[process.relevantEvent?.id]
                : undefined;
            return { ...process, relevantEvent };
        });
    },
);

export const PendingFeedbackProcesses = createSelector(
    ProcessesWithRelations,
    (processes) =>
        pickBy(processes, (p) => isPendingFeedback(p)) as Record<
            string,
            ProcessPendingEventFeedback & ProcessWithRelations
        >,
);
