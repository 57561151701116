import { fetchProcessesPendingFeedback } from '@/_reducers/processes';
import { PendingFeedbackProcesses, PendingTasksFetcherData } from '@/_selectors';
import useFetchers from '@/hooks/useFetchers';
import useMissingEventsFetcher from '@/hooks/useMissingEventsFetcher';
import { valuesOf } from '@/lib/utils';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';

function usePendingTasks() {
    useFetchers(fetchProcessesPendingFeedback);
    const pendingFeedbackProcessesById = useSelector(PendingFeedbackProcesses);
    const {
        isLoading: isLoadingEvents,
        isMissingEvents,
        didInitialFetch,
    } = useMissingEventsFetcher();
    const { initiated: initiatedPendingTasks, isLoading: isLoadingPendingTasks } =
        useSelector(PendingTasksFetcherData);

    const isLoadingTasks = isLoadingPendingTasks || isLoadingEvents;
    const initiatedTasks =
        initiatedPendingTasks && (!isMissingEvents || didInitialFetch);
    const processes = sortBy(
        valuesOf(pendingFeedbackProcessesById),
        (process) => -process.relevantEvent.endAt,
    );
    return {
        isLoadingTasks,
        initiatedTasks,
        processes,
    };
}

export default usePendingTasks;
