import { cn } from '@/lib/utils';

import { TableCell, TableRow } from './base';
import { Model, TableRowProps } from './types';

const Row = ({ row, cells, className, cellClass }: TableRowProps<Model>) => {
    return (
        <TableRow className={cn('relative cursor-defualt', className)}>
            {cells.map((cell) => (
                <TableCell key={cell.id} className={cn(cellClass, cell.classes?.cell)}>
                    {cell.getter(row)}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default Row;
