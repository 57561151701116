import { DialogName } from '@/lib/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DialogsSliceState, SetDialogDataPayload } from './types';

const initialState = { open: {}, data: {} } as DialogsSliceState;

const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        setOpen: (
            state,
            action: PayloadAction<{ dialog: DialogName; open: boolean }>,
        ) => {
            const { dialog, open } = action.payload;
            if (action.payload) {
                state.open[dialog] = open;
            }
        },
        closeAll: (state) => {
            state.open = {};
        },
        setDialogData: (state, action: PayloadAction<SetDialogDataPayload>) => {
            const { dialog, data } = action.payload;
            state.data = {
                ...state.data,
                [dialog]: data,
            };
        },
    },
});

export default dialogsSlice;
