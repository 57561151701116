import { CandidatesIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

function NoCandidatesView({ text }: { text: string }) {
    return (
        <div
            className={cn(
                'absolute flex items-center justify-center w-full h-full top-0 left-0',
            )}
        >
            <div className={cn('flex flex-col items-center')}>
                <CandidatesIcon className={cn('w-16 h-16 fill-black opacity-10')} />
                <p className={cn('font-semibold text-14 pt-4 opacity-20')}>{text}</p>
            </div>
        </div>
    );
}

export default NoCandidatesView;
