import { PhysicalAppointment } from '@/components/lotties';
import { PhoneIcon, TimeIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import useDialogAnimationLength from '@/hooks/useDialogAnimationLength';
import { Profession } from '@/lib/types';
import { cn, getFirstName } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { Building2 } from 'lucide-react';

import EventActions from './EventActions';
import { EventDetailsStepProps, ImplementedEventType } from './types';

const strings = dialogsStrings.eventDetails;

export function OrientationDayEventContent({
    event,
    reschedule,
    cacnelEvent,
}: EventDetailsStepProps) {
    const { process, startAt, endAt } = event;
    const { candidate } = process;
    const dialogAnimationLength = useDialogAnimationLength();

    const onRescheduleClick = async () => {
        trackEvent(MixpanelEvent.EventDetailsRescheduleEvent);
        await reschedule();
    };
    const onCancelClick = async () => {
        trackEvent(MixpanelEvent.EventDetailsCancelEvent);
        cacnelEvent();
    };
    return (
        <div className={cn('w-full')}>
            <div
                className={cn(
                    'flex bg-orientation items-start p-12 pb-14 xs:px-20 xs:pb-12 xs:pt-2',
                )}
            >
                <div
                    className={cn(
                        'flex flex-col items-center w-[122px] xs:w-20 ml-auto text-center',
                    )}
                >
                    <ProfessionAvatar
                        profession={candidate.profession as Profession}
                        className={cn(
                            'w-[100px] h-[100px] xs:w-20 xs:h-20 rounded-full outline-white outline-[3px] outline',
                        )}
                    />
                    <div className={cn('w-64 text-center')}>
                        <p className={cn('text-15 font-bold mt-4')}>{candidate.name}</p>
                        <p className={cn('text-11 font-medium')}>
                            {
                                strings.candidateSubtitle[
                                    candidate.profession as Profession
                                ]
                            }
                        </p>
                    </div>
                </div>
                <div
                    className={cn(
                        'h-[100px] w-[210px] xs:h-20 xs:min-w-[120px] mx-2 flex items-center',
                    )}
                >
                    <PhysicalAppointment delay={dialogAnimationLength + 500} />
                </div>
                <div
                    className={cn(
                        'flex flex-col items-center w-[122px] xs:w-20 mr-auto text-center',
                    )}
                >
                    <div
                        className={cn(
                            'w-[100px] h-[100px] xs:w-20 xs:h-20 bg-gray-100 rounded-full outline-white outline-[3px] outline flex items-center justify-center',
                        )}
                    >
                        <Building2
                            className={cn('h-20 w-20 xs:w-16 xs:h-16 stroke-slate-800')}
                        />
                    </div>
                    <div className={cn('w-64 xs:w-52 text-center')}>
                        <p className={cn('text-15 font-bold mt-4')}>
                            {event.facility?.name}
                        </p>
                        <p className={cn('text-11 font-medium')}>
                            {event.facility?.address}
                        </p>
                    </div>
                </div>
            </div>
            <div className={cn('p-12  pb-14')}>
                <div>
                    <div className={cn('flex items-center')}>
                        <div
                            className={cn(
                                'w-[45px] h-[45px] mr-5 bg-accent200 rounded-[8px] flex items-center justify-center',
                            )}
                        >
                            <TimeIcon className={cn('w-6 h-6')} />
                        </div>
                        <div>
                            <p className={cn('text-11')}>{strings.timeLabel}</p>
                            <p className={cn('text-14 font-medium mt-1')}>
                                {strings.dateAndDuration.func(startAt, endAt)}
                            </p>
                        </div>
                    </div>
                    <div className={cn('flex items-center mt-10')}>
                        <div
                            className={cn(
                                'w-[45px] h-[45px] mr-5 bg-accent200 rounded-[8px] flex items-center justify-center',
                            )}
                        >
                            <PhoneIcon className={cn('w-6 h-6')} />
                        </div>
                        <div>
                            <p className={cn('text-11')}>
                                {strings.phoneLabel.func(getFirstName(candidate))}
                            </p>
                            <p className={cn('text-14 font-medium mt-1')}>
                                {strings.phoneNumber.func(candidate.phone)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cn('flex justify-end px-10 py-8 border-t border-neutral100 ')}
            >
                <EventActions
                    eventType={event.eventType as ImplementedEventType}
                    reschedule={onRescheduleClick}
                    cancelEvent={onCancelClick}
                />
            </div>
        </div>
    );
}

export default OrientationDayEventContent;
