import {
    DialogNameWithData,
    openDialog,
    openDialogDismissOthers,
} from '@/_reducers/dialogs';
import { fetchNewProcesses } from '@/_reducers/processes';
import { fetchRecruiterAvailability } from '@/_reducers/recruiterAvailability';
import {
    NewProcesses,
    NewProcessesFetcherData,
    RecruiterAvailabilityFetcherData,
} from '@/_selectors';
import useFetchers from '@/hooks/useFetchers';
import { useIsMediumOrLessScreen } from '@/hooks/useMediaQueries';
import { DialogName } from '@/lib/types/dialogs';
import { valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import NewRequestsLayout from './NewRequestsLayout';
import NewRequestsMobileLayout from './NewRequestsMobileLayout';
import { DEFAULT_PROFESSION_FILTER } from './consts';
import { ProfFilter } from './types';

function NewRequests({
    openDialog,
    openDialogDismissOthers,
}: {
    openDialog: (dialog: DialogName) => void;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
}) {
    useFetchers(fetchRecruiterAvailability, fetchNewProcesses);
    const isMediumOrLess = useIsMediumOrLessScreen();
    const [profFilter, setProfFilter] = useState<ProfFilter>(DEFAULT_PROFESSION_FILTER);
    const modelsById = useSelector(NewProcesses);
    const { initiated: initiatedRecruiterAv } = useSelector(
        RecruiterAvailabilityFetcherData,
    );
    const { initiated: initiatedProcesses, isLoading: isLoadingProcesses } = useSelector(
        NewProcessesFetcherData,
    );
    const openSetAvailabilityDialog = () => openDialog(DialogName.RecruiterAvailability);
    const openSetAvailabilityDialogDismissOthers = () =>
        openDialogDismissOthers(DialogName.RecruiterAvailability);
    const models = valuesOf(modelsById);
    const totalNewCandidates = models.length;
    const noNewCandidates = totalNewCandidates === 0 && initiatedProcesses;

    const filteredModels =
        profFilter === DEFAULT_PROFESSION_FILTER
            ? models
            : models.filter((p) => p.candidate.profession === profFilter);

    const onFilterChange = (value: ProfFilter) => {
        trackEvent(MixpanelEvent.FilterNewCandidates, { filter_value: value });
        setProfFilter(value);
    };

    return isMediumOrLess ? (
        <NewRequestsMobileLayout
            processes={models}
            profFilter={profFilter}
            setProfFilter={onFilterChange}
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
            filteredProcesses={filteredModels}
            totalNewCandidates={totalNewCandidates}
            isLoadingProcesses={isLoadingProcesses}
            initiatedProcesses={initiatedProcesses}
            noNewCandidates={noNewCandidates}
        />
    ) : (
        <NewRequestsLayout
            processes={models}
            profFilter={profFilter}
            setProfFilter={onFilterChange}
            initiatedRecruiterAv={initiatedRecruiterAv}
            openSetAvailabilityDialog={openSetAvailabilityDialog}
            openSetAvailabilityDialogDismissOthers={
                openSetAvailabilityDialogDismissOthers
            }
            filteredProcesses={filteredModels}
            totalNewCandidates={totalNewCandidates}
            isLoadingProcesses={isLoadingProcesses}
            initiatedProcesses={initiatedProcesses}
            noNewCandidates={noNewCandidates}
        />
    );
}

const mapDispathToProps = {
    openDialog,
    openDialogDismissOthers,
};

export default connect(null, mapDispathToProps)(NewRequests);
