import { DayOfWeek, dayOfWeekOptions } from '@/lib/api/v1';
import { Time } from '@/lib/base';
import { addDays, format, formatDistanceToNow, startOfISOWeek } from 'date-fns';
import _ from 'lodash';

export const DAY_INDEX_TO_DATE_MAP = _.zipObject(
    dayOfWeekOptions,
    dayOfWeekOptions.map((day) => addDays(startOfISOWeek(new Date()), day)),
) as Record<DayOfWeek, Date>;

export function getDayFromDateMap(day: DayOfWeek) {
    return DAY_INDEX_TO_DATE_MAP[day];
}

export function capitalizeFirstLetter(str: string) {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getFirstName = (fullName: string) => {
    return fullName.split(' ')[0];
};

export function dateToDayNameShort(date: Date): string {
    return format(date, 'eee');
}

export function dateToDayNameLong(date: Date): string {
    return format(date, 'eeee');
}

export const timeToStringShort12 = (time: Time): string =>
    dateToTimeStringShort12(time.toDate());
export const timeToStringLong12 = (time: Time): string =>
    format(time.toDate(), 'hh:mm a');

export function formatAgoShort(date: Date): string {
    return `${formatDistanceToNow(date)} ago`
        .replace(/ seconds?/, 's')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y');
}

export const dateToLongMonthName = (date: Date): string => format(date, 'MMMM');
export const dateToShortMonthName = (date: Date): string => format(date, 'MMM');
export const dateToShortMonthNameUppercase = (date: Date): string =>
    dateToShortMonthName(date).toUpperCase();
export const dateToDayInMonthShort = (date: Date): string => format(date, 'd');
export const dateToTimeStringShort12 = (date: Date): string => format(date, 'h:mm a');

export const dayOfWeekToDayNameLong = (day: DayOfWeek): string =>
    dateToDayNameLong(getDayFromDateMap(day));
export const dayOfWeekToDayNameShort = (day: DayOfWeek): string =>
    dateToDayNameShort(getDayFromDateMap(day));

export const availableDaysOfWeekToString = (days: DayOfWeek[]): string => {
    const sortedDays = days.sort();
    const ranges = [];
    let rangeStart: DayOfWeek | undefined;
    let rangeEnd: DayOfWeek | undefined;
    for (let i = 0; i <= days.length; i++) {
        const currentDay = sortedDays[i];
        if (rangeStart === undefined || rangeEnd === undefined) {
            rangeStart = currentDay;
            rangeEnd = currentDay;
            continue;
        }
        if (currentDay !== undefined && currentDay - 1 === rangeEnd) {
            rangeEnd = currentDay;
        } else {
            ranges.push(
                rangeStart === rangeEnd
                    ? dayOfWeekToDayNameShort(rangeStart)
                    : `${dayOfWeekToDayNameShort(rangeStart)}-${dayOfWeekToDayNameShort(rangeEnd)}`,
            );
            rangeStart = currentDay;
            rangeEnd = currentDay;
        }
    }
    return ranges.join(', ');
};

export function getString<T>(
    input: T,
    stringObj: string | { func: (input: T) => string },
): string {
    if (typeof stringObj === 'string') {
        return stringObj;
    }
    return stringObj.func(input);
}
