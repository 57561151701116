import Loader from '@/components/lotties/Loader';
import useIsRendered from '@/hooks/useIsRendered';
import { cn } from '@/lib/utils';
import { useState } from 'react';

import { Button } from './base';
import { ButtonProps } from './types';

export interface LoadingButtonProps extends ButtonProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (...args: any) => Promise<any>;
}

function LoadingButton({
    onClick,
    children,
    disabled,
    variant,
    ...props
}: LoadingButtonProps) {
    const [loading, setLoading] = useState(false);
    const isRendered = useIsRendered();
    const onClickWrapper = async () => {
        setLoading(true);
        await onClick().finally(() => {
            if (isRendered) {
                setLoading(false);
            }
        });
    };
    const loaderVariant = variant === 'secondary' ? 'primary' : undefined;

    return (
        <Button
            className={cn({ 'cursor-default pointer-events-none': loading })}
            disabled={loading || disabled}
            onClick={onClickWrapper}
            variant={variant}
            {...props}
        >
            {!loading && <>{children}</>}
            {loading && (
                <>
                    <div className={cn('opacity-0')}>{children}</div>
                    <div
                        className={cn(
                            'absolute w-full h-full flex justify-center items-center',
                        )}
                    >
                        <Loader variant={loaderVariant} sizing={'md'} />
                    </div>
                </>
            )}
        </Button>
    );
}

export default LoadingButton;
