import Loader from '@/components/lotties/Loader';
import { LottieProps } from '@/components/lotties/types';
import { SVGIconComponent } from '@/lib/base';
import { cn } from '@/lib/utils';

function EmptySectionView({
    Icon,
    label,
    loading = false,
    iconProps,
    loaderProps,
}: {
    Icon: SVGIconComponent;
    label: string;
    loading?: boolean;
    iconProps?: React.HTMLAttributes<SVGElement>;
    loaderProps?: LottieProps;
}) {
    return (
        <div
            className={cn(
                'bg-white w-full rounded-2xl flex flex-col justify-center items-center py-[102px] gap-5',
                { 'bg-transparent': loading },
            )}
        >
            {loading && (
                <Loader
                    variant='primary'
                    sizing={'md'}
                    className={cn(loaderProps?.className)}
                />
            )}
            {!loading && (
                <>
                    <Icon
                        {...iconProps}
                        className={cn('fill-black/10', iconProps?.className)}
                    />
                    <div className={cn('font-semibold text-14 opacity-20')}>{label}</div>
                </>
            )}
        </div>
    );
}

export default EmptySectionView;
