import { Dialog } from '@/components/ui';
import { useIsMediumOrLessScreen, useIsMobileScreen } from '@/hooks/useMediaQueries';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import { BaseDialogPropsCommon } from '../types';
import CandidateHeader from './CandidateHeader';
import CandidateHeaderMobile from './CandidateHeaderMobile';
import { CandidateDetailsDialogContent } from './content';

interface Props extends BaseDialogPropsCommon {
    process: Process;
}

function CandidateDetailsDialog({ process, isOpen, setOpen, handleClose }: Props) {
    const isMobileScreen = useIsMobileScreen();
    const showMobileView = useIsMediumOrLessScreen();
    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            contentProps={{
                className: cn(
                    { 'max-w-9/10': !showMobileView },
                    { 'max-w-full w-[800px] relative': showMobileView },
                    { 'h-[95%]': showMobileView && !isMobileScreen },
                ),
            }}
            contentWrapperProps={{
                className: cn(
                    { 'max-w-[90vw] w-[1300px]': !showMobileView },
                    {
                        'w-[800px]': showMobileView,
                    },
                ),
            }}
            headerProps={{
                className: cn('py-8 pr-8'),
            }}
            title={
                showMobileView ? (
                    <CandidateHeaderMobile process={process} />
                ) : (
                    <CandidateHeader process={process} />
                )
            }
            withVerticalScroll={showMobileView}
        >
            <CandidateDetailsDialogContent process={process} handleClose={handleClose} />
        </Dialog>
    );
}

export default CandidateDetailsDialog;
