import { Dialog, DialogHeaderAnchor } from '@/components/ui/dialog';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';

import { Popover, PopoverContent, PopoverTrigger } from './base';
import { PopoverContext } from './context';
import { CustomPopoverProps } from './types';

export function CustomPopover({
    children,
    open: externalOpen,
    setOpen: setExternalOpen,
    trigger,
    popoverProps,
    triggerProps,
    contentProps,
}: CustomPopoverProps) {
    const { open, setOpen, handleClose, handleOpen, handleOpenChange } = useSetOpen(
        externalOpen,
        setExternalOpen,
    );
    const isMobileScreen = useIsMobileScreen();
    return (
        <PopoverContext.Provider value={{ handleClose, handleOpen }}>
            {isMobileScreen ? (
                <Dialog
                    trigger={trigger}
                    open={open}
                    setOpen={setOpen}
                    {...{ triggerProps }}
                    customHeader={<DialogHeaderAnchor />}
                >
                    {children}
                </Dialog>
            ) : (
                <Popover open={open} onOpenChange={handleOpenChange} {...popoverProps}>
                    <PopoverTrigger {...triggerProps}>{trigger}</PopoverTrigger>
                    <PopoverContent
                        sideOffset={16}
                        align={'start'}
                        alignOffset={-12}
                        {...contentProps}
                        className={cn(
                            'rounded-2xl overflow-hidden border-0 p-0 w-fit',
                            contentProps?.className,
                        )}
                    >
                        {children}
                    </PopoverContent>
                </Popover>
            )}
        </PopoverContext.Provider>
    );
}
