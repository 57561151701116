import { TimeIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount, getFirstName } from '@/lib/utils';
import { homepage as homepageStrings } from '@/strings';
import { formatAgoShort } from '@/strings/common';

import TaskBlockActions from '../TaskBlockActions';
import { TaskBlockProps } from '../types';

const strings = homepageStrings.tasks;

function TaskBlockMobile({
    taskType,
    process,
    event,
    openCandidateDialog,
}: TaskBlockProps) {
    const profession = process.candidate.profession as Profession;
    const candidateFirstName = getFirstName(process.candidate);
    const eventDate = new Date(event.endAt ? event.endAt : event.startAt);
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);

    return (
        <div
            className={cn('w-full bg-white rounded-2xl p-6 cursor-pointer')}
            onClick={openCandidateDialog}
        >
            <div className={cn('flex items-center gap-3')}>
                <ProfessionAvatar className={cn('h-12 w-12')} profession={profession} />
                <div>
                    <p className={cn('text-13 font-bold')}>{process.candidate.name}</p>
                    <p className={cn('text-11 font-medium')}>
                        {strings.candidateSubtitle.func(
                            profession,
                            facilityName,
                            facilitiesCount,
                        )}
                    </p>
                </div>
            </div>
            <div className={cn('mt-4')}>
                <p className={cn('text-16 font-bold')}>
                    {strings[taskType].questionTitle.func(candidateFirstName)}
                </p>
                <p className={cn('text-12 font-medium mt-2')}>
                    {strings[taskType].questionInfo.func(
                        candidateFirstName,
                        eventDate,
                        event.facility?.name ?? '',
                    )}
                </p>
            </div>
            <div className={cn('flex items-center justify-between mt-5')}>
                <TaskBlockActions process={process} taskType={taskType} />
                <div className={cn('flex items-center gap-2')}>
                    <p className={cn('text-neutral300 text-11 leading-[1] font-medium')}>
                        {formatAgoShort(eventDate)}
                    </p>
                    <TimeIcon
                        className={cn(
                            'h-[11px] w-[11px] fill-neutral300 stroke-neutral300',
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default TaskBlockMobile;
