import { CurrentUserDisplayName } from '@/_selectors';
import { ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { homepage as strings } from '@/strings';
import { useSelector } from 'react-redux';

import PanelRight from './PanelRight';
import TasksPanel from './TasksPanel';
import { HompageLayoutProps } from './types';

function HomePageLayout({
    pendingTasksProcesses,
    initiatedTasks,
    isLoadingTasks,
}: HompageLayoutProps) {
    const { firstName } = useSelector(CurrentUserDisplayName);
    return (
        <ScrollableFillerDiv
            rootDivProps={{ className: cn('bg-white') }}
            growDivProps={{ className: cn('p-12') }}
            fixedDiv={
                <div
                    className={cn(
                        'basis-[80px] shrink-0 px-12 flex items-center justify-between border-b border-neutral100',
                    )}
                >
                    <p className={cn('font-bold text-18')}>
                        {firstName ? strings.header.func(firstName) : null}
                    </p>
                    <div className={cn('px-[4px]')}></div>
                </div>
            }
        >
            <ScrollableFillerDiv
                rootDivProps={{ className: cn('flex-row-reverse w-full') }}
                contentWrapperProps={{ className: cn('w-full') }}
                className={cn('w-full')}
                fixedDiv={<PanelRight />}
            >
                <TasksPanel
                    processes={pendingTasksProcesses}
                    initiated={initiatedTasks}
                    loading={isLoadingTasks}
                />
            </ScrollableFillerDiv>
        </ScrollableFillerDiv>
    );
}

export default HomePageLayout;
