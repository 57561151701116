import { Button, LoadingButton } from '@/components/ui';
import { useToast } from '@/components/ui/';
import { cn, createIntervalsFromAvailability } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candidatesStrings } from '@/strings';
import { useFormContext } from 'react-hook-form';

import { AvailabilityFormSchema, FormActionProps } from './types';
import { avInputSchemaToAvRecord, isEmptAvInputSchema } from './utils';

const strings = candidatesStrings.newRequests.recruiterAvailability;

function AvailabilityFormActions({
    handleClose,
    updateAvailability,
    callback,
}: FormActionProps) {
    const { toast } = useToast();
    const {
        handleSubmit,
        formState: { isValid, isLoading },
        watch,
    } = useFormContext<AvailabilityFormSchema>();
    const availability = watch();
    const isEmptyAv = isEmptAvInputSchema(availability);

    const submit = async (inputSchenma: AvailabilityFormSchema) => {
        trackEvent(MixpanelEvent.SetAvailability, { availability: inputSchenma });
        const availability = avInputSchemaToAvRecord(inputSchenma);
        const intervals = createIntervalsFromAvailability(availability);
        await updateAvailability(intervals)
            .then(() => {
                callback && callback();
                handleClose();
            })
            .catch((error) => {
                toast({
                    title: strings.updateToast.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            });
    };

    return (
        <div className={cn('flex')}>
            <div
                className={cn(
                    'w-full gap-6 flex items-center justify-end xs:justify-center',
                )}
            >
                <Button
                    className={cn('xs:w-full')}
                    size={'lg'}
                    variant={'secondary'}
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    {strings.buttonCancel}
                </Button>
                <LoadingButton
                    className={cn('xs:w-full')}
                    size={'lg'}
                    onClick={handleSubmit(submit)}
                    disabled={isEmptyAv || !isValid}
                >
                    {strings.buttonSetAvailability}
                </LoadingButton>
            </div>
        </div>
    );
}

export default AvailabilityFormActions;
