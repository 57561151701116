import { cn } from '@/lib/utils';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { Tabs, TabsContent, TabsList, TabsTrigger } from './base';
import { TabsProps } from './types';

const CustomTabs = ({
    tabs,
    value: externalSelectedTab = '',
    setValue: setExternalSelectedTab,
    initialValue,
    tabsListContainerProps,
    tabsListProps,
    className,
    ...props
}: TabsProps) => {
    const [internalSelectedTab, setInternalSelectedTab] = useState<string>(
        initialValue ?? '',
    );

    const isControlled = !!setExternalSelectedTab;
    const selectedTab = isControlled ? externalSelectedTab : internalSelectedTab;
    const setSelectedTab = isControlled
        ? setExternalSelectedTab
        : setInternalSelectedTab;

    const [hadSelection, setHadSelection] = useState(false);
    const tabsRef = useRef<Record<string, HTMLButtonElement>>({});
    const animation = useAnimation();

    useEffect(() => {
        if (tabsRef.current[selectedTab]) {
            const { offsetLeft, offsetWidth } = tabsRef.current[selectedTab];
            if (!hadSelection) {
                animation.set({
                    left: offsetLeft,
                    width: offsetWidth,
                });
            }
            animation.start({
                left: offsetLeft,
                width: offsetWidth,
                transition: hadSelection
                    ? { type: 'spring', stiffness: 300, damping: 30 }
                    : { duration: 0 },
            });
            setHadSelection(true);
        }
    }, [selectedTab, animation, hadSelection]);

    return (
        <Tabs
            value={selectedTab}
            onValueChange={setSelectedTab}
            className={cn('flex flex-col justify-center', className)}
            {...props}
        >
            <div className={cn('relative')} {...tabsListContainerProps}>
                <TabsList {...tabsListProps}>
                    <motion.div
                        className={cn(
                            'bg-primary h-full absolute top-0 rounded-full z-0',
                        )}
                        initial={false}
                        animate={animation}
                    />
                    {tabs.map((tab) => (
                        <TabsTrigger
                            ref={(el: HTMLButtonElement) =>
                                (tabsRef.current[tab.id] = el)
                            }
                            key={tab.id}
                            value={tab.id}
                        >
                            {tab.header}
                        </TabsTrigger>
                    ))}
                </TabsList>
            </div>
            <div className={cn('relative flex-grow')}>
                {tabs.map((tab) => (
                    <TabsContent key={tab.id} value={tab.id}>
                        {tab.content}
                    </TabsContent>
                ))}
            </div>
        </Tabs>
    );
};

export default CustomTabs;
