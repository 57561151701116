import { useLottie } from 'lottie-react';
import { useEffect, useState } from 'react';

import { BaseLottieProps } from './types';

const BaseLottie = ({
    stopAtFrame,
    delay,
    animationData,
    ...props
}: BaseLottieProps) => {
    const [shouldNotPlay, setShouldDontPlay] = useState(!!delay);
    const { View, goToAndStop, goToAndPlay } = useLottie({
        animationData,
        autoPlay: true,
        loop: true,
        style: { ...(shouldNotPlay ? { display: 'none' } : {}) },
        ...props,
    });
    const { fr: framesPerSecond } = animationData as { op: number; fr: number };
    if (!framesPerSecond) {
        throw new Error('Invalid animationData for Lottie: missing "fr" property');
    }

    useEffect(() => {
        if (stopAtFrame) {
            setTimeout(
                () => {
                    goToAndStop(stopAtFrame, true);
                },
                (stopAtFrame / framesPerSecond) * 1000,
            );
        }
    }, [stopAtFrame, framesPerSecond, goToAndStop]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (delay) {
            timeout = setTimeout(() => {
                goToAndPlay(0, true);
                setShouldDontPlay(false); // workaround because autoplay doesn't work as expected
            }, delay);
        }
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);
    return View;
};

export default BaseLottie;
