import BaseLottie from './BaseLottie';
import animationData from './_files/termination.json';
import { LottieProps } from './types';

const TerminationLottie = ({ infinite = false, ...props }: LottieProps) => {
    return (
        <BaseLottie
            animationData={animationData}
            loop={true}
            stopAtFrame={infinite ? undefined : 160}
            {...props}
        />
    );
};

export default TerminationLottie;
