import { CurrentAuthUser } from '@/_selectors';
import config from '@/config';
import { Env } from '@/lib/types';
import { getSessionId } from '@/lib/utils';
import mixpanel from 'mixpanel-browser';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MixpanelEvent, trackEvent } from './events';
import { registerScreenSizeInMixpanel, updateScereenSizeInMixpanel } from './metadata';
import { isMixpanelOn } from './utils';

interface MixpanelState {
    initiated?: boolean;
}
const MixpanelContext = createContext({} as MixpanelState);

function MixpanelController({ children }: { children: ReactNode }) {
    const [initiated, setInitiated] = useState(false);
    const user = useSelector(CurrentAuthUser);
    const { name: nameOfCurrentUser } = user ?? {};

    useEffect(() => {
        if (!isMixpanelOn() || !user) {
            return;
        }
        mixpanel.init(config.MIXPANEL_TOKEN, {
            debug: config.ENV === Env.Dev,
            persistence: 'localStorage',
        });
        mixpanel.register({ session: getSessionId(), env: config.ENV });
        mixpanel.identify(user.email);
        mixpanel.people.set_once({ $email: user.email });
        registerScreenSizeInMixpanel(false);
        trackEvent(MixpanelEvent.Login);
        setInitiated(true);
        const windowResizeListener = () => updateScereenSizeInMixpanel();
        window.addEventListener('resize', windowResizeListener);
        return () => {
            window.removeEventListener('resize', windowResizeListener);
        };
    }, [user]);

    useEffect(() => {
        if (!isMixpanelOn() || !nameOfCurrentUser) {
            return;
        }
        mixpanel.people.set({ $name: nameOfCurrentUser });
    }, [nameOfCurrentUser]);

    return (
        <MixpanelContext.Provider
            value={{
                initiated,
            }}
        >
            {(initiated || !isMixpanelOn()) && children}
        </MixpanelContext.Provider>
    );
}

export const useMixpanelContext = () => useContext(MixpanelContext);

export default MixpanelController;
