import { DialogContentWithFooter } from '@/components/ui';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useState } from 'react';

import MoveProcessForwardDialogFooter from './footer';
import { DialogContentBaseProps } from './types';
import { getStringsForDialog } from './utils';

export function MoveProcessForwardDialogContent({
    process,
    nextStep,
    ...footerProps
}: DialogContentBaseProps) {
    const [freeText, setFreeText] = useState('');

    const { title, submitButton, cancelButton, freeTextPlaceholder, description } =
        getStringsForDialog(process, nextStep);

    return (
        <DialogContentWithFooter
            footer={
                <MoveProcessForwardDialogFooter
                    {...{
                        ...footerProps,
                        submitButtonString: submitButton,
                        cancelButtonString: cancelButton,
                        nextStep,
                        process,
                    }}
                />
            }
        >
            <div className={cn('relative w-[650px] max-w-full')}>
                <div
                    className={cn('p-12 xs:p-0 xs:pb-4 text-center xs:text-left h-fit')}
                >
                    <p className={cn('text-18 leading-10 font-semibold')}>{title}</p>
                    <p className={cn('text-14')}>{description}</p>
                    <div className={cn('realtive h-[150px]')}>
                        <Textarea
                            className={cn('mt-12 xs:mt-8 h-full resize-none')}
                            value={freeText}
                            onChange={(e) => setFreeText(e.target.value)}
                            placeholder={freeTextPlaceholder}
                            tabIndex={-1}
                        />
                    </div>
                </div>
            </div>
        </DialogContentWithFooter>
    );
}

export default MoveProcessForwardDialogContent;
