import { useAppDispatch } from '@/_helpers/store';
import eventsSlice from '@/_reducers/events';
import { ProcessRelevantEventIds } from '@/_selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function useMarkMissingEventIds() {
    const relevantEventIds = useSelector(ProcessRelevantEventIds);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (relevantEventIds.length > 0) {
            dispatch(eventsSlice.actions.markIdsToFetch(relevantEventIds));
        }
    }, [relevantEventIds, dispatch]);
}

export default useMarkMissingEventIds;
