import { cn } from '@/lib/utils';
import PDFViewer from '@/pages/candidates/newProcessesTable/PdfViewer';
import { candidates as candStrings } from '@/strings';
import { useLayoutEffect, useRef, useState } from 'react';

import DetailsCard from './DetailsCard';
import { useResumeUrlFetcher } from './hooks';
import { CandidateResumeTileProps } from './types';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function CandidateResumeTile({
    process: p,
    cardProps,
    hidden = false,
    fixedThumbnalWidth = false,
    pdfContainerProps,
    emptyDivProps,
    noBottomRow,
}: CandidateResumeTileProps) {
    const { refresh } = useResumeUrlFetcher(p, hidden);

    const { hasResume, resumeUrl: resumeLink } = p.candidate;

    const containerRef = useRef<HTMLDivElement>(null);
    const [thumbnailWidth, setThumbnailWidth] = useState<number>(0);

    useLayoutEffect(() => {
        if (containerRef.current) {
            setThumbnailWidth(containerRef.current.clientWidth);
        }
    }, []);

    return (
        <DetailsCard title={strings.resumeCardTitle} {...cardProps}>
            <div
                ref={containerRef}
                className={cn('relative flex items-center w-full h-full px-8 py-6')}
            >
                {hasResume && (
                    <div
                        {...pdfContainerProps}
                        className={cn(
                            'relative w-full h-full',
                            pdfContainerProps?.className,
                        )}
                    >
                        <PDFViewer
                            filePath={resumeLink}
                            candidateName={p.candidate.name}
                            candidateId={p.candidate.id}
                            onRetry={refresh}
                            noBottomRow={noBottomRow}
                            thumbnailWidth={
                                fixedThumbnalWidth ? thumbnailWidth : undefined
                            }
                        />
                    </div>
                )}
                {!hasResume && (
                    <div
                        {...emptyDivProps}
                        className={cn('w-full h-44', emptyDivProps?.className)}
                    />
                )}
            </div>
        </DetailsCard>
    );
}

export default CandidateResumeTile;
