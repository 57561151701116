import { cn } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function NoData({ className }: { className?: string }) {
    return (
        <div className={cn('text-14 font-bold opacity-20 text-black', className)}>
            {strings.noData}
        </div>
    );
}

export default NoData;
