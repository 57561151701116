import { eventTypeToName, professionToName } from '@/strings/common';

export default {
    title: {
        func: (count: number | null) =>
            `${count !== null ? count + ' ' : ''}Upcoming events`,
        example: [9],
    },
    mobile: {
        noEventsTitle: 'No upcoming events',
    },
    noEventsTitle: 'Upcoming events',
    noEventsMsg: 'No events ahead',
    eventBlock: {
        professionToName,
        eventTypeToName,
    },
};
