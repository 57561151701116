import Loader from '@/components/lotties/Loader';
import { TasksDone } from '@/components/svgs';
import { ScrollOnHoverVertical, ScrollableFillerDiv } from '@/components/ui';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName } from '@/lib/types';
import { cn } from '@/lib/utils';
import { homepage as homepageStrings } from '@/strings';

import TaskBlock from './TaskBlock';
import { PendingTasksViewProps } from './types';
import { getTaskType } from './utils';

const strings = homepageStrings.tasks;

function TasksPanel({ processes, initiated, loading }: PendingTasksViewProps) {
    const { openDialog: openCandidateDetails } = useOpenDialog(
        DialogName.CandidateDetails,
    );
    const totalCount = processes.length;
    const isEmpty = totalCount === 0;
    const showLoader = loading && !initiated;

    return (
        <ScrollableFillerDiv
            className={cn('overflow-hidden')}
            rootDivProps={{ className: cn('relative') }}
            growDivProps={{ className: cn('mt-5 min-w-[570px] w-full') }}
            contentWrapperProps={{
                className: cn('relative rounded-2xl p-4 pl-2 pr-[2px] bg-accent300'),
            }}
            fixedDiv={
                <p className={cn('text-15 font-bold')}>
                    {initiated && !isEmpty && strings.title.func(totalCount)}
                    {initiated && isEmpty && strings.noTasksTitle}
                </p>
            }
        >
            <ScrollOnHoverVertical>
                <div className={cn('h-full pl-2 pr-[2px]')}>
                    {initiated &&
                        !isEmpty &&
                        processes.map((process) => (
                            <TaskBlock
                                key={process.id}
                                taskType={getTaskType(process)}
                                process={process}
                                event={process.relevantEvent}
                                openCandidateDialog={() =>
                                    openCandidateDetails({ processId: process.id })
                                }
                            />
                        ))}
                    {initiated && isEmpty && (
                        <div
                            className={cn(
                                'relative flex flex-col items-center justify-center w-full h-full',
                            )}
                        >
                            <TasksDone className={cn('opacity-10')} />
                            <p className={cn('text-14 font-medium mt-5 text-black/20')}>
                                {strings.noTasksMsg}
                            </p>
                        </div>
                    )}
                    <div
                        className={cn(
                            'relative flex items-center justify-center w-full h-full',
                            { 'h-0 overflow-hidden': !showLoader },
                        )}
                    >
                        <Loader variant='primary' sizing='lg' />
                    </div>
                </div>
            </ScrollOnHoverVertical>
        </ScrollableFillerDiv>
    );
}

export default TasksPanel;
