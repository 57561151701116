import { RootState } from '@/_helpers/store';
import { getAllEnumValues } from '@/lib/base';
import { CandidateEventApiModel, FetchModelStatus } from '@/lib/types';
import { generateEmptyFetcherState } from '@/lib/utils';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy, mapValues } from 'lodash';

import { EventsState, FetchProcessEventsFunc, FetcherName } from './types';

export const storeEvents = createAsyncThunk<
    CandidateEventApiModel[],
    {
        fetcherId?: FetcherName;
        fetchFunction: FetchProcessEventsFunc | FetchProcessEventsFunc[];
    },
    { state: RootState }
>('processes/fetchEvents', async ({ fetchFunction }, { rejectWithValue }) => {
    const fetchFuncs = Array.isArray(fetchFunction) ? fetchFunction : [fetchFunction];
    try {
        const fetchPromises = fetchFuncs.map((fetchFunc) => fetchFunc());
        const results = await Promise.all(fetchPromises);
        const processEvents = results.flat();
        return processEvents;
    } catch (error) {
        return rejectWithValue(String(error));
    }
});

const fetcherNames = getAllEnumValues(FetcherName);

const initialState = {
    data: {},
    fetchers: mapValues(
        keyBy(fetcherNames, (n) => n),
        generateEmptyFetcherState,
    ),
} as EventsState;

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        updateEventStatus: (state, action: PayloadAction<CandidateEventApiModel>) => {
            const eventId = action.payload.id;
            if (action.payload) {
                state.data[eventId] = action.payload;
            }
        },
        markIdsToFetch: (state, action: PayloadAction<string[]>) => {
            const ids = action.payload;
            for (const id of ids) {
                if (!state.data[id]) {
                    state.data[id] = undefined;
                }
            }
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeEvents.pending, (state, action) => {
                const { fetcherId } = action.meta.arg;
                if (fetcherId) {
                    state.fetchers[fetcherId].status = FetchModelStatus.LOADING;
                }
            })
            .addCase(storeEvents.fulfilled, (state, action) => {
                const { fetcherId } = action.meta.arg;
                if (fetcherId) {
                    const fetcherState = state.fetchers[fetcherId];
                    fetcherState.status = FetchModelStatus.SUCCESS;
                    fetcherState.initiated = true;
                }
                if (action.payload.length > 0) {
                    state.data = {
                        ...state.data,
                        ...keyBy(action.payload, 'id'),
                    };
                }
            })
            .addCase(storeEvents.rejected, (state, action) => {
                const { fetcherId } = action.meta.arg;
                if (fetcherId) {
                    const fetcherState = state.fetchers[fetcherId];
                    fetcherState.status = FetchModelStatus.FAILURE;
                    fetcherState.error = action.error.message;
                }
            });
    },
});

export default eventsSlice;
