import { Dialog } from '@/components/ui';
import { cn } from '@/lib/utils';

import { BaseDialogPropsCommon } from '../types';
import WeeklyAvMissingDialogContent from './content';

interface Props extends BaseDialogPropsCommon {
    openRecruiterAvailabilityDialog: () => void;
}

function WeeklyAvMissingDialog({
    isOpen,
    setOpen,
    handleClose,
    openRecruiterAvailabilityDialog,
}: Props) {
    return (
        <Dialog
            open={isOpen}
            setOpen={setOpen}
            headerProps={{
                className: cn('pb-7 border-b-0'),
            }}
            overlayProps={{
                onPointerDown: handleClose,
            }}
            contentProps={{
                onPointerDown: (ev) => ev.stopPropagation(),
                onPointerDownOutside: (ev) => ev.preventDefault(),
            }}
        >
            <WeeklyAvMissingDialogContent
                openRecruiterAvailabilityDialog={openRecruiterAvailabilityDialog}
            />
        </Dialog>
    );
}

export default WeeklyAvMissingDialog;
