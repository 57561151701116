import Loader from '@/components/lotties/Loader';
import { ArrowRight, CandidatesIcon } from '@/components/svgs';
import { ScrollOnHoverVertical, ScrollableFillerDiv } from '@/components/ui';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as homepageStrings } from '@/strings';
import { useHover } from '@uidotdev/usehooks';

import NewRequestBlock from './NewRequestBlock';
import { NewCandidatesLayoutProps } from './types';

const strings = homepageStrings.newRequests;

function NewRequestsLayout({
    totalCount,
    isEmpty,
    initiated,
    isLoading,
    openCandidateDetails,
    newCandidates,
    goToCandidates,
}: NewCandidatesLayoutProps) {
    const [hoverRef, hovering] = useHover();

    return (
        <div ref={hoverRef} className={cn('relative h-full')}>
            <ScrollableFillerDiv
                className={cn('hidden-scrollbar')}
                rootDivProps={{ className: cn('relative h-full') }}
                growDivProps={{ className: cn('mt-5 w-full') }}
                contentWrapperProps={{
                    className: cn(
                        'relative rounded-2xl py-4 pl-2 pr-[2px] bg-accent300',
                    ),
                }}
                fixedDiv={
                    initiated ? (
                        <div className={cn('flex items-end justify-between')}>
                            <p className={cn('text-15 font-bold')}>
                                {isEmpty
                                    ? strings.noCandidatesTitle
                                    : strings.title.func(totalCount)}
                            </p>
                            <div
                                className={cn(
                                    'flex items-center cursor-pointer select-none group',
                                    { hidden: !hovering || isEmpty },
                                )}
                                onClick={goToCandidates}
                            >
                                <p
                                    className={cn(
                                        'mr-2  text-13 font-bold text-black group-hover:text-primary transition-colors duration-200 ease-out',
                                    )}
                                >
                                    {strings.goToCandidates}
                                </p>
                                <ArrowRight
                                    className={cn(
                                        ' transition-colors duration-200 ease-out fill-black stroke-black group-hover:fill-accent600 group-hover:stroke-accent600',
                                    )}
                                />
                            </div>
                        </div>
                    ) : null
                }
            >
                <div className={cn('h-full w-full')}>
                    {!initiated && isLoading && (
                        <div
                            className={cn(
                                'h-full relative flex items-center justify-center w-full  bg-accent300 rounded-2xl',
                            )}
                        >
                            <Loader variant='primary' sizing='lg' />
                        </div>
                    )}

                    {initiated && (
                        <div className={cn('h-full')}>
                            <ScrollOnHoverVertical>
                                <div className={cn('h-full pl-2 pr-[2px]')}>
                                    {!isEmpty &&
                                        newCandidates.map((p) => (
                                            <NewRequestBlock
                                                key={p.id}
                                                process={p}
                                                openCandidateDetails={() => {
                                                    trackEvent(
                                                        MixpanelEvent.HomepageClickCandidateName,
                                                        {
                                                            via: 'new_request',
                                                            candidate_id: p.candidate.id,
                                                            candidate_name:
                                                                p.candidate.name,
                                                        },
                                                    );
                                                    openCandidateDetails({
                                                        processId: p.id,
                                                    });
                                                }}
                                            />
                                        ))}
                                    {isEmpty && (
                                        <div
                                            className={cn(
                                                'relative flex flex-col items-center justify-center w-full h-full',
                                            )}
                                        >
                                            <CandidatesIcon
                                                className={cn(
                                                    'fill-black opacity-10 w-14 h-14',
                                                )}
                                            />
                                            <p
                                                className={cn(
                                                    'text-14 font-medium mt-5 text-black/20',
                                                )}
                                            >
                                                {strings.noCandidatesMsg}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </ScrollOnHoverVertical>
                        </div>
                    )}
                </div>
            </ScrollableFillerDiv>
        </div>
    );
}

export default NewRequestsLayout;
