import { setDialogOpen } from '@/_reducers/dialogs';
import { PatchProcessStatus, updateProcessStatus } from '@/_reducers/processes';
import {
    IsMoveProcessForwardDialogOpen,
    MoveProcessForwardDialogData,
} from '@/_selectors';
import { DialogName } from '@/lib/types';
import { connect, useSelector } from 'react-redux';

import MoveProcessForwardDialog from './MoveProcessForwardDialog';
import { UpdateProcessFunc } from './types';

function MoveProcessForwardDialogProvider({
    setDialogOpenAction,
    updateProcessStatus,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    updateProcessStatus: (params: PatchProcessStatus) => Promise<void>;
}) {
    const shouldBeOpen = useSelector(IsMoveProcessForwardDialogOpen);
    const { process, nextStep } = useSelector(MoveProcessForwardDialogData);
    const hasData = !!process && !!nextStep;

    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.MoveProcessForward, open);
    };
    const handleClose = () => setOpen(false);

    const updateStatus: UpdateProcessFunc = async (params) => {
        return await updateProcessStatus({ ...params, processId: process!.id });
    };

    return (
        <>
            {hasData && (
                <MoveProcessForwardDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    updateStatus={updateStatus}
                    nextStep={nextStep}
                />
            )}
        </>
    );
}

const mapDispathToProps = {
    setDialogOpenAction: setDialogOpen,
    updateProcessStatus,
};

export default connect(null, mapDispathToProps)(MoveProcessForwardDialogProvider);
