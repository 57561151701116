import { SuccessLottie } from '@/components/lotties';
import { Candidate } from '@/lib/types';
import { cn, getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

const strings = dialogsStrings.eventDetails.reschedule.successStep;

export function RequestRescheduleSuccessContent({
    candidate,
}: {
    candidate: Candidate;
}) {
    return (
        <div
            className={cn(
                'relative flex flex-col items-center text-center pt-6 pb-[90px] px-12',
            )}
        >
            <SuccessLottie className={cn('h-[270px] w-fit')} />
            <p className={cn('mt-10 text-20 font-bold')}>{strings.title}</p>
            <p className={cn('mt-4 text-14 font-medium')}>
                {strings.description.func(getFirstName(candidate))}
            </p>
        </div>
    );
}

export default RequestRescheduleSuccessContent;
