import { XIcon } from '@/components/svgs';
import { cn } from '@/lib/utils';

import ClickableIcon from './ClickableIcon';
import TimeInput from './TimeInput';
import { TIME_INTERVAL_ROW_HEIGHT_CLASS } from './common';
import { IntervalInputProps } from './types';
import { SELECTABLE_TIMES } from './utils';

function IntervalInput({ value, onChange, onDelete, error }: IntervalInputProps) {
    return (
        <div
            className={cn(
                'relative ml-10 xs:ml-0 flex items-start justify-between gap-8 mb-6',
                'last-of-type:mb-0',
            )}
        >
            <div className={cn('relative')}>
                <div className={cn('flex items-center gap-5')}>
                    <TimeInput
                        time={value.start}
                        onChange={(val) => onChange({ ...value, start: val })}
                        selectableTimes={SELECTABLE_TIMES.slice(0, -1)}
                        hasError={!!error}
                    />
                    <div className={cn('border-t border-black rounded-[1px] w-4')} />
                    <TimeInput
                        time={value.end}
                        onChange={(val) => onChange({ ...value, end: val })}
                        selectableTimes={SELECTABLE_TIMES.slice(1)}
                        hasError={!!error}
                    />
                </div>
                {error && (
                    <div className={cn('h-8 w-full')}>
                        <div className={cn('absolute px-1')}>
                            <p
                                className={cn(
                                    'w-full text-13 text-red200 mt-2 whitespace-nowrap',
                                )}
                            >
                                {error}
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div
                className={cn(
                    TIME_INTERVAL_ROW_HEIGHT_CLASS,
                    'flex items-center ml-auto',
                )}
            >
                <ClickableIcon onClick={onDelete}>
                    <XIcon className={cn('w-3 h-3')} />
                </ClickableIcon>
            </div>
        </div>
    );
}

export default IntervalInput;
