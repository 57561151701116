import { ArrowDown, TasksDone } from '@/components/svgs';
import { Button } from '@/components/ui';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName, Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as homepageStrings } from '@/strings';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { PendingTasksSectionProps } from '../types';
import { getTaskType } from '../utils';
import EmptySectionView from './EmptySectionView';
import TaskBlockMobile from './TaskBlockMobile';

const strings = homepageStrings.tasks.mobile;

const DEFAULT_SHOWN_AMOUNT = 2;

function PendingTasksSection({
    pendingTasksProcesses,
    initiatedTasks,
    isLoadingTasks,
    anchorRef,
}: PendingTasksSectionProps) {
    const [showMore, setShowMore] = useState(false);
    const { openDialog: openCandidateDetails } = useOpenDialog(
        DialogName.CandidateDetails,
    );
    const onTaskBlockClick = (process: Process) => {
        trackEvent(MixpanelEvent.ExpandCandidateInProcess, {
            via: 'pending_task_block',
            candidate_id: process.candidate.id,
            candidate_name: process.candidate.name,
        });
        openCandidateDetails({ processId: process.id });
    };

    const toggleShowMore = () => {
        if (showMore) {
            setShowMore(!showMore);
            setTimeout(() => {
                anchorRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        } else {
            setShowMore(!showMore);
        }
    };

    const count = pendingTasksProcesses.length;
    const isEmpty = count === 0;
    const firstProcesses = pendingTasksProcesses.slice(0, DEFAULT_SHOWN_AMOUNT);
    const additionalProcesses = pendingTasksProcesses.slice(DEFAULT_SHOWN_AMOUNT);
    const hasMore = count > DEFAULT_SHOWN_AMOUNT;

    return (
        <div className={cn('py-12 border-b border-neutral200')} ref={anchorRef}>
            <div className={cn('text-20 font-bold')}>
                {isEmpty && initiatedTasks
                    ? strings.headerNoTasks
                    : strings.header.func(initiatedTasks ? count : null)}
            </div>
            <div
                className={cn(
                    'mt-10 flex flex-col items-stretch  justify-center gap-10',
                )}
            >
                {isEmpty && (
                    <EmptySectionView
                        Icon={TasksDone}
                        label={strings.noTasksLabel}
                        loading={isLoadingTasks}
                        loaderProps={{ className: cn('my-[26px]') }}
                    />
                )}
                {!isEmpty && (
                    <>
                        {firstProcesses.map((process) => (
                            <TaskBlockMobile
                                key={process.id}
                                taskType={getTaskType(process)}
                                process={process}
                                event={process.relevantEvent}
                                openCandidateDialog={() => onTaskBlockClick(process)}
                            />
                        ))}
                    </>
                )}
            </div>
            {hasMore && (
                <>
                    <motion.div
                        transition={{
                            type: 'spring',
                            stiffness: 350,
                            damping: 30,
                            mass: 1,
                        }}
                        initial={false}
                        animate={{
                            height: showMore ? 'auto' : 15,
                            marginBottom: showMore ? 30 : 15,
                            paddingTop: showMore ? 30 : 15,
                        }}
                        className={cn('overflow-hidden flex flex-col gap-10')}
                    >
                        {additionalProcesses.map((process) => (
                            <TaskBlockMobile
                                key={process.id}
                                taskType={getTaskType(process)}
                                process={process}
                                event={process.relevantEvent}
                                openCandidateDialog={() => onTaskBlockClick(process)}
                            />
                        ))}
                    </motion.div>
                    <Button
                        className={cn('text-13 w-fit mx-auto flex items-center gap-3')}
                        onClick={toggleShowMore}
                    >
                        <p>
                            {showMore
                                ? strings.showLessTasks
                                : strings.showMoreTasks.func(
                                      count - DEFAULT_SHOWN_AMOUNT,
                                  )}
                        </p>
                        <ArrowDown
                            className={cn(
                                'w-3 h-fit stroke-white transition-all duration-300 rotate-0',
                                { 'rotate-180': showMore },
                            )}
                        />
                    </Button>
                </>
            )}
        </div>
    );
}

export default PendingTasksSection;
