import { ButtonsGroup, Select, SelectOption } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { professionTypes } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as strings } from '@/strings';
import { groupBy } from 'lodash';
import { useEffect } from 'react';

import { DEFAULT_PROFESSION_FILTER } from './consts';
import { ProfFilter, ProfessionFilterProps } from './types';

const tableStrings = strings.newRequests.candidatesTable;

function ProfessionFilter({ profFilter, setProfFilter, models }: ProfessionFilterProps) {
    const isMobileScreen = useIsMobileScreen();

    const modelsByProf = groupBy(models, (p) => p.candidate.profession);
    const relevantProfessions = professionTypes.filter(
        (prof) => modelsByProf[prof]?.length ?? 0 > 0,
    );
    useEffect(() => {
        if (
            profFilter !== DEFAULT_PROFESSION_FILTER &&
            !relevantProfessions.includes(profFilter)
        ) {
            setProfFilter(DEFAULT_PROFESSION_FILTER);
        }
    }, [profFilter, setProfFilter, relevantProfessions]);

    return (
        <>
            {isMobileScreen && (
                <Select
                    value={profFilter}
                    onChange={setProfFilter}
                    options={
                        [
                            {
                                id: 'All',
                                value: 'All',
                                label: tableStrings.showAllFilter.func(models.length),
                            },
                            ...relevantProfessions.map((prof) => ({
                                id: prof,
                                value: prof,
                                label: tableStrings.professionFilter.func(
                                    prof,
                                    modelsByProf[prof]?.length ?? 0,
                                ),
                            })),
                        ] as SelectOption<ProfFilter>[]
                    }
                    triggerProps={{
                        className: cn(
                            'w-fit xs:px-4 xs:py-3 text-12 font-semibold bg-accent300 rounded-xl border-none whitespace-nowrap',
                        ),
                    }}
                />
            )}
            {!isMobileScreen && (
                <ButtonsGroup
                    value={profFilter}
                    setValue={setProfFilter}
                    buttons={[
                        {
                            id: 'All',
                            label: tableStrings.showAllFilter.func(models.length),
                        },
                        ...relevantProfessions.map((prof) => ({
                            id: prof,
                            label: tableStrings.professionFilter.func(
                                prof,
                                modelsByProf[prof]?.length ?? 0,
                            ),
                        })),
                    ]}
                />
            )}
        </>
    );
}

export default ProfessionFilter;
