import { cn } from '@/lib/utils';

import { AttributeRowProps } from './types';
import { attributeRowVariants } from './variants';

function AttributeRow({ label, value, variant }: AttributeRowProps) {
    return (
        <div
            className={cn(
                'py-6 px-8 flex items-center justify-between border-b border-neutral-100',
                '[&:last-of-type]:xs:border-b-0',
                attributeRowVariants({ variant }),
            )}
        >
            <div className={cn('h-auto flex items-center')}>
                {label.icon}
                <p className={cn('mx-3 font-semibold text-13')}>{label.text}</p>
            </div>
            <div className={cn('h-auto flex items-center')}>
                <p className={cn('mr-3 font-semibold text-13')}>{value.text}</p>
                {value.icon}
            </div>
        </div>
    );
}

export default AttributeRow;
