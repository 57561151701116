import { fetchNewProcesses } from '@/_reducers/processes';
import { NewProcesses, NewProcessesFetcherData } from '@/_selectors';
import useFetchers from '@/hooks/useFetchers';
import useGoTo from '@/hooks/useGoTo';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName } from '@/lib/types';
import { CandidatesTab } from '@/lib/types/queryParams';
import { valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { useSelector } from 'react-redux';

function useNewCandidates() {
    useFetchers(fetchNewProcesses);
    const { goToCandidates } = useGoTo();
    const onGoToCandidates = () => {
        trackEvent(MixpanelEvent.HomepageNavigateNewCandidates);
        goToCandidates(CandidatesTab.New);
    };
    const newCandidatesById = useSelector(NewProcesses);
    const { initiated, isLoading } = useSelector(NewProcessesFetcherData);
    const { openDialog: openCandidateDetails } = useOpenDialog(
        DialogName.CandidateDetails,
    );
    const newCandidates = valuesOf(newCandidatesById);

    const totalCount = newCandidates.length;
    const isEmpty = newCandidates.length === 0;
    return {
        totalCount,
        isEmpty,
        initiated,
        isLoading,
        openCandidateDetails,
        newCandidates,
        goToCandidates: onGoToCandidates,
    };
}

export default useNewCandidates;
