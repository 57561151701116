import { firebaseAuth, getLocalStorageGracefully } from '@/lib/utils';
import strings from '@/strings/login';
import { isSignInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth';

import { UseSignInProps } from './types';

function useSignIn({
    onEmailSent,
    setError,
    email: emailVal,
    signInFromEmailLink,
}: UseSignInProps) {
    const email = emailVal.trim();
    const sendEmailLink = async () => {
        await sendSignInLinkToEmail(firebaseAuth, email, {
            url: window.location.href,
            handleCodeInApp: true,
        })
            .then(() => {
                getLocalStorageGracefully()?.setItem('emailForSignIn', email);
                onEmailSent();
            })
            .catch(() => {
                setError(strings.emailInput.errorMsg);
            });
    };

    const onSubmit = async () => {
        const url = window.location.href;
        if (isSignInWithEmailLink(firebaseAuth, url)) {
            await signInFromEmailLink(email);
        } else {
            await sendEmailLink();
        }
    };

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            onSubmit();
        }
    };

    const clearError = () => setError('');

    return {
        onSubmit,
        onKeyDown,
        clearError,
    };
}

export default useSignIn;
